export const addUserFormFields = [
    // {
    //     label: 'Business Name',
    //     type: 'text',
    //     name: 'businessName',
    //     placeholder: '',
    //     readonly: true,
    // },
    // {
    //     label: 'Business Address',
    //     type: 'text',
    //     name: 'businessAddress',
    //     placeholder: '',
    //     readonly: true,
    // },
    {
        label: 'First Name',
        type: 'text',
        name: 'firstName',
        placeholder: 'Enter first name',
        readonly: false,
        required: true,
    },
    {
        label: 'Last Name',
        type: 'text',
        name: 'lastName',
        placeholder: 'Enter last name',
        readonly: false,
        required: true,
    },
    {
        label: 'Email',
        type: 'email',
        name: 'username',
        placeholder: 'Enter email',
        readonly: false,
        required: true,
    },

    {
        label: 'Phone Number',
        type: 'number',
        name: 'phoneNumber',
        placeholder: 'Enter phone number',
        readonly: false,
        required: false,
    },
]

export const userHead = [
    {
        label: 'First Name',
        field: 'first_name',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'Last Name',
        field: 'last_name',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'Email',
        field: 'email_id',
        sort: 'asc',
        width: 270,
    },
    {
        label: 'Status',
        field: 'status',
    },
    {
        label: 'Phone Number',
        field: 'phoneNumber',
    },
]
