import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { setSearchQuery, selectSearchQuery } from '../../redux/searchSlice'

const Search = () => {
    const dispatch = useDispatch()
    const query = useSelector(selectSearchQuery)
    const navigate = useNavigate()

    return (
        <div className="search">
            <div className="form-field">
                <input
                    type="text"
                    value={query}
                    onChange={(e) => dispatch(setSearchQuery(e.target.value.toLocaleLowerCase()))}
                    placeholder="What are you Looking for?"
                />

                <img
                    onClick={() => navigate(`/search?search=${query}`)}
                    src={process.env.PUBLIC_URL + '/images/search.svg'}
                    alt="Search Icon"
                    className="icon"
                />
            </div>
        </div>
    )
}

export default Search
