import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
    name: 'user',
    initialState: {
        userData: {},
        loading: false,
    },
    reducers: {
        setUserData: (state, action) => {
            state.userData = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
    },
})

export const { setUserData, setLoading, getUserDataFromApi } = userSlice.actions

export default userSlice.reducer
