import React, { useState, useEffect } from 'react'
import { MDBDataTable } from 'mdbreact'
import { getIconClassName } from '../../../helper/utils'
import Loader from '../../../components/Loader/Loader'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { getMultiUserList } from '../../../api/services/multi-user-vendor'
import { userHead } from './constant'

export type tableData = {
    columns: {
        label?: string
        field?: string
        sort?: string
        width?: number
        searchable?: boolean
        [rest: string]: any
    }[]
    rows: {
        clickEvent?: () => void
        [rest: string]: any
    }[]
}

const MultiUserVendorListing = () => {
    const [tableData, settableData] = useState<tableData>()
    const [error, setError] = useState('')
    const [loading, setloading] = useState(false)
    const navigate = useNavigate()

    const getuserListing = () => {
        setloading(true)
        getMultiUserList().then(
            (response) => {
                setloading(false)
                settableData({
                    columns: userHead,
                    rows: response?.data?.users?.map((row: any, index: number) => {
                        return {
                            ...row,
                            status: (
                                <div
                                    className={getIconClassName(
                                        row.isActive ? 'active' : 'disabled'
                                    )}
                                >
                                    {row.isActive ? 'active' : 'disabled'}
                                </div>
                            ),
                        }
                    }),
                })
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.error) ||
                    error.message ||
                    error.toString()
                setError(error.message)
                setloading(false)
            }
        )
    }

    useEffect(() => {
        getuserListing()
    }, [])

    const handleAddUserModal = () => {
        navigate('/add-sub-vendor')
    }

    if (loading) {
        return (
            <div className="loader__table">
                <Loader />
            </div>
        )
    }

    return (
        <div className="tableView admin__table">
            {error && error?.length && (
                <div className="alert alert-danger">{error}</div>
            )}

            <div className="btn-row">
                <Button className="btn-orange" onClick={handleAddUserModal}>
                    Add sub vendor
                </Button>
            </div>

            <MDBDataTable
                small
                data={tableData}
                className="responsive-table"
                responsive
            />
        </div>
    )
}

export default MultiUserVendorListing
