import customAxios from '../axios'
import { END_POINTS } from '../domain'

export const addReport = async (
    productId: any,
    reason: any,
    productType: any
) => {
    try {
        const result = await customAxios.post(END_POINTS.addReport, {
            productId,
            reason,
            productType,
        })
        return {
            err: false,
            result,
        }
    } catch (error: any) {
        return {
            err: true,
            error,
        }
    }
}

export const getReport = async () => {
    try {
        const result = await customAxios.get(END_POINTS.getReport)
        return {
            err: false,
            result,
        }
    } catch (error: any) {
        return {
            err: true,
            error,
        }
    }
}
export const seenByAdmin = async (id: any) => {
    try {
        const result = await customAxios.post(
            `${END_POINTS.seenByAdmin}/${id}`,
            {}
        )
        return {
            err: false,
            result,
        }
    } catch (error: any) {
        return {
            err: true,
            error,
        }
    }
}
