import React, { useEffect, useState } from 'react'
import { getServices } from '../../api/services/category.service'
import { useNavigate } from 'react-router'
import NoDataFound from '../../components/NodataFound/NoDataFound'
import './services.scss'

const Services = () => {
    const [services, setServices] = useState([])
    const navigate = useNavigate()

    const getData = () => {
        getServices()
            .then((response) => {
                setServices(response.data)
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        getData()
    }, [])

    const handleCategory = (item: any) => {
        navigate(`/listing-list/${item?._id}`)
    }

    return (
        <div className="services">
            <div className="listingwrap">
                <div className="limboListing">
                    <div className="listingpanel">
                        {services && services?.length ? (
                            services?.map((item: any, idx: any) => {
                                return (
                                    <div
                                        onClick={() => handleCategory(item)}
                                        key={item?._id}
                                        className="category-card"
                                    >
                                        <img
                                            src={item?.limboImage}
                                            alt="image"
                                        />
                                        <h3> {item?.limboName}</h3>
                                    </div>
                                )
                            })
                        ) : (
                            <NoDataFound />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services
