import customAxios from '../axios'
import { END_POINTS } from '../domain'

export const saveListing = (payload: any) => {
    return customAxios.post(`${END_POINTS.saveListing}`, payload)
}
export const updateListingStatus = (id: any, payload: any) => {
    return customAxios.put(`${END_POINTS.updateListingStatus}/${id}`, payload)
}

export const deleteListing = (id: any) => {
    return customAxios.put(`${END_POINTS.deleteListing}/${id}`)
}

export const getListingList = (type: any) => {
    const payload = { params: { listingType : type } };
    return customAxios.get(`${END_POINTS.getListingsList}`, payload)
}
export const getListingByUser = () => {
    return customAxios.get(`${END_POINTS.getListingByUser}`)
}

export const getListingById = (id: any, includeDetails: any) => {
    if (includeDetails) {
        return customAxios.get(
            `${END_POINTS.getListingsList}/${id}?includeDetails=1`
        )
    } else {
        return customAxios.get(`${END_POINTS.getListingsList}/${id}`)
    }
}

export const getLimbosByCategory = (payload: any) => {
    const loggedInUser = JSON.parse(localStorage.getItem('email') || '{}')
    return customAxios.post(`${END_POINTS.getListingByCategory}`, {
        ...payload,
        userId: loggedInUser,
    })
}

export const getFilterFieldValues = (queryString: any) => {
    return customAxios.get(`${END_POINTS.getFilterFieldValues}?${queryString}`)
}

export const imageUpload = (payload: any) => {
    return customAxios.post(`${END_POINTS.uploadImage}`, payload)
}

export const updateListing = (id: any, payload: any) => {
    return customAxios.put(`${END_POINTS.updateListing}/${id}`, payload)
}
