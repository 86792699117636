import React from 'react'
import Login from '../pages/Login/Login'
import LandinPage from '../pages/LandingPage/LandingPage'
import Register from '../pages/Register/Register'
import Success from '../pages/Register/Success'
import Verification from '../pages/Register/Verification'
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword'
import ResetPassword from '../pages/ForgotPassword/ResetPassword'
import PageNotFound from '../components/PageNotFound/PageNotFound'
import ListingDetailsPage from '../pages/LandingPage/ListingDetailsPage/ListingDetailsPage'
import ListingCategoryList from '../pages/ListingCategoryList/ListingCategoryList'
import Contact from '../pages/Contact/Contact'
import SearchPage from '../pages/Search/Search'
import Services from '../pages/Services/Services'
import Product from '../pages/Product/Product'
import Subscriptions from '../pages/SubscriptionPlans/SubscriptionPlans'
import PrivacyPolicy from '../pages/static/PrivacyPolicy'
import TermsCondition from '../pages/static/TermsCondition'
import About from '../pages/static/About'
import ChangePassword from '../pages/Dashboard/MultiUserVendor/ChangePassword'

export const auth_routes = [
    {
        path: '/',
        ele: <LandinPage />,
    },
    {
        path: '*',
        ele: <PageNotFound />,
    },
    {
        path: '/login',
        ele: <Login />,
    },
    {
        path: '/register',
        ele: <Register />,
    },
    {
        path: '/otp',
        ele: <Verification />,
    },
    {
        path: '/success',
        ele: <Success />,
    },
    {
        path: '/forgot-password',
        ele: <ForgotPassword />,
    },
    {
        path: '/resetpassword',
        ele: <ResetPassword />,
    },
    {
        path: '/change-password',
        ele: <ChangePassword />,
    },
    {
        path: '*',
        element: <PageNotFound />,
    },
    {
        path: '/listing/:id',
        ele: <ListingDetailsPage />,
    },
    {
        path: '/listing-list/:id',
        ele: <ListingCategoryList />,
    },
    {
        path: '/about',
        ele: <About />,
    },
    {
        path: '/privacy',
        ele: <PrivacyPolicy />,
    },
    {
        path: '/terms',
        ele: <TermsCondition />,
    },
    {
        path: '/services',
        ele: <Services />,
    },
    {
        path: '/product',
        ele: <Product />,
    },
    {
        path: '/search',
        ele: <SearchPage />,
    },
    {
        path: '/contact',
        ele: <Contact />,
    },
    {
        path: '/subscription/:status',
        ele: <Subscriptions />,
    },
]
