import React, { ReactNode } from 'react'
import Report from '../Report/Report'
import { constant } from '../../constant/constant'
import './LimboListItem.scss'
import Moment from 'react-moment'
import { Button } from 'react-bootstrap'
import { FaEye } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

const LimboListItem = ({
    data,
    addToFavourite,
    isAlreadyFavorite,
    removeFromFavourite,
}: any) => {
    const navigate = useNavigate()
    const details = data?.listingDetail?.map((item: any, key: any) => {
        return {
            [item?.listingFieldName]: item?.fieldValue,
        }
    })

    const addFavourite = () => {
        addToFavourite(data?._id)
    }

    const deleteFromFavourite = () => {
        removeFromFavourite(data?._id)
    }

    return (
        <>
            <div className="limbo-panel">
                <div className="time-details time-section">
                    <span className="list-created">
                        Listed Created :
                        <Moment format="MMMM Do YYYY, h:mm:ss a">
                            {data?.createdAt}
                        </Moment>
                    </span>
                    <span className="view-detail">
                        <button
                            className="btn-orange"
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate(`/listing/${data?._id}`)}
                            data-toggle="tooltip"
                            title="View Details"
                        >
                            {' '}
                            <FaEye /> View Details
                        </button>
                    </span>
                </div>

                <div className="limbo-title">
                    <h3>({data?.listingType})</h3>
                    <h3 className="price">
                        {`${process.env.REACT_APP_CURRENCY_SIGN}$ ${data?.price}`}
                    </h3>
                </div>

                <div className="limbo--details">
                    <div className="limbo-item">
                        {details?.map((item: any, idx: any) => {
                            return Object.entries(item).map(([key, value]) => {
                                return (
                                    <div key={key} className="flex-inline">
                                        <div className="label">{key}</div>
                                        <div className="label_name">
                                            {value as ReactNode}
                                        </div>
                                    </div>
                                )
                            })
                        })}
                    </div>

                    <div className="report__wrapper">
                        <Button
                            className="btn-orange"
                            onClick={
                                isAlreadyFavorite
                                    ? deleteFromFavourite
                                    : addFavourite
                            }
                        >
                            {isAlreadyFavorite
                                ? 'Remove From Favourite '
                                : 'Add to Favourite'}
                        </Button>
                        <Report
                            id={data?._id}
                            reportText={constant.reportListing}
                            productType={constant.listing}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default LimboListItem
