// toastSlice.js
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    showToast: false,
    message: '',
}

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        showToast: (state, action) => {
            state.showToast = true
            state.message = action.payload.message
        },
        hideToast: (state) => {
            state.showToast = false
            state.message = ''
        },
    },
})

export const { showToast, hideToast } = toastSlice.actions
export const selectToast = (state) => state.toast

export default toastSlice.reducer
