import React, { useEffect, useState } from 'react'
import SideNavBar, { roleList } from '../../layout/SideNavBar/SideNavBar'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import './dashboard.scss'

const Dashboard = () => {
    const navigate = useNavigate()
    const role = JSON.parse(localStorage.getItem('userRole') || '')
    const [title, setTitle] = useState<any>()
    const location = useLocation()
    const path = location.pathname.split('/')

    useEffect(() => {
        getIconTitle()
    }, [])

    const onNavSelect = (item: any) => {
        navigate(`/dashboard/${item?.id}`)
        setTitle(item)
    }

    const getIconTitle = () => {
        const role: any = localStorage.getItem('userRole')
        const selectedRole = roleList.find(
            (item) => item.role === JSON.parse(role)
        )
        setTitle(
            selectedRole?.list.find((item) => item.id === path[path.length - 1])
        )
    }


    return (
        <div className="dashboard-container">
            <div className="left-section">
                <SideNavBar onNavCliCk={onNavSelect} role={role} />
            </div>

            <div className="right-section">
                <div className="dashboard-header">
                    <div className="dashboard-header__icon">
                        <img src={title?.icon} alt="icon" />
                    </div>
                    <div className="dashboard-header__title">
                        {title?.title}
                    </div>
                </div>
                <div className="dashboard-content ">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default Dashboard
