import { MDBDataTable } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { reportsColumn } from '../../../../constant/constant';
import { getReport, seenByAdmin } from '../../../../api/services/report.service';
import Loader from '../../../../components/Loader/Loader';
import "../../../../components/Report/report.scss"
import { FaEye } from 'react-icons/fa6'

const ReportList = () => {
    const [tableData, settableData] = useState({ columns: reportsColumn, rows: [] });
    const [error, seterror] = useState<any>()
    const [loading, setloading] = useState(false)

    const viewedByAdmin = async (id: any) => {
        try {
            const res = await seenByAdmin(id)
            if (!res.err) {
                getData()
            }
            if (res.err) {
                seterror(res?.error?.response?.data?.message)
            }
        } catch (error: any) {
            seterror(error.message)
        }
    }
    const getData = async () => {
        setloading(true)
        try {
            const res = await getReport();
            if (!res.err) {
                const formattedRows = res?.result?.data.map((item: any) => {
                    return { ...item, action: (<div className={`${item.isReaded && "viewed"} isChecked`} title='mark as viewed' onClick={() => viewedByAdmin(item.id)}><FaEye /></div>) };
                });
                settableData({ columns: reportsColumn, rows: formattedRows });
                setloading(false)
            }
            if (res.err) {
                setloading(false)
                seterror(res?.error?.response?.data?.message)
            }
        } catch (error: any) {
            setloading(false)
            seterror(error?.message)
        }
    }
    useEffect(() => {
        getData();
    }, []);

    if (loading) {
        return <div className="loader__table">
            <Loader />
        </div>;
    }

    return (
        <div className='admin__table'>
            {
                error &&
                <div className='alert alert-danger'>{error}</div>
            }
            <MDBDataTable
                data={tableData}
                className="responsive-table report__table"
                responsive
                sortable={false}
            />
        </div>
    );
}

export default ReportList;
