import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import UserService from '../../../../api/services/user.service'
import './profile.scss'
import LimboCategories from '../../../../components/LimboCategories/LimboCategories'
import { useSelector } from 'react-redux'
import userService from '../../../../api/services/user.service'
import { toast } from 'react-toastify'
import { getShopperSubs } from '../../../../api/services/limbo.service'
import SubsHeader from '../Listing/SubsHeader'

const Profile = () => {
    const userData = useSelector((state: any) => state.user.userData)
    const userDataLoading = useSelector((state: any) => state.user.loading)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const email = JSON.parse(localStorage.getItem('email') || '')

    const [useremail, setEmail] = useState(email)
    const [phone, setPhone] = useState('')
    const [firstName, setfirstName] = useState('')
    const [lastName, setlastName] = useState('')
    const [address, setAddress] = useState('')
    const [zipcode, setZipcode] = useState(userData?.zip || '')
    const [businessname, setBusinessname] = useState('')
    const [website, setWebsite] = useState('')
    const [emailNotify, setEmailNotify] = useState(false)
    const [phoneNotify, setPhoneNotify] = useState(false)
    const [loading, setLoading] = useState(false)
    const [successful, setSuccessful] = useState(false)
    const [message, setMessage] = useState('')
    const [subscription, setsubscription] = useState<any>()
    const [onlineVendor, setOnlineVendor] = useState(false)
    const userRole = localStorage.getItem('userRole')

    const navigate = useNavigate()

    useEffect(() => {
        if (userData?.zip) {
            setZipcode(userData?.zip)
        }
    }, [userData?.zip])

    const [category, setCategory] = useState<{ data: any[]; loading: boolean }>(
        { data: [], loading: false }
    )

    const [selected, setSelected] = useState<string[]>(
        userData?.categories_supported || []
    )

    const updateCategories = async (selectedCategories: string[]) => {
        const response = await userService.updateUserCategory(
            useremail || userData?.email_id,
            selectedCategories
        )
        if (response?.status === 200) {
            toast('Categories updated successfully', {
                type: 'success',
                toastId: 'updateCategories',
                autoClose: 2000,
            })
        }
    }

    const handleChange = (categoryArr: any) => {
        const ids = categoryArr.map((item: any) => item._id)
        setSelected(ids)
    }

    useEffect(() => {
        getUser(useremail)
    }, [])

    useEffect(() => {
        const getShopperSubscription = async () => {
            try {
                const res = await getShopperSubs()
                if (res?.data?.plan) {
                    setsubscription(res?.data?.plan)
                }
            } catch (error) {
                console.log(error)
            }
        }
        if (
            userRole === `"vendor-service-provider"` ||
            userRole === `"vendor-sub-user"`
        ) {
            getShopperSubscription()
        }
    }, [])
    const validationSchema = () => {
        return Yup.object().shape({
            firstname: Yup.string()
                .test(
                    'len',
                    'The firstname must be between 3 and 20 characters.',
                    (val: any) =>
                        val &&
                        val.toString().length >= 3 &&
                        val.toString().length <= 20
                )
                .required('This field is required!'),
            lastname: Yup.string()
                .test(
                    'len',
                    'The lastname must be between 3 and 20 characters.',
                    (val: any) =>
                        val &&
                        val.toString().length >= 3 &&
                        val.toString().length <= 20
                )
                .required('This field is required!'),
            phone: Yup.string().test(
                'len',
                'The phone number must be exactly 10 digits.',
                (val: any) => {
                    const cleanedPhone = val?.replace(/\D/g, '')
                    return !cleanedPhone || cleanedPhone?.length === 10
                }
            ),
            email: Yup.string()
                .email('This is not a valid email.')
                .required('This field is required!'),
        })
    }
    const updateUser = (formValue: {
        firstname: string
        lastname: string
        phone: string
        email: string
        address: string
        zip: string
        emailnotify: boolean
        phonenotify: boolean
        businessname: string
        website: string
        onlineVendor: boolean
    }) => {
        const {
            firstname,
            lastname,
            email,
            phone,
            address,
            zip,
            emailnotify,
            phonenotify,
            businessname,
            website,
            onlineVendor,
        } = formValue
        setSuccessful(false)
        setMessage('')
        UserService.edituserInfo(
            firstname,
            lastname,
            phone.replace(/\D/g, ''),
            email,
            address,
            zip,
            emailnotify,
            phonenotify,
            businessname,
            website,
            onlineVendor
        ).then(
            async (response) => {
                const res = await updateCategories(selected)
                setSuccessful(true)
                setMessage(response.data.message)
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.error) ||
                    error.message ||
                    error.toString()
                setSuccessful(false)
                setMessage(resMessage)
            }
        )
    }
    const getUser = (useremail: any) => {
        UserService.getuserInfo(useremail).then(
            (response) => {
                const data = response.data?.result[0]

                setSuccessful(true)
                setfirstName(data?.first_name)
                setlastName(data?.last_name)
                setPhone(data?.phoneNumber)
                setAddress(
                    data?.address
                        ? data?.address
                        : response?.data?.parentUser?.[0]?.address
                )
                setZipcode(
                    data?.zip ? data?.zip : response?.data?.parentUser?.[0]?.zip
                )
                setWebsite(
                    data?.business_website
                        ? data?.business_website
                        : response?.data?.parentUser?.[0]?.business_website
                )
                setBusinessname(
                    data?.business_name
                        ? data?.business_name
                        : response?.data?.parentUser?.[0]?.business_name
                )
                setOnlineVendor(data?.online_vendor)
                setEmailNotify(data?.enable_email_notification)
                setPhoneNotify(data?.enable_phone_notification)
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.error) ||
                    error.message ||
                    error.toString()
                setSuccessful(false)
            }
        )
    }
    const initialValues = {
        firstname: firstName,
        lastname: lastName,
        phone: phone,
        email: useremail as string,
        address: address,
        zip: zipcode,
        emailnotify: emailNotify,
        phonenotify: phoneNotify,
        businessname: businessname,
        website: website,
        onlineVendor: onlineVendor,
    }

    const isValidPhoneNumber = (phone: any) => {
        const cleanedPhone = phone?.replace(/\D/g, '')
        return cleanedPhone?.length === 10
    }

    const handlePhoneChange = (event: any, form: any) => {
        const input = event.target.value.replace(/\D/g, '')
        if (input.length <= 10) {
            if (isValidPhoneNumber(input)) {
                const formattedPhoneNumber = input.replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    '($1) $2-$3'
                )
                form.setFieldValue('phone', formattedPhoneNumber)
            } else {
                form.setFieldValue('phone', input)
            }
        } else {
            event.preventDefault() // Prevent further input
        }
    }

    return (
        <>
            {subscription && (
                <div className="btn-row">
                    <SubsHeader subscription={subscription} />
                </div>
            )}
            <div className="userview">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={updateUser}
                    enableReinitialize
                >
                    <Form>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <span className="value">{useremail}</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Phone Number</label>
                            <div className="phone-form-control">
                                <span className="country-code" aria-readonly>
                                    +1
                                </span>
                                <Field name="phone">
                                    {({ field, form }: any) => (
                                        <>
                                            <input
                                                {...field}
                                                type="tel"
                                                className={`form-control`}
                                                id="phoneInput"
                                                value={field?.value?.replace(
                                                    /(\d{3})(\d{3})(\d{4})/,
                                                    '($1) $2-$3'
                                                )}
                                                onChange={(event) =>
                                                    handlePhoneChange(
                                                        event,
                                                        form
                                                    )
                                                }
                                            />
                                        </>
                                    )}
                                </Field>
                            </div>

                            <ErrorMessage
                                name="phone"
                                component="div"
                                className="alert alert-danger"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="firstname">
                                First Name
                                <span className="red-text">*</span>
                            </label>
                            <Field
                                name="firstname"
                                type="text"
                                className="form-control"
                                formValue={firstName}
                            />
                            <ErrorMessage
                                name="firstname"
                                component="div"
                                className="alert alert-danger"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastname">
                                Last Name
                                <span className="red-text">*</span>
                            </label>
                            <Field
                                name="lastname"
                                type="text"
                                className="form-control"
                                formValue={lastName}
                            />
                            <ErrorMessage
                                name="lastname"
                                component="div"
                                className="alert alert-danger"
                            />
                        </div>
                        {(userRole === `"vendor-service-provider"` ||
                            userRole === `"vendor-sub-user"`) && (
                            <>
                                <div className="form-group">
                                    <label htmlFor="businessname">
                                        Business Name
                                    </label>
                                    <Field
                                        name="businessname"
                                        type="text"
                                        formValue={businessname}
                                        className="form-control"
                                    />
                                    <ErrorMessage
                                        name="businessname"
                                        component="div"
                                        className="alert alert-danger"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="website">
                                        Business Website
                                    </label>
                                    <Field
                                        name="website"
                                        type="text"
                                        formValue={website}
                                        className="form-control"
                                    />
                                    <ErrorMessage
                                        name="website"
                                        component="div"
                                        className="alert alert-danger"
                                    />
                                </div>
                            </>
                        )}
                        <div className="form-group">
                            <label htmlFor="address">
                                {userRole === `"vendor-service-provider"` ||
                                userRole === `"vendor-sub-user"`
                                    ? 'Business Address'
                                    : 'Address'}
                            </label>
                            <Field
                                name="address"
                                type="text"
                                formValue={address}
                                className="form-control"
                            />
                            <ErrorMessage
                                name="address"
                                component="div"
                                className="alert alert-danger"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="zip">
                                {userRole === `"vendor-service-provider"` ||
                                userRole === `"vendor-sub-user"`
                                    ? 'Business Postal Code'
                                    : 'Postal Code'}
                            </label>
                            <Field
                                name="zip"
                                type="text"
                                formValue={zipcode}
                                className="form-control"
                            />
                            <ErrorMessage
                                name="zip"
                                component="div"
                                className="alert alert-danger"
                            />
                        </div>
                        {(userRole === `"vendor-service-provider"` ||
                            userRole === `"vendor-sub-user"`) && (
                            <div className="form-group">
                                <label>
                                    <Field
                                        type="checkbox"
                                        name="onlineVendor"
                                    />
                                    Online Vendor (Select if any or all sales
                                    are conducted online)
                                </label>
                            </div>
                        )}
                        <div
                            className="form-group"
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}
                        >
                            <p
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                }}
                            >
                                Additional Notification Preferences
                            </p>
                            <label>
                                <Field type="checkbox" name="emailnotify" />
                                Email Notification
                            </label>
                            <label>
                                <Field type="checkbox" name="phonenotify" />
                                Phone Notification
                            </label>
                        </div>
                        {userRole === `"vendor-service-provider"` ||
                        userRole === `"vendor-sub-user"` ? (
                            <div className="form-group">
                                <label>
                                    Select Categories <br />
                                    <small>
                                        (You will get notification for selected
                                        category when a limbo is created in that
                                        category)
                                    </small>
                                </label>
                                <LimboCategories
                                    category={category}
                                    setCategory={setCategory}
                                    setSelected={setSelected}
                                    selected={selected}
                                    handleChange={handleChange}
                                />
                            </div>
                        ) : (
                            ''
                        )}

                        <div
                            className="form-group"
                            style={{
                                width: '100%',
                                display: 'flex',
                            }}
                        >
                            <button type="submit" className="btn-orange">
                                Update
                            </button>
                        </div>
                        {message && (
                            <div className="form-group">
                                {successful ? (
                                    <div
                                        className="alert alert-success"
                                        role="alert"
                                    >
                                        {/* {message} */}
                                    </div>
                                ) : (
                                    <div
                                        className="alert alert-danger"
                                        role="alert"
                                    >
                                        {/* {message} */}
                                    </div>
                                )}
                            </div>
                        )}
                    </Form>
                </Formik>
            </div>
            {/* <div className="update-categories">Update Categories</div> */}
        </>
    )
}

export default Profile
