import React, {useState} from 'react'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import '../ForgotPassword/forgotPassword.scss';
import AuthService from '../../api/services/auth.service'

const ForgotPassword = () => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [successful, setSuccessful] = useState(false)
    const navigate = useNavigate();
    const validationSchema = () => {
        return Yup.object().shape({
            email: Yup.string().email('Please enter the valid email').required("This field is required!"),
        });
      }

      const handleForgotPassword = (formValue: { email: string; }) => {
        const { email } = formValue;
        AuthService.forgotPassword(email).then(
          (response) => {
              setSuccessful(true)
              setMessage(response.data.message)
              localStorage.setItem("forgotEmail", email);
              navigate('/resetpassword')
          },
          (error) => {
              const resMessage =
                  (error.response &&
                      error.response.data &&
                      error.response.data.error) ||
                  error.message ||
                  error.toString()
              setSuccessful(false)
              setMessage(resMessage)
          }
      )
      
      }


      const initialValues = {
        email: "",
      };
  return (
    <div className='forgotPage'>

        <div className='forgotWrap'>
          <div className='forgotLeft'>
            
          <img src={process.env.PUBLIC_URL + '/images/forgotImg.png'} alt="forgotImg" />
          </div>
          <div className='forgotRight'>
          <h3>Forgot your password?</h3>
          <p> Please enter your email address. You will receive a link to create a new password via email.</p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleForgotPassword}
          >
          <Form>
              <div className="form-group">
                <label htmlFor="email">Email<span className='red-text'>*</span></label>
                <Field name="email" type="text" className="form-control" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Send Reset Instructions</span>
                </button>
              </div>

              {message && (
                <div className="form-group">
                  {successful ?
                  <div className="alert alert-success" role="alert">
                    {message}
                  </div> :
                  <div className="alert alert-danger" role="alert">
                  {message}
                </div>}
                </div>
              )}
              </Form>
              </Formik>
        </div>
        </div>
        </div>
  )
}

export default ForgotPassword