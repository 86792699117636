import { MDBDataTable } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import {
    CataegoryHead,
    categoryUpdateFields,
} from '../../../../constant/constant'
import { getIconClassName } from '../../../../helper/utils'
import {
    getCategories,
    updateCategory,
} from '../../../../api/services/category.service'
import Image from '../../../../components/Image/Image'
import { placeholderSm } from '../../../../helper/iconPath'
import './category.scss'
import { FaRegEdit } from 'react-icons/fa'
import { FaCheck } from 'react-icons/fa6'
import { RxCross2 } from 'react-icons/rx'
import { ModalComp } from '../../../../components/Modal/Modal'
import CategoryUpdateForm from './Form/CategoryUpdateForm'
import Swal from 'sweetalert2'
import Loader from '../../../../components/Loader/Loader'

const CategoryListAdmin = () => {
    const [error, seterror] = useState()
    const [data, setdata] = useState<any>([])
    const [loading, setloading] = useState(false)
    const [showModal, setshowModal] = useState(false)
    const [filterType, setFilterType] = useState('')
    const handleEdit = (item: any) => {
        setshowModal(true)
        setFormData({
            id: item._id,
            limboName: item.limboName,
            limboType: item.limboType,
            limboImage: item.limboImage[0],
            status: item.status,
            limbo: item.limbo,
            listing: item.listing,
        })
    }
    const [formData, setFormData] = useState<any>()

    const getCategoryList = async () => {
        setloading(true)
        try {
            const result: any = await getCategories(filterType)
            setloading(false)
            setdata({
                columns: CataegoryHead,
                rows: result.data.map((row: any, index: any) => {
                    return {
                        ...row,
                        status: (
                            <div className={getIconClassName(row.status)}>
                                {row.status}
                            </div>
                        ),
                        limboImage: (
                            <Image
                                className="category__img"
                                src={row.limboImage}
                                alt="limbo image"
                                placeholder={placeholderSm}
                            />
                        ),
                        limbo: (
                            <div>{row.limbo ? <FaCheck title='active'/> : <RxCross2  title='inactive'/>}</div>
                        ),
                        listing: (
                            <div>
                                {row.listing ? <FaCheck title='active'/> : <RxCross2 title='inactive' />}
                            </div>
                        ),
                        action: (
                            <div className="icon__wrapper">
                                <FaRegEdit title='edit' onClick={() => handleEdit(row)} />
                            </div>
                        ),
                    }
                }),
            })
        } catch (error: any) {
            seterror(error.message)
            setloading(false)
        }
    }
    const updateHandler = async () => {
        try {
            const result = await updateCategory(formData.id, formData)
            if (result) {
                Swal.fire({
                    title: 'Updated',
                    text: 'Category updated successful',
                    icon: 'success',
                })
            }
            getCategoryList()
            setshowModal(false)
        } catch (error: any) {
            Swal.fire({
                title: 'Something went wrong',
                text: error.message,
                icon: 'error',
            })
        }
    }

    useEffect(() => {
        getCategoryList()
    }, [])

    useEffect(() => {
        getCategoryList()
    }, [filterType])
    const handleChange = (e: any) => {
        setFilterType(e.target.value);
      };

    if (loading) {
        return (
            <div className="loader__table">
                <Loader />
            </div>
        )
    }

    return (
        <div className="admin__table">
            {error && (
                <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                </div>
            )}
             <div className='filterdropdown'>
      <p>Filter by</p>
      <select value={filterType} onChange={handleChange}>
        <option value="">Select...</option>
        <option value="product">Product</option>
        <option value="service">Service</option>
      </select>
      
    </div>
            <MDBDataTable
                small
                data={data}
                className="responsive-table category"
                responsive
                sortable={false}
            />

            <ModalComp
                onSubmit={updateHandler}
                className="category__update__modal"
                desc={
                    <CategoryUpdateForm
                        field={categoryUpdateFields}
                        formData={formData}
                        setFormData={setFormData}
                    />
                }
                show={showModal}
                title="Update category details"
                setshow={setshowModal}
                submitText="Update"
            />
        </div>
    )
}

export default CategoryListAdmin
