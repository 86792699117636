import React, { useEffect, useState } from 'react'
import {
    getLimbosList,
    getShopperSubs,
    limboNotificationVendor,
} from '../../../../api/services/limbo.service'
import userService from '../../../../api/services/user.service'
import LimboList from '../../../../components/LimboList/LimboList'
import { Link } from 'react-router-dom'
import NoDataFound from '../../../../components/NodataFound/NoDataFound'
import Loader from '../../../../components/Loader/Loader'

const Limbos = () => {
    const [limbos, setLimbos] = useState<any>([])
    const [loading, setloading] = useState(false)
    const userRole = localStorage.getItem('userRole')

    useEffect(() => {
        getLimboListFun()
    }, [])

    const getLimboListFun = async () => {
        try {
            setloading(true)

            let api
            if (userRole === `"shopper"`) {
                api = await getLimbosList('')
                setLimbos(api.data.data)
            } else {
                api = await limboNotificationVendor()
                setLimbos(api.data)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setloading(false)
        }
    }

    if (loading) {
        return (
            <div className="loader__table">
                <Loader />
            </div>
        )
    }

    return (
        <div>
            {userRole == `"shopper"` && (
                <>
                    <div className="btn-row">
                        <Link to={`/start-limbo`} className="btn-orange">
                            Start Limbo
                        </Link>
                    </div>
                </>
            )}

            {limbos && limbos.length > 0 ? (
                limbos.map((item: any, idx: any) => {
                    return <LimboList item={item} key={idx} />
                })
            ) : (
                <NoDataFound />
            )}
        </div>
    )
}

export default Limbos
