import React, { useState } from 'react'
import { ModalComp, ReasonIinput } from '../Modal/Modal'
import { addReport } from '../../api/services/report.service'
import Swal from 'sweetalert2'

const Report = ({ id, reportText, productType }: any) => {
    const [show, setshow] = useState(false)
    const [reason, setreason] = useState<any>({ error: false, text: '' })

    const showModel = () => {
        setshow(true)
    }

    const handleAddReport = async () => {
        if (!reason?.text?.length) {
            setreason({ error: true, text: '' })
        } else {
            try {                
                const res = await addReport(
                    id,
                    reason.text,
                    productType
                )

                if (!res.err) {
                    Swal.fire({
                        title: 'Reported',
                        text: 'Product reported',
                        icon: 'success',
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res?.error?.response?.data?.message,
                    })
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'something went wrong',
                })
            }
        }
    }

    return (
        <div>
            <ModalComp
                className="limbo-panel__modal"
                desc={<ReasonIinput reason={reason} setreason={setreason} />}
                submitText="Report"
                show={show}
                onSubmit={handleAddReport}
                setshow={setshow}
            />
            <div className="btn-report" onClick={showModel}>
                {reportText}
            </div>
        </div>
    )
}

export default Report
