import React, { useState, useEffect } from 'react'
import {
    deleteListing,
    getListingByUser,
} from '../../../../api/services/listing.service'
import ListingList from '../../../../components/ListingList/ListingList'
import { Link, useNavigate } from 'react-router-dom'
import NoDataFound from '../../../../components/NodataFound/NoDataFound'
import Loader from '../../../../components/Loader/Loader'
import { getShopperSubs } from '../../../../api/services/limbo.service'
import SubsHeader from './SubsHeader'
import Swal from 'sweetalert2'
const Listing = () => {
    const [listing, setListing] = useState<any>([])
    const [loading, setloading] = useState(false)
    const userRole = localStorage.getItem('userRole')
    const [subscription, setsubscription] = useState<any>()
    const navigate = useNavigate()

    useEffect(() => {
        getListingData()
    }, [])

    const getListingData = () => {
        setloading(true)
        getListingByUser()
            .then((response) => setListing(response.data))
            .catch((error) => console.log(error))
            .finally(() => setloading(false))
    }

    const getShopperSubscription = async () => {
        try {
            const res = await getShopperSubs()

            if (res?.data?.plan) {
                setsubscription(res?.data?.plan)
            } else {
                setsubscription({})
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (
            userRole === `"vendor-service-provider"` ||
            userRole === `"vendor-sub-user"`
        ) {
            getShopperSubscription()
        }
    }, [])

    const disableListing = async (id: any) => {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: 'To delete the listing',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const result = await deleteListing(id)
                    Swal.fire({
                        title: 'Changes!',
                        text: 'Listing deleted successfully.',
                        icon: 'success',
                    })
                    getListingData()
                    getShopperSubscription()
                }
            })
        } catch (error: any) {
            // seterror(error.message)
        }
    }

    if (loading) {
        return (
            <div className="loader__table">
                <Loader />
            </div>
        )
    }

    return (
        <div className="dashboard-listing-list">
            <div className="btn-row">
                <SubsHeader
                    subscription={subscription}
                    getShopperSubscription={getShopperSubscription}
                />

                <div className="createListing">
                    {(userRole == `"vendor-service-provider"` ||
                        userRole === `"vendor-sub-user"`) && (
                        <Link
                            to={
                                subscription?.name
                                    ? `/start-listing`
                                    : '/subscription/loggedin?description=You have no active subscription plan, please buy plan to add new listing'
                            }
                            className="btn-orange"
                        >
                            Create Listing
                        </Link>
                    )}
                </div>
            </div>
            {listing && listing.length > 0 ? (
                listing?.map((item: any, idx: any) => {
                    return (
                        <ListingList
                            item={item}
                            key={idx}
                            disableListing={disableListing}
                        />
                    )
                })
            ) : (
                <NoDataFound />
            )}
        </div>
    )
}

export default Listing
