export const API_URL = `${process.env.REACT_APP_API_URL}`

export const END_POINTS = {
    // for product and services
    getCategory: `${API_URL}limbo/getLimboType`,
    getFormFieldValues: `${API_URL}limbo/getLimboFieldvalues`,

    //limbo
    saveLimbo: `${API_URL}limbo/saveLimbo`,
    getLimbosListing: `${API_URL}limbos`,

    //listing
    saveListing: `${API_URL}listing/saveListing`,
    updateListingStatus: `${API_URL}listing/updateListingStatus`,
    getListingsList: `${API_URL}listing/getListing`,
    getListingByUser: `${API_URL}listing/getListingByUser`,
    getListingByCategory: `${API_URL}listing/getlistingsByCategory`,
    getFilterFieldValues: `${API_URL}limbo/getFieldvalues`,
    deleteListing: `${API_URL}listing/deleteListing`,
    updateListing: `${API_URL}listing/updateListing`,

    //favourite
    checkout: `${API_URL}checkout`,
    checkoutOneTime: `${API_URL}checkoutOneTime`,
    bannerSlots: `${API_URL}/bannerSlots`,
    subscriptionsPrice: `${API_URL}/subscriptionsPrice`,

    // getLimbosByShopper: `${API_URL}limbo/shopper`,
    getLimboDetail: `${API_URL}limbos`,
    userSubscriptions: `${API_URL}userSubscriptions`,
    isPlanAlreadySubscribed: `${API_URL}isPlanAlreadySubscribed`,
    isAdAlreadyCreated: `${API_URL}isAdAlreadyCreated`,
    getMyFavouriteListing: `${API_URL}user/favorite/listing`,
    addFavourite: `${API_URL}user/addFavorite`,
    addReport: `${API_URL}addReport`,
    getReport: `${API_URL}getReport`,
    seenByAdmin: `${API_URL}seenByAdmin`,
    removeFavourite: `${API_URL}user/deleteFavorite`,
    getAdsForVendor: `${API_URL}api/getads`,
    contantUs: `${API_URL}api/contantUs`,
    getVendorInfo: `${API_URL}api/getads`,
    limboStatus: `${API_URL}limboStatus`,
    uploadImage: `${API_URL}saveFile`,
    categories: `${API_URL}category`,
    subscriptions: `${API_URL}subscriptionPlans`,
    shopperSubs: `${API_URL}shopperSubs`,
    checkSubsPayment: `${API_URL}checkSubsPayment`,
    categoryAdd: `${API_URL}category/add`,
    categoryAppend: `${API_URL}category/appendData`,
    categoryUpdate: `${API_URL}category/updateSchema`,
    userSubscription: `${API_URL}user/subscription`,
    refreshToken: `${API_URL}refreshToken`,
    limboNotificationVendor: `${API_URL}limboNotificationVendor`,

    //add multi user for vendor

    addMultiUser: `${API_URL}addVendor`,
    getMultiUserList: `${API_URL}getSubVendor`,
    changePasswordVendor: `${API_URL}changePasswordVendor`,
}
