import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik'

import * as Yup from 'yup'
import '../Register/register.scss'
import AuthService from '../../api/services/auth.service'
type Props = {}
type State = {
    firstname: string
    email: string
    password: string
    successful: boolean
    message: string
    userType: string
}

const Register = () => {
    const [successful, setSuccessful] = useState(false)
    const [message, setMessage] = useState('')
    const navigate = useNavigate()

    const isValidPhoneNumber = (phone: any) => {
        const cleanedPhone = phone?.replace(/\D/g, '')
        return cleanedPhone?.length === 10
    }

    const validationSchema = () => {
        return Yup.object().shape({
            userType: Yup.string().required('Please select an option'),
            firstname: Yup.string()
                .test(
                    'len',
                    'The firstname must be between 3 and 20 characters.',
                    (val: any) =>
                        val &&
                        val.toString().length >= 3 &&
                        val.toString().length <= 20
                )
                .required('This field is required!'),
            lastname: Yup.string()
                .test(
                    'len',
                    'The lastname must be between 3 and 20 characters.',
                    (val: any) =>
                        val &&
                        val.toString().length >= 3 &&
                        val.toString().length <= 20
                )
                .required('This field is required!'),
            phone: Yup.string().test(
                'len',
                'The phone number must be exactly 10 digits.',
                (val: any) => {
                    const cleanedPhone = val?.replace(/\D/g, '')
                    return !cleanedPhone || cleanedPhone?.length === 10
                }
            ),
            // .required('This field is required!'),

            email: Yup.string()
                .email('This is not a valid email.')
                .required('This field is required!'),
            password: Yup.string()
                .required('Please Enter your password')
                .min(8, 'Must be 8 characters or more')
                .matches(/[a-z]+/, 'One lowercase character')
                .matches(/[A-Z]+/, 'One uppercase character')
                .matches(/[@$!%*#?&]+/, 'One special character')
                .matches(/\d+/, 'One number'),
            cpassword: Yup.string()
                .required('Please retype your password.')
                .oneOf([Yup.ref('password')], 'Your passwords do not match.'),
            termsAndConditions: Yup.bool() // use bool instead of boolean
                .oneOf([true], 'You must accept the terms and conditions'),
        })
    }
    const handleRegister = (formValue: {
        firstname: string
        lastname: string
        phone: string
        email: string
        password: string
        userType: string
    }) => {
        const { firstname, email, password, lastname, phone, userType } =
            formValue

        setSuccessful(false)
        setMessage('')
        AuthService.register(
            firstname,
            lastname,
            phone.replace(/\D/g, ''),
            email,
            password,
            userType
        ).then(
            (response) => {
                setSuccessful(true)
                setMessage(response.data.message)
                const userRole =
                    userType === 'vendor'
                        ? 'vendor-service-provider'
                        : 'shopper'
                localStorage.setItem('userType', userRole)
                navigate('/otp')
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.error) ||
                    error.message ||
                    error.toString()
                setSuccessful(false)
                setMessage(resMessage)
            }
        )
    }

    const handlePhoneChange = (event: any, form: any) => {
        const input = event.target.value.replace(/\D/g, '')
        if (input.length <= 10) {
            if (isValidPhoneNumber(input)) {
                const formattedPhoneNumber = input.replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    '($1) $2-$3'
                )
                form.setFieldValue('phone', formattedPhoneNumber)
            } else {
                form.setFieldValue('phone', input)
            }
        } else {
            event.preventDefault() // Prevent further input
        }
    }

    const initialValues = {
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        password: '',
        cpassword: '',
        userType: '',
        termsAndConditions: false,
    }
    return (
        <div className="registerpage">
            <div className="registerWrap">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleRegister}
                >
                    <Form>
                        <h3>
                            Create Account
                            <div className="registeroptions">
                                <div className="typewrap">
                                    Register as a
                                    <div>
                                        <Field
                                            type="radio"
                                            name="userType"
                                            value="vendor"
                                        />
                                        <label htmlFor="vendor">Vendor</label>
                                    </div>
                                    <div>
                                        <Field
                                            type="radio"
                                            name="userType"
                                            value="shopper"
                                        />
                                        <label htmlFor="shopper">Shopper</label>
                                    </div>
                                </div>
                                <ErrorMessage
                                    name="userType"
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>
                        </h3>
                        {!successful && (
                            <>
                                <div className="registerLeft">
                                    <div className="form-group">
                                        <label htmlFor="firstname">
                                            First Name
                                            <span className="red-text">*</span>
                                        </label>
                                        <Field
                                            name="firstname"
                                            type="text"
                                            className="form-control"
                                        />
                                        <ErrorMessage
                                            name="firstname"
                                            component="div"
                                            className="alert alert-danger"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="lastname">
                                            Last Name
                                            <span className="red-text">*</span>
                                        </label>
                                        <Field
                                            name="lastname"
                                            type="text"
                                            className="form-control"
                                        />
                                        <ErrorMessage
                                            name="lastname"
                                            component="div"
                                            className="alert alert-danger"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">
                                            Phone Number
                                        </label>
                                        <div className="phone-form-control">
                                            <span
                                                className="country-code"
                                                aria-readonly
                                            >
                                                +1
                                            </span>
                                            <Field name="phone">
                                                {({ field, form }: any) => (
                                                    <>
                                                        <input
                                                            {...field}
                                                            type="tel"
                                                            className={`form-control`}
                                                            id="phoneInput"
                                                            onChange={(event) =>
                                                                handlePhoneChange(
                                                                    event,
                                                                    form
                                                                )
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </div>
                                        <ErrorMessage
                                            name="phone"
                                            component="div"
                                            className="alert alert-danger"
                                        />
                                    </div>
                                </div>
                                <div className="registerRight">
                                    <div className="form-group">
                                        <label htmlFor="email">
                                            Email{' '}
                                            <span className="red-text">*</span>
                                        </label>
                                        <Field
                                            name="email"
                                            type="email"
                                            className="form-control"
                                        />
                                        <ErrorMessage
                                            name="email"
                                            component="div"
                                            className="alert alert-danger"
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="password">
                                            Password{' '}
                                            <span className="red-text">*</span>
                                        </label>
                                        <Field
                                            name="password"
                                            type="password"
                                            className="form-control"
                                        />
                                        <ErrorMessage
                                            name="password"
                                            component="div"
                                            className="alert alert-danger"
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="cpassword">
                                            Confirm Password{' '}
                                            <span className="red-text">*</span>
                                        </label>
                                        <Field
                                            name="cpassword"
                                            type="password"
                                            className="form-control"
                                        />
                                        <ErrorMessage
                                            name="cpassword"
                                            component="div"
                                            className="alert alert-danger"
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>
                                        <Field
                                            type="checkbox"
                                            name="termsAndConditions"
                                        />
                                        I have read and agree to Price Limbo’s{' '}
                                        <a href="/privacy" target="_blank">
                                            Privacy Policy
                                        </a>{' '}
                                        and{' '}
                                        <a href="/terms" target="_blank">
                                            Terms & Conditions
                                        </a>
                                    </label>

                                    <ErrorMessage
                                        name="termsAndConditions"
                                        component="div"
                                        className="alert alert-danger"
                                    />
                                </div>
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-block"
                                    >
                                        Proceed Next
                                    </button>
                                </div>
                                {message && (
                                    <div className="form-group">
                                        {successful ? (
                                            <div
                                                className="alert alert-success"
                                                role="alert"
                                            >
                                                {message}
                                            </div>
                                        ) : (
                                            <div
                                                className="alert alert-danger"
                                                role="alert"
                                            >
                                                {message}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </Form>
                </Formik>
            </div>
        </div>
    )
}
export default Register
