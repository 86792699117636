import React from 'react'
import { Provider } from 'react-redux'
import './App.scss'
import ErrorBoundary from './components/ErrorBoundry/ErrorBoundry'
import Header from './layout/Header/Header'
import Footer from './layout/Footer/Footer'
import AppRoutes from './routes/app.routes'
import store from './redux/store'
import './style/style.scss'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './style/responsive.scss'

const App = () => {
    return (
        <div className="pricelimbo">
            <Provider store={store}>
                <Header />
                <ErrorBoundary>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                    ></ToastContainer>
                    <AppRoutes />
                </ErrorBoundary>
                <Footer />
            </Provider>
        </div>
    )
}

export default App
