import React, { useEffect } from 'react'
import '../Filter/filter.scss'

const CategoryFilter = ({
    data,
    handleCheckBox,
    childLimboFieldId,
    limboFieldName,
    limboFieldId,
    alreadyChecked,
}: // filterParams,
any) => {

    
    const handleCheckboxChange = (event: any, item: any) => {
        // event.preventDefault()
        const isChecked = {
            name: event.target.name,
            isCheckboxChecked: event.target.checked,
        }

        handleCheckBox(
            item,
            childLimboFieldId,
            limboFieldId,
            limboFieldName,
            isChecked
        )
    }

    return (
        <div className="categoryfilter filterblock">
            <h5>{limboFieldName}</h5>

            <div className="list-container">
                {data?.map((item: any, idx: any) => {
                    return (
                        <div className="checkboxrow" key={idx}>
                            <label>
                                <input
                                    type="checkbox"
                                    name={item?.fieldValue}
                                    // value={item?.fieldValue}
                                    checked={alreadyChecked(item?.fieldValue)} // Check if the value is already selected from params
                                    onChange={(event) =>
                                        handleCheckboxChange(event, item)
                                    }
                                />
                                {item?.fieldValue}
                            </label>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default CategoryFilter
