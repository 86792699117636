export const formatPriceWithCommas = (price: number | string) => {
    const numericPrice = typeof price === 'string' ? parseFloat(price) : price

    if (isNaN(numericPrice)) {
        return 'Invalid price'
    }

    return new Intl.NumberFormat('en-CA', {
        minimumFractionDigits: 0, // No decimal points
    }).format(numericPrice)
}
