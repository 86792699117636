import React from 'react'
import PageHeader from '../../components/PageHeader/PageHeader'

const TermsCondition = () => {
    return (
        <div className="staticPage">
            <PageHeader title="Terms & Conditions" />
            <div className="staticContent">
                <h4>
                    TERMS AND CONDITIONS
                    <br />
                </h4>
                <p></p>
                <p>
                    These terms and conditions (the &quot;Terms and
                    Conditions&quot;) govern the use of
                    <strong>www.pricelimbo.com</strong> (the&quot;Site&quot;).
                    This Site is owned and operated by Price Limbo Inc.. This
                    Site is an ecommerce website.
                </p>
                <p>
                    By using this Site, you indicate that you have read and
                    understand these Terms and Conditions and agreeto abide by
                    them at all times.
                </p>
                <h4>
                    Intellectual Property
                    <br />
                </h4>
                <p>
                    All content published and made available on our Site is the
                    property of Price Limbo Inc. and the Sitescreators. This
                    includes, but is not limited to images, text, logos,
                    documents, downloadable files andanything that contributes
                    to the composition of our Site.
                </p>
                <h4>
                    Age Restrictions
                    <br />
                </h4>
                <p>
                    The minimum age to use our Site is 16 years old. By using
                    this Site, users agree that they are over 16years old. We do
                    not assume any legal responsibility for false statements
                    about age.
                </p>
                <h4>
                    Acceptable Use
                    <br />
                </h4>
                <p>
                    As a user of our Site, you agree to use our Site legally,
                    not to use our Site for illegal purposes, and not to:
                </p>

                <ul>
                    <li> Harass or mistreat other users of our Site;</li>
                    <li> Violate the rights of other users of our Site;</li>
                    <li>
                        Violate the intellectual property rights of the Site
                        owners or any third party to the Site;
                    </li>
                    <li> Hack into the account of another user of the Site;</li>
                    <li>Act in any way that could be considered fraudulent;</li>
                    <li>
                        Post any material that may be deemed inappropriate or
                        offensive;
                    </li>
                    <li> Pornographic or intimate materials;</li>
                    <li> Use false or misleading information;</li>
                    <li>
                        Transmit computer viruses or any other technologies that
                        could affect or harm Price Limbo orPrice Limbo users;
                    </li>
                    <li>
                        <i>Website Terms and Conditions </i>Page 2 of 5
                    </li>
                    <li>
                        Perform any act which can cause an abnormally heavy load
                        on our infrastructure or interfere withthe normal
                        workings of Price Limbo; or
                    </li>
                    <li> Violate any laws.</li>
                </ul>
                <p>
                    If we believe you are using our Site illegally or in a
                    manner that violates these Terms and Conditions, wereserve
                    the right to limit, suspend or terminate your access to our
                    Site. We also reserve the right to takeany legal steps
                    necessary to prevent you from accessing our Site.
                </p>
                <h4>
                    User Contributions
                    <br />
                </h4>
                <p>Users may post the following information on our Site: </p>
                <ul>
                    <li> Items for sale;</li>
                    <li> Photos;</li>
                    <li> Videos; and</li>
                    <li> Public comments.</li>
                </ul>

                <p>
                    By posting publicly on our Site, you agree not to act
                    illegally or violate these Terms and Conditions.
                </p>
                <h4>
                    Accounts
                    <br />
                </h4>
                <p>
                    When you create an account on our Site, you agree to the
                    following:
                </p>
                <p>
                    1. You are solely responsible for your account and the
                    security and privacy of your account,including passwords or
                    sensitive information attached to that account; and
                </p>
                <p>
                    2. All personal information you provide to us through your
                    account is up to date, accurate, andtruthful and that you
                    will update your personal information if it changes.
                </p>
                <p>
                    We reserve the right to suspend or terminate your account if
                    you are using our Site illegally or if youviolate these
                    Terms and Conditions.
                </p>
                <h4>
                    Sale of Services
                    <br />
                </h4>
                <p>
                    These Terms and Conditions govern the sale of services
                    available on our Site.
                </p>
                <p>The following services are available on our Site: </p>
                <ul>
                    <li>
                        {' '}
                        Digital Ad Space; and Website Terms and Conditions{' '}
                    </li>

                    <li>
                        Retailer / Dealer / Service Provider to Consumer
                        communication service.
                    </li>
                </ul>
                <p>
                    The services will be paid for in full when the services are
                    ordered.
                </p>
                <p>
                    These Terms and Conditions apply to all the services that
                    are displayed on our Site at the time youaccess it. All
                    information, descriptions, or images that we provide about
                    our services are as accurate aspossible. However, we are not
                    legally bound by such information, descriptions, or images
                    as we cannotguarantee the accuracy of all services we
                    provide. You agree to purchase services from our Site at
                    yourown risk.
                </p>
                <p>
                    We reserve the right to modify, reject or cancel your order
                    whenever it becomes necessary. If we cancelyour order and
                    have already processed your payment, we will give you a
                    refund equal to the amount youpaid. You agree that it is
                    your responsibility to monitor your payment instrument to
                    verify receipt of anyrefund.
                </p>
                <h4>
                    Third Party Goods and Services
                    <br />
                </h4>
                <p>
                    Our Site may offer goods and services from third parties. We
                    cannot guarantee the quality or accuracy ofgoods and
                    services made available by third parties on our Site.
                </p>
                <h4>
                    User Goods and Services
                    <br />
                </h4>
                <p>
                    Our Site allows users to sell goods and services. We do not
                    assume any responsibility for the goods andservices users
                    sell on our Site. We cannot guarantee the quality or
                    accuracy of any goods and servicessold by users on our Site.
                    However, if we are made aware that a user is violating these
                    Terms andConditions, we reserve the right to suspend or
                    prohibit the user from selling goods and services on
                    ourSite.
                </p>
                <h4>
                    Subscriptions
                    <br />
                </h4>
                <p>
                    Your subscription automatically renews and you will be
                    automatically billed until we receivenotification that you
                    want to cancel the subscription.
                </p>
                <p>
                    To cancel your subscription, please follow these steps: Your
                    subscription is non-refundable upon initialuse.
                    Cancellations must be made 14 days prior to your next
                    billing date. Once canceled, your service willcontinue until
                    the end of the subscriptions billing period, at which time
                    your subscription will terminate.
                </p>
                <h4>
                    Free Trial
                    <br />
                </h4>
                <p>
                    We offer the following free trial of our services: A 30 day
                    free trial begins when subscriber registers fora new
                    account. The free trial includes unlimited access to all
                    features available on our site.
                </p>
                <p>
                    At the end of your free trial, the following will occur: You
                    will automatically be billed the selectedsubscription rate.
                    If you do not want to be billed, you will need to cancel
                    your subscription before yourfree trial ends.
                </p>

                <p>
                    <i>Website Terms and Conditions </i>Page 4 of 5
                </p>
                <p>
                    To cancel your free trial, please follow these steps: Log in
                    to your account and select &quot;Cancel Free
                    Trial&quot;under users account.
                </p>
                <h4>
                    Payments
                    <br />
                </h4>

                <p>We accept the following payment methods on our Site: </p>
                <ul>
                    <li>Credit Card;</li>
                    <li>PayPal;</li>
                    <li>Debit; and</li>
                    <li>Direct Debit.</li>
                </ul>

                <p>
                    When you provide us with your payment information, you
                    authorize our use of and access to thepayment instrument you
                    have chosen to use. By providing us with your payment
                    information, youauthorize us to charge the amount due to
                    this payment instrument.
                </p>
                <p>
                    If we believe your payment has violated any law or these
                    Terms and Conditions, we reserve the right tocancel or
                    reverse your transaction.
                </p>
                <h4>
                    Consumer Protection Law
                    <br />
                </h4>
                <p>
                    Where the <i>Consumer Protection Act</i>, or any other
                    consumer protection legislation in your jurisdictionapplies
                    and cannot be excluded, these Terms and Conditions will not
                    limit your legal rights and remediesunder that legislation.
                    These Terms and Conditions will be read subject to the
                    mandatory provisions ofthat legislation. If there is a
                    conflict between these Terms and Conditions and that
                    legislation, themandatory provisions of the legislation will
                    apply.
                </p>
                <h4>
                    Links to Other Websites
                    <br />
                </h4>
                <p>
                    Our Site contains links to third party websites or services
                    that we do not own or control. We are notresponsible for the
                    content, policies, or practices of any third party website
                    or service linked to on ourSite. It is your responsibility
                    to read the terms and conditions and privacy policies of
                    these third partywebsites before using these sites.
                </p>
                <h4>
                    Limitation of Liability
                    <br />
                </h4>
                <p>
                    Price Limbo Inc. and our directors, officers, agents,
                    employees, subsidiaries, and affiliates will not beliable
                    for any actions, claims, losses, damages, liabilities and
                    expenses including legal fees from youruse of the Site.
                </p>

                <p>
                    <i>Website Terms and Conditions </i>
                </p>
                <h4>
                    Indemnity
                    <br />
                </h4>
                <p>
                    Except where prohibited by law, by using this Site you
                    indemnify and hold harmless Price Limbo Inc.and our
                    directors, officers, agents, employees, subsidiaries, and
                    affiliates from any actions, claims,losses, damages,
                    liabilities and expenses including legal fees arising out of
                    your use of our Site or yourviolation of these Terms and
                    Conditions.
                </p>
                <h4>
                    Applicable Law
                    <br />
                </h4>
                <p>
                    These Terms and Conditions are governed by the laws of the
                    Province of Ontario.
                </p>
                <h4>
                    Severability
                    <br />
                </h4>
                <p>
                    If at any time any of the provisions set forth in these
                    Terms and Conditions are found to be inconsistentor invalid
                    under applicable laws, those provisions will be deemed void
                    and will be removed from theseTerms and Conditions. All
                    other provisions will not be affected by the removal and the
                    rest of theseTerms and Conditions will still be considered
                    valid.
                </p>
                <h4>
                    Changes
                    <br />
                </h4>
                <p>
                    These Terms and Conditions may be amended from time to time
                    in order to maintain compliance withthe law and to reflect
                    any changes to the way we operate our Site and the way we
                    expect users to behaveon our Site. We will notify users by
                    email of changes to these Terms and Conditions or post a
                    notice onour Site.
                </p>
                <h4>
                    Contact Details
                    <br />
                </h4>
                <p>
                    Please contact us if you have any questions or concerns. Our
                    contact details are as follows:
                </p>
                <p>
                    support@pricelimbo.com
                    <br />
                    10 Four Seasons Place, Suite 1000, Toronto, Ontario, M9B 6H7
                </p>
                <p>
                    You can also contact us through the feedback form available
                    on our Site.
                </p>
                <p style={{ textAlign: 'right' }}>
                    Effective Date: 1st day of September, 2022{' '}
                </p>
            </div>
        </div>
    )
}

export default TermsCondition
