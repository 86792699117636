import React, { useEffect, useMemo, useState } from 'react'
import '../../App.scss'
import '../Header/header.scss'
import Navbar from '../../components/Navbar/Navbar'

import HeaderIcons from './HeaderIcons'
import userService from '../../api/services/user.service'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Search from './Search'
import moment from 'moment'
import returnTimePassed from '../../utils/timePassedFrom'
import Chat from '../../pages/Chat/Chat'
import { GiNorthStarShuriken } from "react-icons/gi";
import {
    setChatLoading,
    setLimboData,
    setLimboLoading,
    setChatData,
    setAdsLoading,
    setAdsData,
} from '../../redux/notificationSlice'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading, setUserData } from '../../redux/userSlice'
import { MdOutlineLocalOffer } from 'react-icons/md'
import { BsBraces } from 'react-icons/bs'
import { FaRegHandshake } from 'react-icons/fa6'
import { CiChat1 } from 'react-icons/ci'

const Header = () => {
    const userEmail = localStorage.getItem('email')?.split('"')?.[1]
    const userStr = localStorage.getItem('user')
    const user = useSelector((state: any) => state?.user?.userData)

    const dispatch = useDispatch<any>()

    const fetchData = async (userEmail: any, userStr: any) => {
        // await dispatch(getUserDataFromApi({}))
        try {
            dispatch(setLoading(true))

            // const userEmail = localStorage.getItem('email')?.split('"')?.[1]
            // const userStr = localStorage.getItem('user')
            let userData = null

            if (userStr) userData = JSON.parse(userStr)

            if (userEmail && userData) {
                const res: any = await userService.getuserInfo(userEmail)
                if (res?.data?.success && res?.data?.success === true) {
                    dispatch(setUserData(res?.data?.result?.[0]))
                } else {
                    dispatch(setUserData({}))
                }
            } else {
                dispatch(setUserData({}))
            }
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            dispatch(setLoading(false))
        }
    }

    useEffect(() => {
        fetchData(userEmail, userStr)
    }, [userEmail, userStr])

    const userDataRedux = useSelector((state: any) => state.user.userData)
    const userRole = localStorage.getItem('userRole')

    // const [unreadNotification, setUnreadNotification] = useState([])

    // const userEmail = localStorage.getItem('email')?.split('"')?.[1]
    // const userStr = localStorage.getItem('user')
    let userData: any = null
    if (userStr) userData = JSON.parse(userStr)

    const chatLoading = useSelector(
        (state: any) => state.notification.chatLoading
    )
    const chatData = useSelector((state: any) => state.notification.chatData)

    const getAllUnreadNotifications = async () => {
        if (userEmail && userData) {
            dispatch(setChatLoading(true))
            const res: any = await userService.getUnReadNotification(userEmail)
            if (res?.success && res?.success == true) {
                dispatch(setChatData(res?.data))
                dispatch(setChatLoading(false))
            } else {
                dispatch(setChatData([]))
                dispatch(setChatLoading(false))
            }
        } else {
            dispatch(setChatData([]))
            dispatch(setChatLoading(false))
        }
    }

    useEffect(() => {
        getAllUnreadNotifications()
    }, [])

    // console.log(unreadNotification, ' unreadNotification getUnReadNotification')

    const [showChat, setShowChat] = useState({
        shopperId: '',
        vendorId: '',
        limboId: '',
        show: false,
    })

    const toggleShowChat = (
        shopperId: string,
        vendorId: string,
        limboId: string
    ) => {
        setShowChat((prev) => ({
            shopperId,
            vendorId,
            limboId,
            show: !prev.show,
        }))
    }

    const openChatRoom = (chatData: any) => {
        const listingId = chatData?.currChatRoomData?.listing
        const shopperEmail = chatData?.currChatRoomData?.shopper
        const vendorEmail = chatData?.currChatRoomData?.vendor
        toggleShowChat(shopperEmail, vendorEmail, listingId)
    }

    const limboData = useSelector((state: any) => state.notification.limboData)
    const limboLoading = useSelector(
        (state: any) => state.notification.limboLoading
    )
    const navigate = useNavigate()

    useEffect(() => {
        getLimboNotification();

        // Set an interval to call the function every 30 seconds
        const interval = setInterval(() => {
            getLimboNotification();
        }, 30000); // 30000 ms = 30 seconds
        return () => clearInterval(interval);
    }, []);

    const adsData = useSelector((state: any) => state.notification.adsData)
    const adsLoading = useSelector(
        (state: any) => state.notification.adsLoading
    )
    const getAdsNotification = async () => {
        try {
            if (userEmail && userData) {
                let res
                const userRole = localStorage.getItem('userRole')
                const email: string = localStorage.getItem('email') || ''
                if (userRole === '"admin"') {
                    res = await userService.getPendingAds()
                } else if (
                    userRole === '"vendor-service-provider"' ||
                    userRole === '"vendor-sub-user"'
                ) {
                    res = await userService.getRejectedAds(
                        undefined,
                        email?.split('"')?.[1]
                    )
                }
                dispatch(setAdsLoading(true))
                if (res?.data?.success && res?.data?.success == true) {
                    dispatch(setAdsLoading(false))
                    dispatch(setAdsData(res?.data?.result))
                } else {
                    dispatch(setAdsLoading(false))
                    dispatch(setAdsData([]))
                }
            } else {
                dispatch(setAdsLoading(false))
                dispatch(setAdsData([]))
            }
        } catch (err) {
            console.log('err ', err)
        }
    }

    useEffect(() => {
        getAdsNotification()
    }, [])

    const getLimboNotification = async () => {
        try {
            if (userEmail && userData) {
                dispatch(setLimboLoading(true))
                const res: any = await userService.getLimboNotification(
                    userEmail
                )

                if (res?.success && res?.success == true) {
                    dispatch(setLimboLoading(false))
                    dispatch(setLimboData(res?.data))
                } else {
                    dispatch(setLimboLoading(false))
                    dispatch(setLimboData([]))
                }
            } else {
                dispatch(setLimboLoading(false))
                dispatch(setLimboData([]))
            }
        } catch (err) {
            console.log('err ', err)
        }
    }

    const handleOpenLimbo = (limboDetails: any) => {
        const limboId = limboDetails?.limboId
        // navigate(`/limbo-detail/${limboId}`)
        window.open(`/limbo-detail/${limboId}`, '_blank')
    }

    const markAsReadLocally = (
        notificationId: any,
        isLimboNotification: any,
        isAdsNotification: any
    ) => {
        if (isLimboNotification) {
            const limboDataTemp = JSON.parse(JSON.stringify(limboData))
            const index = limboDataTemp.findIndex(
                (data: any) => data._id === notificationId
            )

            if (index > -1) {
                limboDataTemp[index].read = true
                dispatch(setLimboData(limboDataTemp))
            }
        } else if (isAdsNotification) {
            const adsDataTemp = JSON.parse(JSON.stringify(adsData))
            const index = adsDataTemp.findIndex(
                (data: any) => data.adId === notificationId
            )

            if (index > -1) {
                adsDataTemp[index].read = true
                dispatch(setAdsData(adsDataTemp))
            }
        } else {
            const chatDataTemp = JSON.parse(JSON.stringify(chatData))

            const index = chatDataTemp.findIndex(
                (data: any) => data.unReadChat._id === notificationId
            )

            if (index > -1) {
                chatDataTemp[index].unReadChat.readByReceiver = true
                dispatch(setChatData(chatDataTemp))
            }
        }
    }

    const handleOpenAd = (adDetails: any) => {
        if (
            userRole === '"vendor-service-provider"' ||
            userRole === '"vendor-sub-user"'
        ) {
            window.open(`dashboard/ads`, '_blank')
        } else {
            window.open(`dashboard/ad-list`, '_blank')
        }
    }

    const loggedInUserType = localStorage.getItem('userRole')

    const sortedAllNotification = useMemo(() => {
        const allNotification = [
            ...(limboData || []),
            ...(chatData || []),
            ...(adsData || []),
        ]

        const allNotificationWithDate = allNotification.map((data: any) => {
            const isLimboNotification = data?.limboId ? true : false
            const isAdsNotification = data?.adId ? true : false
            let timeStamp: any = ''
            const chatData = data
            const limboDetails = data
            if (isLimboNotification) {
                timeStamp = limboDetails?.createdAt
                    ? moment(limboDetails?.createdAt)
                    : moment(new Date())
            } else {
                timeStamp = chatData?.unReadChat?.time_stamp
                    ? moment(chatData?.unReadChat?.time_stamp)
                    : moment(new Date())
            }

            if (isAdsNotification) {
                timeStamp = data?.createdAt
                    ? moment(data?.createdAt)
                    : moment(new Date())
            }
            return {
                ...data,
                timeStamp,
            }
        })

        const sortedAllNotificationTemp = allNotificationWithDate.sort(
            (x, y) => {
                if (x.timeStamp.diff(y.timeStamp) >= 0) {
                    return -1
                } else {
                    return 1
                }
            }
        )

        return sortedAllNotificationTemp
    }, [chatData, limboData, adsData])

    const popoverClickRootClose = useMemo(() => {
        return (
            <Popover
                id="popover-trigger-click-root-close"
                title="Popover bottom"
                className="header-popover-notification"
                style={{
                    // zoom: 0.4,
                    // maxHeight:'350px',
                    // overflow:'clip'
                    borderRadius: '16px',
                    minWidth: '320px',
                }}
            >
                {/* <Notification/> */}
                <div className="chatroom-container">
                    <div className="chat-title">
                        NOTIFICATION
                        <div className="notification-number">
                            You have {sortedAllNotification?.length || 0}{' '}
                            notification
                        </div>
                    </div>
                    <div>
                        {sortedAllNotification.map((data: any, key) => {
                            const isLimboNotification = data?.limboId
                                ? true
                                : false
                            const isAdsNotification = data?.adId ? true : false

                            let timeStamp: any = ''
                            let timeText: any = ''
                            const chatData = data
                            const limboDetails = data
                            const adsDetails = data
                            if (isLimboNotification) {
                                timeStamp = limboDetails?.createdAt
                                    ? moment(limboDetails?.createdAt)
                                    : moment(new Date())
                                timeText = returnTimePassed(timeStamp)
                            } else {
                                timeStamp = data?.unReadChat?.time_stamp
                                    ? moment(data?.unReadChat?.time_stamp)
                                    : moment(new Date())
                                timeText = returnTimePassed(timeStamp)
                            }

                            if (isAdsNotification) {
                                timeStamp = data?.createdAt
                                timeText = returnTimePassed(timeStamp)
                            }
                            const isRead = isLimboNotification
                                ? limboDetails?.read
                                : isAdsNotification
                                ? adsDetails?.read
                                : chatData?.unReadChat?.readByReceiver
                            const notificationId = isLimboNotification
                                ? limboDetails?._id
                                : isAdsNotification
                                ? adsDetails?.adId
                                : chatData?.unReadChat?._id

                            const chatContentClass = isRead
                                ? 'chat-content read'
                                : 'chat-content unread'

                            return isLimboNotification ? (
                                <>
                                    <div
                                        className={chatContentClass}
                                        key={limboDetails?._id}
                                        onClick={async () => {
                                            markAsReadLocally(
                                                notificationId,
                                                isLimboNotification,
                                                undefined
                                            )
                                            await userService.updateLimboReadStatus(
                                                notificationId
                                            )
                                            handleOpenLimbo(limboDetails)
                                        }}
                                    >
                                        {/* <div className="chat-img-title"> */}
                                        {/* <span
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                borderBottom: '1px solid black',
                                            }}
                                        > */}
                                        <span>
                                            <span className="chat-img">
                                                {limboDetails?.stage ===
                                                'NEWOFFER' ? (
                                                    <MdOutlineLocalOffer
                                                        style={{
                                                            fontSize: '1.4rem',
                                                            margin: '0.5rem',
                                                            color: '#FF0000',
                                                        }}
                                                    />
                                                ) : limboDetails?.stage ===
                                                  'LIMBOSTART' ? (
                                                    <GiNorthStarShuriken
                                                        style={{
                                                            fontSize: '1.4rem',
                                                            margin: '0.5rem',
                                                            color: '#FF0000',
                                                        }}
                                                    />
                                                ) : (
                                                    <FaRegHandshake
                                                        style={{
                                                            fontSize: '1.4rem',
                                                            margin: '0.5rem',
                                                            color: '#FF0000',
                                                        }}
                                                    />
                                                )}
                                            </span>
                                            <span className="chat-email">
                                                {limboDetails?.stage ===
                                                'NEWOFFER'
                                                    ? `New Offer`
                                                    : limboDetails?.stage ===
                                                      'LIMBOSTART'
                                                    ? 'New Limbo'
                                                    : 'Hand Shake'}
                                            </span>
                                        </span>
                                        <span className="chat-message">
                                            {limboDetails?.stage === 'NEWOFFER'
                                                ? `A more competitive offer was placed for this limbo :`
                                                : limboDetails?.stage ===
                                                  'LIMBOSTART'
                                                ? 'Time for you to compete in '
                                                : loggedInUserType !=
                                                  `"shopper"`
                                                ? 'A shopper has accepted your offer!                                                '
                                                : 'You finalized the offer for this limbo: '}
                                            &nbsp;
                                            {limboDetails?.limboName}
                                        </span>
                                        <span className="chat-timestamp">
                                            {timeText}
                                        </span>
                                        {/* </span> */}
                                        {/* </div> */}
                                    </div>
                                </>
                            ) : isAdsNotification ? (
                                <>
                                    <div
                                        className={chatContentClass}
                                        key={adsDetails?.adId}
                                        onClick={async () => {
                                            markAsReadLocally(
                                                notificationId,
                                                undefined,
                                                isAdsNotification
                                            )
                                            if (!isRead) {
                                                await userService.updateAdsReadStatus(
                                                    notificationId
                                                )
                                            }
                                            handleOpenAd(adsDetails)
                                        }}
                                    >
                                        {/* <div className="chat-img-title"> */}
                                        {/* <span
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                borderBottom: '1px solid black',
                                            }}
                                        > */}
                                        <span>
                                            <span className="chat-img">
                                                <MdOutlineLocalOffer
                                                    style={{
                                                        fontSize: '1.4rem',
                                                        margin: '0.5rem',
                                                        color: '#FF0000',
                                                    }}
                                                />
                                            </span>
                                            <span className="chat-email">
                                                {adsDetails?.adName}
                                            </span>
                                        </span>
                                        <span className="chat-message">
                                            {adsDetails?.adDesc}
                                        </span>
                                        <span className="chat-timestamp">
                                            {timeText}
                                        </span>
                                        {/* </span> */}
                                        {/* </div> */}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div
                                        className={chatContentClass}
                                        key={chatData?.currChatRoomData?._id}
                                        onClick={() => {
                                            openChatRoom(chatData)
                                            markAsReadLocally(
                                                notificationId,
                                                isLimboNotification,
                                                undefined
                                            )
                                        }}
                                    >
                                        {/* <div className="chat-img-title"> */}
                                        {/* <span
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                borderBottom: '1px solid black',
                                            }}
                                        > */}
                                        <span>
                                            <span className="chat-img">
                                                <CiChat1
                                                    style={{
                                                        fontSize: '1.4rem',
                                                        margin: '0.5rem',
                                                        color: '#FF0000',
                                                    }}
                                                />
                                            </span>
                                            <span className="chat-email">
                                                {chatData?.unReadChat?.sender}
                                            </span>
                                        </span>
                                        <span className="chat-message">
                                            {chatData?.unReadChat?.content
                                                ? chatData?.unReadChat?.content.substring(
                                                      0,
                                                      50
                                                  )
                                                : ''}
                                        </span>
                                        <span className="chat-timestamp">
                                            {timeText}
                                        </span>
                                        {/* </span> */}
                                        {/* </div> */}
                                    </div>
                                </>
                            )
                        })}
                        <div
                            className="show-all-notification-button"
                            onClick={() => {
                                navigate('/notification')
                                document && document.body.click()
                            }}
                        >
                            Show All
                        </div>
                    </div>
                </div>
            </Popover>
        )
    }, [chatData, limboData, adsData])

    const [openPopup, setOpenPopup] = useState(false)

    const openOverlay = () => {
        // setOpenPopup(true)
    }

    const closeOverlay = () => {
        setOpenPopup(false)
    }

    const items = [
        {
            icon: 'location',
            label: 'Location',
            value: `${userDataRedux?.district || ''}  ${
                userDataRedux?.state || ''
            }`,
        },
    ]

    return (
        <header>
            <div className="header">
                <div className="head">
                    <a className="logo" href="/">
                        <img
                            src={process.env.PUBLIC_URL + '/images/logo.svg'}
                            alt="Logo"
                        />
                    </a>
                    <Search />
                    {userRole && <HeaderIcons items={items} />}

                    <div className="header-icons">
                        <ul
                            onClick={() => {
                                setOpenPopup((prev) => !prev)
                            }}
                        >
                            <OverlayTrigger
                                trigger="click"
                                placement="left"
                                rootClose={true}
                                show={openPopup}
                                onToggle={() => {
                                    // setOpenPopup((prev) => !prev)
                                    closeOverlay()
                                }}
                                overlay={popoverClickRootClose}
                            >
                                <li
                                    key={'notification'}
                                    className="notification"
                                    // onMouseEnter={openOverlay}
                                    // onClick={() => {
                                    //     navigate('/notification')
                                    // }}
                                >
                                    {userEmail &&
                                        user &&
                                        user.first_name &&
                                        user.first_name.length > 0 && (
                                            <span className="first-letter">
                                                {user.first_name.charAt(0)}
                                            </span>
                                        )}

                                    <img
                                        src={`/images/notification-icon.svg`}
                                        alt={` Icon`}
                                        className=""
                                    />
                                    {sortedAllNotification?.length > 0 ? (
                                        <div className="notification-dot">
                                            {[
                                                ...(limboData || []),
                                                ...(chatData || []),
                                                ...(adsData || []),
                                            ]?.length || 0}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </li>
                            </OverlayTrigger>
                        </ul>
                    </div>
                    {/* {userRole && <HeaderIcons items={items} />} */}
                </div>
            </div>

            <Navbar />
            {showChat?.show ? (
                <Chat
                    shopperId={showChat?.shopperId}
                    vendorId={showChat?.vendorId}
                    listingId={showChat?.limboId ? showChat.limboId : ''}
                    closeCallback={() => {
                        setShowChat((prev) => ({
                            ...prev,
                            show: !prev.show,
                        }))
                    }}
                />
            ) : (
                ''
            )}
        </header>
    )
}

export default Header
