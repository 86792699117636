import React from 'react'

import PageHeader from '../../components/PageHeader/PageHeader'

const PrivacyPolicy = () => {
    return (
        <div className="staticPage">
            <PageHeader title="Privacy Policy" />
            <div className="staticContent">
                <p style={{ textAlign: 'center' }}>
                    Type of website: Ecommerce <br />
                    Effective date: 1st day of February, 2024
                </p>
                <p>
                    www.pricelimbo.com (the &quot;Site&quot;) is owned and
                    operated by Price Limbo Inc.. Price Limbo Inc. can be
                    contacted at: <br />
                    <br />
                    support@pricelimbo.com <br />
                    (647) 490-555710 <br />
                    Four Seasons Place, Suite 1000, Toronto, Ontario, M9B 6H7
                </p>
                <h4>Purpose</h4>
                <p>
                    The purpose of this privacy policy (this &quot;Privacy
                    Policy&quot;) is to inform users of our Site of the
                    following:
                </p>
                <ol>
                    <li>The personal data we will collect;</li>
                    <li>Use of collected data;</li>
                    <li>Who has access to the data collected;</li>
                    <li>The rights of Site users; and</li>
                    <li>The Sites cookie policy.</li>
                </ol>

                <p>
                    This Privacy Policy applies in addition to the terms and
                    conditions of our Site.
                </p>

                <h4>Consent</h4>
                <p> By using our Site users agree that they consent to:</p>

                <ol>
                    <li>The conditions set out in this Privacy Policy; and</li>
                    <li>
                        The collection, use, and retention of the data listed in
                        this Privacy Policy.
                    </li>
                </ol>

                <h4>Personal Data We Collect</h4>
                <p>
                    We only collect data that helps us achieve the purpose set
                    out in this Privacy Policy. We will not collect any
                    additional data beyond the data listed below without
                    notifying you first.
                </p>

                <p>Data Collected Automatically </p>
                <p>
                    When you visit and use our Site, we may automatically
                    collect and store the following information:
                </p>

                <ol>
                    <li>IP address; </li>
                    <li>Location; </li>
                    <li>Hardware and software details; </li>
                    <li>Clicked links; </li>
                    <li>Content viewed; </li>
                    <li>Statistics on page views; </li>
                    <li>Traffic to and from www.pricelimbo.com; </li>
                    <li>Referring URL; </li>
                    <li>Ad data; </li>
                    <li>Standard web log information; and </li>
                    <li>
                        Information collected through cookies, web beacons and
                        similar technologies.
                    </li>
                </ol>

                <p>
                    Data Collected in a Non-Automatic Way <br />
                    We may also collect the following data when you perform
                    certain functions on our Site:
                </p>

                <ol>
                    <li>First and last name;</li>
                    <li>Age;</li>
                    <li>Date of birth;</li>
                    <li>Email address;</li>
                    <li>Phone number;</li>
                    <li>Address;</li>
                    <li>Payment information;</li>
                    <li>Auto fill data;</li>
                    <li>
                        Any information provided to us when registering for an
                        account as a user or subscriber; and
                    </li>
                    <li>
                        Any correspondence sent to us through our website or
                        other.
                    </li>
                </ol>

                <p>This data may be collected using the following methods:</p>
                <ol>
                    <li>Subscription &amp; Account Creation; and </li>
                    <li>General site usage.</li>
                </ol>

                <h4>How We Use Personal Data</h4>
                <p>
                    Data collected on our Site will only be used for the
                    purposes specified in this Privacy Policy or indicated on
                    the relevant pages of our Site. We will not use your data
                    beyond what we disclose in this
                </p>
                <p>
                    <i>Website Privacy Policy </i>
                </p>
                <p>
                    Privacy Policy. The data we collect automatically is used
                    for the following purposes:
                </p>

                <ol>
                    <li>Statistics; and</li>
                    <li>User Experience.</li>
                </ol>

                <p>
                    The data we collect when the user performs certain functions
                    may be used for the following purposes:
                </p>
                <ol>
                    <li>
                        To provide access to our Services and Customer Support
                        through email or telephone;
                    </li>
                    <li>
                        In prevention, detection and investigation of
                        potentially prohibited or illegal activities, fraud and
                        security breaches;
                    </li>
                    <li>
                        To personalize and enhance the users experience; and
                    </li>
                    <li>
                        To contact you by email, text message (SMS) or push
                        notification as selected by you and provided by our
                        service.
                    </li>
                </ol>

                <h4>Who We Share Personal Data With</h4>
                <p>
                    Employees We may disclose user data to any member of our
                    organization who reasonably needs access to user data to
                    achieve the purposes set out in this Privacy Policy.
                </p>
                <p>
                    Other Disclosures <br />
                    We will not sell or share your data with other third
                    parties, except in the following cases:
                </p>
                <ol>
                    <li>If the law requires it;</li>
                    <li>If it is required for any legal proceeding;</li>
                    <li>To prove or protect our legal rights; and</li>
                    <li>
                        To buyers or potential buyers of this company in the
                        event that we seek to sell the company.
                    </li>
                </ol>

                <p>
                    If you follow hyperlinks from our Site to another site,
                    please note that we are not responsible for andhave no
                    control over their privacy policies and practices.
                </p>
                <h4>How Long We Store Personal Data</h4>
                <p>
                    User data will be stored until the purpose the data was
                    collected for has been achieved.
                </p>
                <p>
                    <i>Website Privacy Policy </i>
                </p>
                <p>
                    You will be notified if your data is kept for longer than
                    this period.
                </p>
                <h4>How We Protect Your Personal Data</h4>
                <p>
                    In order to protect your security, we use the strongest
                    available browser encryption and store all of ourdata on
                    servers in secure facilities. All data is only accessible to
                    our employees. Our employees arebound by strict
                    confidentiality agreements and a breach of this agreement
                    would result in theemployees termination. <br />
                    <br />
                    While we take all reasonable precautions to ensure that user
                    data is secure and that users are protected,there always
                    remains the risk of harm. The Internet as a whole can be
                    insecure at times and thereforewe are unable to guarantee
                    the security of user data beyond what is reasonably
                    practical.
                </p>
                <h4>Children</h4>
                <p>
                    The minimum age to use our website is 16 years of age. We do
                    not knowingly collect or use personaldata from children
                    under 13 years of age. If we learn that we have collected
                    personal data from a childunder 13 years of age, the
                    personal data will be deleted as soon as possible. If a
                    child under 13 years ofage has provided us with personal
                    data their parent or guardian may contact our privacy
                    officer.
                </p>
                <h4>
                    How to Access, Modify, Delete, or Challenge the Data
                    Collected
                </h4>
                <p>
                    If you would like to know if we have collected your personal
                    data, how we have used your personaldata, if we have
                    disclosed your personal data and to who we disclosed your
                    personal data, or if youwould like your data to be deleted
                    or modified in any way, please contact our privacy officer
                    here: <br />
                    <br />
                    Dennis Vella <br />
                    support@pricelimbo.com
                    <br />
                    (647) 490-5557 <br />
                    1000 -10 Four Seasons Place, Etobicoke, Ontario, M9B 0A6
                </p>
                <h4>Cookie Policy</h4>
                <p>
                    A cookie is a small file, stored on a users hard drive by a
                    website. Its purpose is to collect data relatingto the users
                    browsing habits. You can choose to be notified each time a
                    cookie is transmitted. You canalso choose to disable cookies
                    entirely in your internet browser, but this may decrease the
                    quality ofyour user experience.
                </p>
                <p>We use the following types of cookies on our Site:</p>
                <p>
                    <i>Website Privacy Policy </i>
                </p>

                <p>&#169;2002-2024 LawDepot.ca&#174;</p>

                <ol>
                    <li>
                        <p>
                            <u>Functional cookies</u>
                        </p>
                        <p>
                            Functional cookies Functional cookies are used to
                            remember the selections you make on our Site so that
                            yourselections are saved for your next visits;
                        </p>
                    </li>
                    <li>
                        <p>
                            <u>Analytical cookies</u>
                        </p>
                        <p>
                            Analytical cookies Analytical cookies allow us to
                            improve the design and functionality of our Site by
                            collecting dataon how you access our Site, for
                            example data on the content you access, how long you
                            stay onour Site, etc; and
                        </p>
                    </li>
                    <li>
                        <p>
                            <u>Targeting cookies</u>
                        </p>
                        <p>
                            Targeting cookies Targeting cookies collect data on
                            how you use the Site and your preferences. This
                            allows us topersonalize the information you see on
                            our Site for you.
                        </p>
                    </li>
                </ol>

                <h4>Modifications</h4>
                <p>
                    This Privacy Policy may be amended from time to time in
                    order to maintain compliance with the lawand to reflect any
                    changes to our data collection process. When we amend this
                    Privacy Policy we willupdate the &quot;Effective Date&quot;
                    at the top of this Privacy Policy. We recommend that our
                    users periodicallyreview our Privacy Policy to ensure that
                    they are notified of any updates. If necessary, we may
                    notifyusers by email of changes to this Privacy Policy.
                </p>
                <h4>Contact Information</h4>
                <p>
                    If you have any questions, concerns or complaints, you can
                    contact our privacy officer, Dennis Vella,at:
                    <br />
                    <br />
                    support@pricelimbo.com <br />
                    (647) 490-5557
                    <br />
                    1000 -10 Four Seasons Place, Etobicoke, Ontario, M9B 0A6
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicy
