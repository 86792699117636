import axios, { InternalAxiosRequestConfig } from 'axios'
import { toast } from 'react-toastify'
import authHeader from './services/auth-header'
import authService from './services/auth.service'

const customAxios = axios.create()
// Add a request interceptor
customAxios.interceptors.request.use(
    function (config: InternalAxiosRequestConfig) {
        // Do something before request is sent
        const { method, url } = config
        config.headers.Authorization = authHeader()?.Authorization
        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    }
)

// Add a response interceptor
customAxios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response
    },
    async function (error) {
        if (error?.code === 'ERR_NETWORK') {
            toast.error(error.message, {
                toastId: 'erromessage',
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            })
        }

        if (error?.response?.status === 401) {
            //redirect to login
            try {
                const response: any = await authService.refreshToken()

                if (response.data) {
                    localStorage.setItem('user', JSON.stringify(response.data))
                    return null
                }
            } catch (error) {
                if (window.location.pathname !== '/login') {
                    window.location.href = '/login'
                    toast('Unauthorized Access', {
                        type: 'error',
                        autoClose: 2000,
                        toastId: 'customId',
                    })
                } else {
                    toast('Please login again', {
                        type: 'info',
                        autoClose: 2000,
                        toastId: 'customId',
                    })
                }
            }
        }

        // return error
        return Promise.reject(error)
    }
)

export const get = (path: string) => {
    return customAxios.get(path)
}

export const post = (path: string, data: any, config: any) => {
    if (config) {
        return customAxios.post(path, data, config)
    }
    return customAxios.post(path, data, {
        headers: { 'Content-Type': 'Application/json' },
    })
}

export const put = (path: string, data: any, config: any) => {
    if (config) {
        return customAxios.put(path, data, config)
    }
    return customAxios.put(path, data, {
        headers: { 'Content-Type': 'Application/json' },
    })
}

export const patch = (path: string, data: any) => {
    return customAxios.patch(path, data)
}

export const del = (path: string, config: any) => {
    if (config) {
        return customAxios.delete(path, config)
    }
    return customAxios.delete(path)
}

export default customAxios
