import React from 'react'
import { Link } from 'react-router-dom'
import '../Footer/footer.scss'

const Footer = () => {
    return (
        <footer>
            <div className="footer">
                <div className="col">
                    <ul>
                        <li>
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/footer-logo.svg'
                                }
                            />
                            <p className="mt-25">
                                1000 – 10 Four Seasons Place
                                <br />
                                Etobicoke, Ontario, M9B 0A6
                            </p>
                            <ul className="social-icons">
                                <li>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/images/fb.svg'
                                        }
                                    />
                                </li>
                                <li>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/images/tw.svg'
                                        }
                                    />
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/pricelimbo/">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/images/insta.svg'
                                            }
                                        />
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="col">
                    <h5>Need Help?</h5>
                    <ul>
                        <li className="bottom-footer">
                            {/* <img
                                src={
                                    process.env.PUBLIC_URL + '/images/phone.svg'
                                }
                            /> */}
                            <div>
                                <p>CONTACT US</p>
                                {/* <h6>(647)490-5557</h6> */}
                            </div>
                        </li>
                        <li>Monday - Friday 8:30am-4:30pm EST</li>
                        <li>
                            <strong>
                                <a href="mailto:support@pricelimbo.com">
                                    support@pricelimbo.com
                                </a>
                            </strong>
                        </li>
                    </ul>
                </div>

                <div className="col">
                    <ul>
                        <li>
                            <strong>Information</strong>
                        </li>
                        <li>
                            <a href="/about">About Us</a>
                        </li>
                        <li>
                            <a href="/contact">Contact</a>
                        </li>
                        <li>
                            <a href="/privacy">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/terms">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer
