import customAxios from '../axios'
const API_URL = process.env.REACT_APP_API_URL

class AdService {
    adsService = () => {
        return customAxios.get(API_URL + 'getAds')
    }

    getAdsSlot() {
        return customAxios.get(API_URL + 'bannerSlots')
    }

    getAdsBySlot(slot: any) {
        return customAxios.post(API_URL + 'adBySlot', { slot })
    }

    getAdsListing(page: number) {
        return customAxios.get(
            API_URL + 'getAds/?page=' + page + '&pageSize=all'
        )
    }

    createAd(
        priceId: string,
        bannerSlot: string,
        bannerImage: any,
        sessionId: any
    ) {
        return customAxios.post(API_URL + 'createAd', {
            bannerSlot,
            status: 2,
            bannerImage,
            sessionId,
            priceId,
            product: 'Ad',
        })
    }

    updateAd(id: string, status: any) {
        return customAxios.put(API_URL + 'changeStatusAds', {
            id,
            status,
        })
    }

    adsPayment(id: any) {
        return customAxios.post(API_URL + 'adsPayment', {
            id,
        })
    }

    /* GetADS for Admin */
    getAdvertisementListforVendor() {
        return customAxios.get(API_URL + 'getAdsByVendor/')
    }
}

export default new AdService()
