import React, { useState } from 'react'
import MapPopup from '../../components/MapWithUserLocation/MapPopup'
import MapWithSearch from '../../components/LocationMap/Map'

interface HeaderIconsProps {
    items: Array<{
        icon: string
        label: string
        value: string
    }>
}

const HeaderIcons: React.FC<HeaderIconsProps> = ({ items }) => {
    const [show, setShow] = useState(false)

    return (
        <>
            {items.map((item, index) => (
                <li
                    className="location-bar"
                    key={index}
                    onClick={() => {
                        setShow((prev) => !prev)
                    }}
                >
                    <img
                        src={`/images/${item.icon}.svg`}
                        alt={`${item.label} Icon`}
                    />
                    <div className="head-location">
                        <p>{item.label}</p>
                        <h6>{item.value}</h6>
                    </div>
                </li>
            ))}

            <MapPopup text="Search Area Video" setShow={setShow} show={show} placement="header" />
        </>
    )
}

export default HeaderIcons
