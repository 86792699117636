import customAxios from '../axios'
import { error } from 'console'
import { END_POINTS } from '../domain'

const API_URL = `${process.env.REACT_APP_API_URL}`

class AuthService {
    login(username: string, password: string) {
        return customAxios
            .post(API_URL + 'login', {
                username: username,
                email: username,
                password,
            })
            .then((response: any) => {
                if (response?.response?.data?.error) {
                    throw new Error(response?.response?.data?.error)
                }
                if (
                    response.data.data.AuthenticationResult &&
                    response.data.data.AuthenticationResult.AccessToken
                ) {
                    localStorage.setItem(
                        'user',
                        JSON.stringify(response.data.data.AuthenticationResult)
                    )
                    localStorage.setItem(
                        'refreshToken',
                        JSON.stringify(
                            response.data.data.AuthenticationResult.RefreshToken
                        )
                    )
                    localStorage.setItem(
                        'userRole',
                        JSON.stringify(response.data.role)
                    )
                    localStorage.setItem('email', JSON.stringify(username))
                }
                return response.data
            })
    }

    logout() {
        localStorage.removeItem('user')
        localStorage.removeItem('email')
        localStorage.removeItem('userRole')
    }

    register(
        firstname: string,
        lastname: string,
        phone: string,
        email: string,
        password: string,
        userType: string
    ) {
        localStorage.setItem('email', email)
        return customAxios.post(API_URL + 'signup', {
            firstName: firstname,
            password,
            lastName: lastname,
            phoneNumber: phone,
            username: email,
            userType: userType === "vendor" ? 'vendor' : 'shopper',
        })
    }
    verifyOtp(otp: string) {
        const email = localStorage.getItem('email')
        return customAxios.post(API_URL + 'verifyOtp', {
            otp,
            username: email,
        })
    }

    resendOtp() {
        const email = localStorage.getItem('email')
        return customAxios.post(API_URL + 'resendOtp', {
            email,
        })
    }

    forgotPassword(email: string) {
        return customAxios.post(API_URL + 'forgotPassword', {
            username: email,
        })
    }

    confirmPassword(password: string, otp: string) {
        const email = localStorage.getItem('forgotEmail')
        return customAxios.post(API_URL + 'confirmPassword', {
            username: email,
            password,
            confirmationCode: otp,
        })
    }

    refreshToken() {
        const token = localStorage.getItem('refreshToken')
        if (token) {
            const tokenRefresh = JSON.parse(token)
            return customAxios.post(END_POINTS.refreshToken, {
                token: tokenRefresh,
                username: '806a2e04-5058-4218-888e-2f6c52646059',
            })
        }
    }
}

export default new AuthService()
