export const userHead = [
    {
      label: 'First Name',
      field: "first_name",
      sort: 'asc',
      width: 150
    },
    {
      label: 'Last Name',
      field: "last_name",
      sort: 'asc',
      width: 150
    },
    {
      label: 'Email',
      field: "email_id",
      sort: 'asc',
      width: 270
    },
    {
      label: 'Status',
      field: "status",
    },
    {
      label: "Action",
      field: "action",
      sort: 'disabled'
   }
  ]