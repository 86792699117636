import adsService from '../api/services/ads.service'

export const getBannerImage = async (slot: string) => {
    try {
        const result = await adsService.getAdsBySlot(slot)
        return result?.data?.ad?.bannerImage
    } catch (error) {
        console.log(error)
    }
}

export const getQueryString = (child: any, parentFieldIdArray: any) => {
    const queryString = `fieldId=${child}&parentFieldId=${parentFieldIdArray.join(
        '&parentFieldId='
    )}`
    return queryString
}

export const getFirst100Words = (paragraph: string, wordCount: number) => {
    // Split the paragraph into an array of words
    const words = paragraph?.split(/\s+/)

    // Extract the first 100 words
    const first100Words = words?.slice(0, wordCount)

    // Join the words back into a string
    const result = first100Words?.join(' ')

    return result
}

export const getFileExtensionRegex = (filename: any) => {
    return filename?.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2)
}

// Parse filters from query parameters
export const parseFiltersFromParams = (searchParams: any) => {
    const filters = JSON.parse(searchParams.get('filter'))

    return filters.map((filter: any) => JSON.parse(filter))
}

// Parse sort expression from query parameters
export const parseSortExpressionFromParams = (searchParams: any) => {
    const sortExpression = searchParams.get('sortExpression')
    return sortExpression ? JSON.parse(sortExpression) : null
}
export const filtersToParams = (filter: any) => {
    const searchParams = new URLSearchParams()
    filter.forEach((item: any) => {
        searchParams.append('filter', JSON.stringify(item))
    })

    // searchParams.append('sortExpression', JSON.stringify(sortExpression))

    return searchParams.toString()
}

export const getClassName = (status: string) => {
    if (status === '1') {
        return 'tableView__approved'
    }
    if (status === '0') {
        return 'tableView__rejected'
    }

    if (status === 'expired') {
        return 'tableView__expired'
    }
}

export const getIconClassName = (status: string) => {
    const statusMappings: any = {
        approved: 'approved',
        active: 'approved',
        rejected: 'rejected',
        inactive: 'rejected',
        expired: 'rejected',
        disabled: 'rejected',
        pending: 'pending',
    }

    return statusMappings[status] || ''
}

export const isValidPhoneNumber = (phone: any) => {
    const cleanedPhone = phone?.replace(/\D/g, '')
    return cleanedPhone?.length === 10
}

export const handlePhoneChange = (event: any, form: any) => {
    const input = event.target.value.replace(/\D/g, '')
    if (input.length <= 10) {
        if (isValidPhoneNumber(input)) {
            const formattedPhoneNumber = input.replace(
                /(\d{3})(\d{3})(\d{4})/,
                '($1) $2-$3'
            )
            form.setFieldValue('phoneNumber', formattedPhoneNumber)
        } else {
            form.setFieldValue('phoneNumber', input)
        }
    } else {
        event.preventDefault() // Prevent further input
    }
}
