import React, { useEffect, useState } from 'react'
import './map.css'

declare global {
    interface Window {
        google: any
        initAutocomplete: () => void
    }
}

const MapWithSearch: React.FC<any> = ({
    setLocationData,
    circleRadius,
    userData,
}) => {
    const [map, setMap] = useState<google.maps.Map | null>(null)
    const [circle, setCircle] = useState<google.maps.Circle | null>(null)
    const [marker, setMarker] = useState<google.maps.Marker | null>(null)
    const [selectedLocation, setSelectedLocation] = useState<{
        lat: number
        lng: number
    } | null>(null)
    const [loading, setLoading] = useState(true)

    const initializeMap = (center: { lat: number; lng: number }) => {
        const mapInstance = new window.google.maps.Map(
            document.getElementById('map') as HTMLElement,
            {
                center,
                zoom: 10,
                mapTypeId: 'roadmap',
            }
        )
        setMap(mapInstance)
        return mapInstance
    }

    const addCircleToMap = (
        mapInstance: google.maps.Map,
        center: { lat: number; lng: number }
    ) => {
        if (circle) {
            circle.setMap(null)
        }

        const newCircle = new window.google.maps.Circle({
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            map: mapInstance,
            center,
            radius: circleRadius * 20 ,
            clickable: false,
        })
        setCircle(newCircle)
    }

    const addMarkerToMap = (
        mapInstance: google.maps.Map,
        position: { lat: number; lng: number },
        title: string
    ) => {
        if (marker) {
            marker.setMap(null) // Remove the existing marker from the map
        }

        const newMarker = new window.google.maps.Marker({
            map: mapInstance,
            position,
            title,
        })
        setMarker(newMarker)
    }

    const updateLocationData = (lat: number, lng: number, name: string) => {
        console.log(name)
        setLocationData({
            lat: lat,
            lng: lng,
            name: name,
        })
    }

    const handlePlaceSelection = (
        places: google.maps.places.PlaceResult[],
        mapInstance: google.maps.Map
    ) => {
        if (places.length === 0) return

        const place = places[0] // Only consider the first place
        if (!place.geometry || !place.geometry.location) return

        const location = place.geometry.location
        const bounds = new window.google.maps.LatLngBounds()
        bounds.extend(location)
        // addMarkerToMap(
        //     mapInstance,
        //     { lat: location.lat(), lng: location.lng() },
        //     place.name || ''
        // )

        updateLocationData(location.lat(), location.lng(), place.name || '')
        setSelectedLocation({ lat: location.lat(), lng: location.lng() })

        // addCircleToMap(mapInstance, {
        //     lat: location.lat(),
        //     lng: location.lng(),
        // })

        mapInstance.fitBounds(bounds)
    }

    useEffect(() => {
        if (circle) {
            circle.setRadius(circleRadius * 20)
        }
    }, [circleRadius])

    useEffect(() => {
        if (map && selectedLocation) {
            const { lat, lng } = selectedLocation
            map.setCenter({ lat, lng })
            addMarkerToMap(map, { lat, lng }, 'Selected Location')
            addCircleToMap(map, { lat, lng })
        }
    }, [selectedLocation])

    const reCenter = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    }

                    if (map) {
                        map.setCenter(pos)
                        map.setZoom(10)
                        addCircleToMap(map, pos)
                        addMarkerToMap(map, pos, 'Current Location')
                        updateLocationData(pos.lat, pos.lng, 'Current Location')
                    }
                },
                (error) => {
                    console.error('Error getting current position:', error)
                }
            )
        } else {
            console.error('Geolocation is not supported by this browser.')
        }
    }

    useEffect(() => {
        const initAutocomplete = () => {
            const center = userData
                ? { lat: userData.latitude, lng: userData.longitude }
                : { lat: -33.8688, lng: 151.2195 }

            const mapInstance = initializeMap(center)

            if (navigator.geolocation && !userData) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        }
                        mapInstance.setCenter(pos)
                        mapInstance.setZoom(2)
                        addCircleToMap(mapInstance, pos)
                        addMarkerToMap(mapInstance, pos, 'Current Location')
                        updateLocationData(pos.lat, pos.lng, 'Current Location')
                        setLoading(false)
                    },
                    () => {
                        console.error('Error: The Geolocation service failed.')
                        setLoading(false)
                    }
                )
            } else {
                addCircleToMap(mapInstance, center)
                setLoading(false)
            }

            const input = document.getElementById(
                'pac-input'
            ) as HTMLInputElement
            const searchBox = new window.google.maps.places.SearchBox(input)
            mapInstance.controls[
                window.google.maps.ControlPosition.TOP_LEFT
            ].push(input)

            mapInstance.addListener('bounds_changed', () => {
                searchBox.setBounds(
                    mapInstance.getBounds() as google.maps.LatLngBounds
                )
            })

            searchBox.addListener('places_changed', () => {
                const places = searchBox.getPlaces()
                handlePlaceSelection(places, mapInstance)
            })

            mapInstance.addListener('click', (event: any) => {
                const clickedLocation = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                }
                const geocoder = new window.google.maps.Geocoder()
                geocoder.geocode(
                    { location: clickedLocation },
                    (results: any, status: any) => {
                        if (status === 'OK' && results[0]) {
                            const address = results[0].formatted_address
                            updateLocationData(
                                clickedLocation.lat,
                                clickedLocation.lng,
                                address
                            )
                            input.value = address || ''
                            setSelectedLocation(clickedLocation)
                        } else {
                            console.error('Geocoder failed due to: ' + status)
                        }
                    }
                )
            })
        }

        window.initAutocomplete = initAutocomplete

        const script = document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBcAWEbvtMj1hQopB08neXwUnhxq_LuEns&callback=initAutocomplete&libraries=places&loading=async&result_type=postal_code`
        script.async = true
        script.defer = true
        script.onerror = () => {
            console.error('Error loading Google Maps API script.')
            setLoading(false)
        }
        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return (
        <div>
            <div className="search-section">
                <input
                    id="pac-input"
                    className="controls"
                    type="text"
                    placeholder="Search Box"
                />
                <button
                    className="btn-orange"
                    onClick={reCenter}
                    disabled={loading}
                >
                    {loading ? 'Loading...' : 'Use my current location'}
                </button>
            </div>
            <div id="map" style={{ height: '400px', width: '100%' }}></div>
        </div>
    )
}

export default MapWithSearch
