import React from 'react'
import { Form } from 'react-bootstrap'
import ImagePicker from '../../../../../components/ImagePicker/ImagePicker'

const CategoryUpdateForm = ({
    setFormData,
    formData,
    formType,
    field,
}: any) => {
    const handleChange = (field: any, value: any) => {
        setFormData((prevData: any) => ({
            ...prevData,
            [field]: value,
        }))
    }

    const onImageUpload = (data: any) => {
        setFormData((prev: any) => ({
            ...prev,
            categoryImagePath: data[0],
        }))
    }

    const handleFileChange = (event: any) => {
        const file = event.target.files[0]

        if (file) {
            setFormData((prev: any) => ({
                ...prev,
                files: file,
            }))
        }
    }

    const getFormFields = (item: any, index: number) => {
        if (item.type === 'switch') {
            return item.field === 'status' ? (
                <Form.Check
                    key={index}
                    type="switch"
                    id={`custom-switch-${index}`}
                    label={item.label}
                    disabled={formType === 'append'}
                    checked={formData[item.field] === 'Active'}
                    onChange={() =>
                        handleChange(
                            item.field,
                            formData[item.field] === 'Active'
                                ? 'inActive'
                                : 'Active'
                        )
                    }
                />
            ) : (
                <Form.Check
                    key={index}
                    type="switch"
                    id={`custom-switch-${index}`}
                    label={item.label}
                    checked={formData[item.field]}
                    disabled={formType === 'append'}
                    onChange={() =>
                        handleChange(item.field, !formData[item.field])
                    }
                />
            )
        } else if (item.type === 'select') {
            return (
                <>
                    <Form.Label>{item.label}</Form.Label>
                    <Form.Select
                        disabled={formType === 'append'}
                        aria-label="Default select example"
                        onChange={(e) =>
                            handleChange(item.field, e.target.value)
                        }
                        value={formData[item.field]}
                    >
                        <option value="Product">Product</option>
                        <option value="Service">Service</option>
                    </Form.Select>
                </>
            )
        } else if (item.type === 'csvUpload') {
            return (
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>{item.label}</Form.Label>
                    <Form.Control
                        accept=".csv"
                        onChange={handleFileChange}
                        type="file"
                    />
                </Form.Group>
            )
        } else if (item.type === 'imgUpload') {
            return (
                <div key={index}>
                    <ImagePicker
                        onImageUpload={onImageUpload}
                        awsFolderName="limbotypes"
                        multiple={false}
                        // name="image"
                    />
                </div>
            )
        } else {
            return (
                <div key={index}>
                    <Form.Label>{item.label}</Form.Label>
                    <Form.Control
                        disabled={formType === 'append'}
                        type={item.type}
                        placeholder={`Enter ${item.label}`}
                        value={formData[item.field]}
                        onChange={(e) =>
                            handleChange(item.field, e.target.value)
                        }
                    />
                </div>
            )
        }
    }

    return (
        <div className="category__update">
            <Form>
                {field.map((item: any, index: number) => {
                    return getFormFields(item, index)
                })}
            </Form>
        </div>
    )
}

export default CategoryUpdateForm
