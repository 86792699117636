import React from 'react'
import Dashboard from '../pages/Dashboard/Dashboard'
import Profile from '../pages/Dashboard/User/Profile/Profile'
import UserListing from '../pages/Dashboard/Admin/UserListing/UserListing'
import LandinPage from '../pages/LandingPage/LandingPage'
import CreateAd from '../pages/Dashboard/User/Ads/CreateAd'
import AdsListing from '../pages/Dashboard/Admin/AdListing/AdListing'
import LimboForm from '../pages/Limbo/LimboForm'
import ListingCategory from '../pages/Listing/ListCategory'
import ListingForm from '../pages/Listing/ListingForm'
import PageNotFound from '../components/PageNotFound/PageNotFound'
import LimboDetailsPage from '../pages/LimboDetailsPage/LimboDetailsPage'
import Limbos from '../pages/Dashboard/User/Limbos/Limbos'
import ListingList from '../pages/Dashboard/Admin/ListingList/ListingList'
import Favourites from '../pages/Dashboard/User/Favorites/Favourites'
import Notification from '../pages/Notification/Notification'
import Ads from '../pages/Dashboard/User/Ads/Ads'
import ReportList from '../pages/Dashboard/Admin/ReportList/ReportList'
import PaymentSuccess from '../components/Success/PaymentSuccess'
import LimboList from '../pages/Dashboard/Admin/LimboList/LimboList'
import Listing from '../pages/Dashboard/User/Listing/Listing'
import ManageSubscription from '../pages/Dashboard/Admin/ManageSubscription/ManageSubscription'
import CategoryList from '../pages/Limbo/Category'
import CategoryListAdmin from '../pages/Dashboard/Admin/CategoryList/CategoryList'
import Subscriptions from '../pages/SubscriptionPlans/SubscriptionPlans'
import UserCategoryList from '../pages/Dashboard/Admin/UserCategories/UserCategoryList'
import PaymentCancel from '../components/Cancel/PaymentCancel'
import CSV from '../pages/Dashboard/Admin/CSV/CSV'
import VendorListing from '../pages/Dashboard/Admin/UserListing/VendorListing'
import MultiUserVendorListing from '../pages/Dashboard/MultiUserVendor/MultiUserVendorList'
import AddUserForm from '../pages/Dashboard/MultiUserVendor/Form/AddUserForm'
export const general_routes = [
    {
        path: '/',
        ele: <LandinPage />,
        availability: [
            'admin',
            'shopper',
            'vendor-service-provider',
            'vendor-sub-user',
        ],
    },
    {
        path: '*',
        ele: <PageNotFound />,
    },
    {
        path: '/start-limbo',
        ele: <CategoryList />,
        availability: ['shopper'],
    },
    {
        path: '/start-limbo/:id',
        ele: <LimboForm />,
        availability: ['shopper'],
    },
    {
        path: '/start-listing',
        ele: <ListingCategory />,
        availability: ['vendor-service-provider', 'vendor-sub-user'],
    },
    {
        path: '/start-listing/:id',
        ele: <ListingForm />,
        availability: ['vendor-service-provider', 'vendor-sub-user'],
    },
    {
        path: 'subscription',
        ele: <Subscriptions />,
        // availability: ['vendor-service-provider'],
    },
    {
        path: '/add-sub-vendor',
        ele: <AddUserForm />,
        availability: ['vendor-service-provider', 'vendor-sub-user'],
    },

    {
        path: '/dashboard',
        ele: <Dashboard />,
        availability: [
            'admin',
            'shopper',
            'vendor-service-provider',
            'vendor-sub-user',
        ],
        child_routes: [
            {
                path: 'report-list',
                ele: <ReportList />,
                availability: ['admin'],
            },
            {
                path: 'user-categories',
                ele: <UserCategoryList />,
                availability: ['admin'],
            },
            {
                path: 'shopper-list',
                ele: <UserListing />,
                availability: ['admin'],
            },
            {
                path: 'vendor-list',
                ele: <VendorListing />,
                availability: ['admin'],
            },
            {
                path: 'ad-list',
                ele: <AdsListing />,
                availability: ['admin'],
            },
            {
                path: 'limbo-list',
                ele: <LimboList />,
                availability: ['admin'],
            },
            {
                path: 'listing-list',
                ele: <ListingList />,
                availability: ['admin'],
            },
            {
                path: 'category-list',
                ele: <CategoryListAdmin />,
                availability: ['admin'],
            },
            {
                path: 'manageSubscription',
                ele: <ManageSubscription />,
                availability: ['admin'],
            },

            {
                path: 'ads',
                ele: <Ads />,
                availability: ['vendor-service-provider', 'vendor-sub-user'],
            },
            {
                path: 'sub-vendor-list',
                ele: <MultiUserVendorListing />,
                availability: ['vendor-service-provider', 'vendor-sub-user'],
            },

            {
                path: 'createad',
                ele: <CreateAd />,
                availability: ['vendor-service-provider', 'vendor-sub-user'],
            },
            {
                path: 'listings',
                ele: <Listing />,
                availability: ['vendor-service-provider', 'vendor-sub-user'],
            },
            {
                path: 'limbos',
                ele: <Limbos />,
                availability: [
                    'vendor-service-provider',
                    'shopper',
                    'vendor-sub-user',
                ],
            },
            {
                path: 'favourites',
                ele: <Favourites />,
                availability: [
                    'vendor-service-provider',
                    'shopper',
                    'vendor-sub-user',
                ],
            },
            {
                path: 'profile',
                ele: <Profile />,
                availability: [
                    'shopper',
                    'vendor-service-provider',
                    'vendor-sub-user',
                ],
            },
            {
                path: 'upload-csv',
                ele: <CSV />,
                availability: ['admin'],
            },
        ],
    },
    {
        path: '/limbo-detail/:id',
        ele: <LimboDetailsPage />,
        availability: [
            'shopper',
            'vendor-service-provider',
            'admin',
            'vendor-sub-user',
        ],
    },
    {
        path: '/notification',
        ele: <Notification />,
        availability: [
            'shopper',
            'vendor-service-provider',
            'admin',
            'vendor-sub-user',
        ],
    },
    {
        path: 'paymentSuccess',
        ele: <PaymentSuccess />,
    },
    {
        path: 'paymentCancel',
        ele: <PaymentCancel />,
    },
]
