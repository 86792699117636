export const buttonGroup = [
    {
        type: 'submit',
        label: 'Save',
        id: 'start',
        disabled: 'true',
    },
    // {
    //     type: 'button',
    //     label: 'Draft',
    //     id: 'draft',
    //     disabled: 'true',
    // },
]

export const commonFormFields = [
    {
        label: 'Additional Information',
        name: 'description',
        type: 'textarea',
        id: 'description',
        placeholder: 'Enter Additional Information',
    },
    {
        label: 'Price',
        name: 'price',
        type: 'text',
        id: 'price',
        placeholder: 'Enter Price',
    },

    {
        // label: 'Upload Image',
        name: 'image',
        type: 'image',
        placeholder: 'Upload Image',
        id: 'file',
    },
    {
        label: 'Address',
        name: 'address',
        type: 'text',
        placeholder: 'Enter Address',
        id: 'address',
    },
    {
        label: 'City',
        name: 'city',
        type: 'text',
        placeholder: 'Enter City',
        id: 'city',
    },
    {
        label: 'Postal code',
        name: 'postalCode',
        type: 'text',
        placeholder: 'Enter Postal code',
        id: 'postalCode',
    },
]

export const payload = {
    description: 'Limbo description 7',
    listingTypeId: 7,
    listingName: 'Appliance 7',
    listingType: 'Product 7',
    location: 'location text 7',
    city: 'city text 7',
    address: 'address text 7',
    postalCode: '12347',
    createdBy: 1001,
    listingDetail: [
        {
            listingFieldId: 24,
            listingFieldName: 'Appliance Category 7',
            fieldValueId: 327,
            fieldValue: 'Large/Major Appliance 7',
            displayOrder: 1,
            fieldDataType: 'Text 7',
        },
        {
            listingFieldId: 27,
            listingFieldName: 'Brand 7',
            fieldValueId: 392,
            fieldValue: 'Bluestar 7',
            displayOrder: 2,
            fieldDataType: 'Text 7',
        },
        {
            listingFieldId: 26,
            listingFieldName: 'Appliance Type 7',
            fieldValueId: 397,
            fieldValue: 'Broilers 7',
            displayOrder: 3,
            fieldDataType: 'Text 7',
        },
        {
            listingFieldId: 27,
            listingFieldName: 'Model / Item # 7',
            fieldValueId: null,
            fieldValue: 'Broilers',
            displayOrder: 4,
            fieldDataType: 'Text',
        },
        {
            listingFieldId: 28,
            listingFieldName: 'Delivery Required 7',
            fieldValueId: null,
            fieldValue: 'Yes',
            displayOrder: 7,
            fieldDataType: 'YesNo',
        },
        {
            listingFieldId: 29,
            listingFieldName: 'Old Appliance Take Away 7',
            fieldValueId: null,
            fieldValue: 'No',
            displayOrder: 6,
            fieldDataType: 'YesNo',
        },
    ],
    images: ['image1', 'image2', 'image3'],
}

export const initialValues: any = {
    listingTypeId: '',
    listingName: '',
    listingType: '',
    description: '',
    address: '',
    city: '',
    postalCode: '',
    createdBy: '',
    price: '',
    listingDetail: [
        {
            listingFieldId: 0,
            listingFieldName: '',
            fieldValueId: 0,
            fieldValue: '',
            displayOrder: 0,
            fieldDataType: '',
        },
    ],
    images: [],
}
