import React, { useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import '../../ForgotPassword/forgotPassword.scss'
import { changePassword } from '../../../api/services/multi-user-vendor'
import { useNavigate } from 'react-router-dom'

// Define validation schema outside of the component to prevent unnecessary re-renders
const validationSchema = Yup.object().shape({
    username: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    newPassword: Yup.string()
        .required('Please Enter your password')
        .min(8, 'Must be 8 characters or more')
        .matches(/[a-z]+/, 'One lowercase character')
        .matches(/[A-Z]+/, 'One uppercase character')
        .matches(/[@$!%*#?&]+/, 'One special character')
        .matches(/\d+/, 'One number'),
})

const ChangePassword = () => {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [successful, setSuccessful] = useState(false)
    const navigate = useNavigate()

    const handleChangePassword = (formValue: any) => {
        const session = localStorage.getItem('session')
        const payload = { ...formValue, session: session }
        changePassword(payload)
            .then((response: any) => {
                // Assuming response contains a success flag or message
                setMessage(response?.message)
                setSuccessful(response?.success) // Update the successful state based on response
                navigate('/login')
            })
            .catch((error) => {
                // Handle error
                setMessage('An error occurred while changing password.')
                setSuccessful(false)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const initialValues = {
        username: '',
        newPassword: '',
    }

    return (
        <div className="forgotPage">
            <div className="forgotWrap">
                <div className="forgotLeft">
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            '/images/restpasswordImg.png'
                        }
                        alt="restpasswordImg"
                    />
                </div>
                <div className="forgotRight">
                    <h3>Change Your Password</h3>
                    <p>Please change password.</p>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleChangePassword}
                    >
                        <Form>
                            <div className="form-group">
                                <label htmlFor="username">Email</label>
                                <Field
                                    name="username"
                                    type="email"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="username"
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="newPassword">
                                    Confirm Password
                                </label>
                                <Field
                                    name="newPassword"
                                    type="password"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="newPassword"
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>

                            <div className="form-group">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                    disabled={loading}
                                >
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>Change Password</span>
                                </button>
                            </div>

                            {message && (
                                <div className="form-group">
                                    <div
                                        className={`alert ${
                                            successful
                                                ? 'alert-success'
                                                : 'alert-danger'
                                        }`}
                                        role="alert"
                                    >
                                        {message}
                                    </div>
                                </div>
                            )}
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword
