import React from 'react'
import { navItems } from './constant'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import './navbar.scss'
import DashboardIcon from '../../assets/svg/dashboard_icon.svg'
import Logout from '../../assets/svg/logout.svg'
import PlusIcon from '../../assets/svg/plus-icon.svg'
import SignIn from '../../assets/svg/login.svg'
import CreateAccount from '../../assets/svg/create-account.svg'
import MyAccountIcon from '../../assets/svg/my-account.svg'
import { useDispatch } from 'react-redux'
import { removeNotification } from '../../redux/notificationSlice'

const NavigationBar = () => {
    const navigate = useNavigate()
    const userRole = localStorage.getItem('userRole')
    const dispatch = useDispatch()
    const logout = () => {
        localStorage.removeItem('user')
        localStorage.removeItem('userRole')
        dispatch(removeNotification())
        navigate('/', { replace: true })
    }
    return (
        <Navbar>
            <div className="container">
                <Nav>
                    {navItems.map((item) => (
                        <NavLink key={item.id} to={item?.to}>
                            {item.MenuItem}
                        </NavLink>
                    ))}
                </Nav>
                <div className="topbar">
                    <div className="top-links">
                        <ul>
                            {userRole && (
                                <>
                                    {userRole == `"admin"` ? (
                                        <li>
                                            <Link
                                                to={'/dashboard/shopper-list'}
                                                className="nav-link"
                                            >
                                                <img
                                                    src={MyAccountIcon}
                                                    alt="icon"
                                                />
                                                <span>Dashboard</span>
                                            </Link>
                                        </li>
                                    ) : (
                                        <li>
                                            <Link
                                                to={'/dashboard/profile'}
                                                className="nav-link"
                                            >
                                                <img
                                                    src={DashboardIcon}
                                                    alt="icon"
                                                />
                                                <span>Dashboard </span>
                                            </Link>
                                        </li>
                                    )}
                                </>
                            )}

                            {userRole == `"shopper"` && (
                                <li className="btn-createlimbo">
                                    <button
                                        onClick={() => navigate('/start-limbo')}
                                    >
                                        <img src={PlusIcon} />
                                        <span>Start Limbo</span>
                                    </button>
                                </li>
                            )}
                            {userRole ? (
                                <>
                                    <li>
                                        <a
                                            onClick={logout}
                                            className="nav-link"
                                        >
                                            <img src={Logout} alt="icon" />
                                            <span>Logout </span>
                                        </a>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li>
                                        <Link
                                            to={'/login'}
                                            className="nav-link"
                                        >
                                            <img src={SignIn} alt="icon" />
                                            <span>Sign In</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            to={'/register'}
                                            className="nav-link"
                                        >
                                            <img
                                                src={CreateAccount}
                                                alt="icon"
                                            />
                                            <span>Create an Account</span>
                                        </Link>
                                    </li>
                                </>
                            )}
                        </ul>
                        {(userRole === '"vendor-service-provider"' ||
                            userRole === '"vendor-sub-user"') && (
                            <ul className="m-show">
                                <li>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/images/create_new_account.svg'
                                        }
                                        alt="Logo"
                                    />
                                </li>
                                <li>
                                    <button className="btn-orange">
                                        + Start Limbo
                                    </button>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </Navbar>
    )
}

export default NavigationBar
