import React from 'react'
import './shopBycategory.scss'
import DashboardOwlCarousel from '../DashboardOwlCarousel/DashBoardOwlCarousel'
import NoDataFound from '../NodataFound/NoDataFound'
import { Button } from 'react-bootstrap'
import Loader from '../Loader/Loader'

const ShopByCategory = ({ data, handleFilter, loading }: any) => {
    if (loading) {
        return <Loader/>
    }
    return (
        <div className="dashboard-shop-Category-section">
            <div className="container">
                <div className="row">
                    <div className="owl-carousel-section">
                        {data && data?.length > 0 ? (
                            <DashboardOwlCarousel data={data} />
                        ) : (
                            <NoDataFound />
                        )}
                    </div>

                    <div className="limbo-heading">
                        <h4>Latest</h4>
                        <div className="limbo-links">
                            <Button onClick={() => handleFilter('All')}>
                                All
                            </Button>
                            <Button onClick={() => handleFilter('Service')}>
                                Services
                            </Button>
                            <Button onClick={() => handleFilter('Product')}>
                                Product
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShopByCategory
