import { LimboFormData } from './limbo.types'

export const buttonGroup = [
    {
        type: 'submit',
        label: 'Save',
        id: 'start',
    },
]

export const commonFormFields = [
    {
        label: 'Additional Information',
        name: 'description',
        type: 'textarea',
        id: 'description',
        placeholder:
            'Provide any additional information that will assist the vendor / service provider in providing an accurate price',
        required: true,
    },
    {
        label: 'Limbo End Time',
        name: 'End Time',
        type: 'date',
        placeholder: 'endAt',
        id: 'endAt',
        required: true,
    },
    {
        label: 'Price to Beat (If available)',
        name: 'price',
        type: 'text',
        placeholder: 'Enter Price',
        id: 'price',
        required: false,
    },
    {
        label: 'Add any photos that will help with receiving an accurate price',
        name: 'image',
        type: 'image',
        placeholder: 'Upload',
        id: 'file',
        required: true,
    },

    {
        label: 'Please provide any URL of the specific item you need (if available) ',
        name: 'image_url',
        type: 'text',
        id: 'image_url',
        placeholder: 'Image URL',
        required: false,
        hidden: false,
    },
    {
        label: 'City',
        name: 'city',
        type: 'text',
        placeholder: 'City',
        id: 'city',
        required: true,
        hidden: true,
    },
    {
        label: 'Postal code',
        name: 'postalCode',
        type: 'text',
        placeholder: 'Postal code',
        id: 'postalCode',
        required: true,
        hidden: true,
    },
    // {
    //     label: 'Selected Loaction',
    //     name: 'location',
    //     type: 'text',
    //     placeholder: 'Select Location',
    //     id: 'location',
    // },
    // {
    //     label: 'Notify All Vendors',
    //     name: 'notify',
    //     type: 'checkbox',
    //     placeholder: 'Notify All Vendors',
    //     id: 'notify',
    // },
]

export const initialValues: LimboFormData = {
    limboTypeId: '',
    limboName: '',
    limboType: '',
    description: '',
    endAt: '',
    price: '',
    image: '',
    location: '',
    city: '',
    postalCode: '',
    createdBy: '',
}

// limboDetail: [
//     {
//         limboFieldId: '',
//         limboFieldName: '',
//         fieldValueId: '',
//         fieldValue: '',
//         displayOrder: '',
//         fieldDataType: '',
//     },
// ],
