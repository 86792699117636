import customAxios from '../axios'
import { END_POINTS } from '../domain'

export const getCategory = (source: any) => {
    if (source) {
        return customAxios.get(`${END_POINTS.getCategory}?source=${source}`)
    } else {
        return customAxios.get(`${END_POINTS.getCategory}`)
    }
}
export const getCategories = (type: any) => {
    const payload = { params: { categoryType: type } };
    return customAxios.get(`${END_POINTS.categories}`, payload)
}

export const getServices = () => {
    return customAxios.get(`${END_POINTS.getCategory}?limboType=Service`)
}

export const getProduct = () => {
    return customAxios.get(`${END_POINTS.getCategory}?limboType=Product`)
}

export const getCategoryById = (id: any) => {
    return customAxios.get(`${END_POINTS.getCategory}/${id}`)
}

export const getFormFieldValues = (fieldId: any, limboId: any) => {
    const queryString =
        fieldId === 0
            ? `limboFieldId=${limboId}`
            : `parentFieldId=${fieldId}&limboFieldId=${limboId}`

    return customAxios.get(`${END_POINTS.getFormFieldValues}?${queryString}`)
}

export const updateCategory = (id: any, payload: any) => {
    return customAxios.put(`${END_POINTS.categories}/${id}`, payload)
}

export const addCategory = (formData: any) => {
    const headers = {
        'Content-Type': 'multipart/form-data',
    }

    return customAxios.post(`${END_POINTS.categoryAdd}`, formData, { headers })
}

export const appendCsvCategory = (id: any, formData: any) => {
    const headers = {
        'Content-Type': 'multipart/form-data',
    }

    return customAxios.post(`${END_POINTS.categoryAppend}/${id}`, formData, {
        headers,
    })
}

export const updateCsvCategory = (id: any, formData: any) => {
    const headers = {
        'Content-Type': 'multipart/form-data',
    }

    return customAxios.post(`${END_POINTS.categoryUpdate}/${id}`, formData, {
        headers,
    })
}
