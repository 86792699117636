import React, { useMemo, useState } from 'react'
import sortIcon from '../../assets/images/sort-icon.svg'
import './sortby.scss'
import Form from 'react-bootstrap/Form'
import { Pagination } from 'react-bootstrap'

export const CustomPagination = ({ data, handlePageSize }: any) => {
    return (
        <Pagination>
            <Pagination.First onClick={() => handlePageSize({ page: 1 })} />
            <Pagination.Prev
                onClick={() => handlePageSize({ page: data.currentPage - 1 })}
                disabled={data.currentPage === 1}
            />
            {[...Array(data.totalPages)].map((_, index) => (
                <Pagination.Item
                    key={index}
                    active={index + 1 === data.currentPage}
                    onClick={() => handlePageSize({ page: index + 1 })}
                >
                    {index + 1}
                </Pagination.Item>
            ))}
            <Pagination.Next
                onClick={() => handlePageSize({ page: data.currentPage + 1 })}
                disabled={data.currentPage === data.totalPages}
            />
            <Pagination.Last
                onClick={() => handlePageSize({ page: data.totalPages })}
            />
        </Pagination>
    )
}

type SortByProps = {
    sortOptions: []
    applySort: (arg: {}) => void
    handlePagination: (arg: {}) => void
    pagination: any
}

const SortBy = ({
    sortOptions,
    applySort,
    pagination,
    handlePagination,
}: SortByProps) => {
    const [sortDirections, setSortDirections] = useState<any>({})

    const hanldeSort = (selectedValue: any) => {
        const newSortDirections = { ...sortDirections }
        newSortDirections[selectedValue] =
            newSortDirections[selectedValue] === 'ascending'
                ? 'descending'
                : 'ascending'
        setSortDirections(newSortDirections)

        applySort({
            sortKey: selectedValue,
            sortDirection: newSortDirections[selectedValue],
        })
    }

    const handlePageSize = ({ page }: any) => {
        const selectElement = document.getElementById(
            'listing-page-select'
        ) as HTMLSelectElement
        const selectedValue = selectElement?.value
        // Perform any other actions based on the selected option
        handlePagination({
            pageSize: parseInt(selectedValue),
            page: parseInt(page) || 1,
        })
    }

    const sortedOptions = useMemo(() => {
        return sortOptions.map((option: any) => (
            <option key={option.key} value={option.key}>
                {option.value}{' '}
                <span style={{ cursor: 'pointer' }}>
                    {sortDirections[option.key] === 'ascending' ? '↑' : '↓'}
                </span>
            </option>
        ))
    }, [sortOptions, sortDirections])

    return (
        <div className="sort-grid-section">
            <div className="sortby">
                <ul>
                    <li>
                        <img src={sortIcon} alt="" /> Sort by
                    </li>
                    <li>
                        <Form.Select
                            id="listing-sort-select"
                            aria-label="Default select example"
                            onChange={(event) => hanldeSort(event.target.value)}
                        >
                            {sortedOptions}
                        </Form.Select>
                    </li>
                </ul>
            </div>
            <div className="sortby m-hide">
                <ul>
                    
                    <li>
                        Show
                        <Form.Select
                            id="listing-page-select"
                            aria-label="Default select example"
                            onChange={handlePageSize}
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                        </Form.Select>
                    </li>
                </ul>
            </div>
            <CustomPagination
                data={pagination}
                handlePageSize={handlePageSize}
            />
        </div>
    )
}

export default SortBy
