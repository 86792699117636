import React from 'react'
import { Carousel } from 'react-bootstrap'
import './DetailsPageSlider.scss'
import 'bootstrap/dist/css/bootstrap.min.css'

const DetailsPageSlider = ({ data }: any) => {
    return (
        <div
            className="limbo-slider detail-page-slider"
        >
            <Carousel>
                {data?.map((item: any) => {
                    return (
                        <Carousel.Item key={item?.id}>
                            <img
                                src={item}
                                alt="banner image"
                                className="img-fluid"
                            />
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        </div>
    )
}

export default DetailsPageSlider
