import React, { useState, useEffect } from 'react'
import { errorImg } from '../../helper/iconPath'

type ErrorBoundaryProps = {
    children: React.ReactNode
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
    const [hasError, setHasError] = useState<boolean>(false)

    useEffect(() => {
        const errorHandler: EventListener = (event) => {
            setHasError(true)
            console.error(event)
        }

        window.addEventListener('error', errorHandler)

        return () => {
            window.removeEventListener('error', errorHandler)
        }
    }, [])

    if (hasError) {
        return <div className='errorBoundry'>
            <img src={errorImg} alt="error" className='errorBoundry__img' />
            <div className='errorBoundry__text'>
                <span>Something</span>
                <span>went</span>
                <span>wrong ⚠️</span>
            </div>
        </div>
    }

    return <>{children}</>
}

export default ErrorBoundary
