import React, { useState } from 'react'

const LocationSlider = ({ handleOnChange, field }: any) => {
    const { name, id, label, type } = field
    const [location, setLocation] = useState(10)

    const handleLocationChange = (e: any) => {
        setLocation(e.target.value)
        handleOnChange(name, e.target.value)
    }

    return (
        <div className="form-fields">
            <label htmlFor={name}>{label}</label>
            <p>Select address range</p>
            <input
                type="range"
                id={id}
                name="location"
                min="0km"
                max="100km"
                value={location}
                onChange={handleLocationChange}
            />

            {location ? (
                <p> Selected range: {location} km</p>
            ) : (
                <p>Please select range</p>
            )}
        </div>
    )
}

export default LocationSlider
