import React from 'react'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { FaRegEdit } from 'react-icons/fa'
import { FaFileMedical } from 'react-icons/fa6'
import { MdEditNote } from 'react-icons/md';

interface CsvActionProps {
    handleEdit: any;
    handleAppend: any;
    row: any;
}
const CsvAction: React.FC<CsvActionProps> = ({ handleEdit, handleAppend, row }) => {
    return (
        <div>
            <OverlayTrigger
                trigger="click"
                key="top"
                placement="top"
                overlay={
                    <Popover className="popover__actions"
                        id={`popover-positioned-top`}>
                        <Popover.Body>
                            <div className='' onClick={() => handleEdit(row)}>
                                <FaRegEdit  />
                                <span>
                                    Edit 
                                </span>
                            </div>
                            <div className='' onClick={() => handleAppend(row)}>
                                <FaFileMedical title='append file'  />
                                <span>
                                    Append CSV
                                </span>
                            </div>
                        </Popover.Body>
                    </Popover>
                }
            >
                <Button className=''>
                    <MdEditNote />
                </Button>
            </OverlayTrigger>
        </div>
    )
}

export default CsvAction
