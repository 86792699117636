import React, { useEffect, useState } from 'react'
import CategoryFilter from '../../components/Filter/CategoryFilter'
import LimboListItem from '../../components/LimboListItem/LimboListItem'
import DetailsPageSlider from '../../components/DetailsPageSlider/DetailsPageSlider'
import Ads from '../../components/Ads/Ads'
import { useParams, useSearchParams } from 'react-router-dom'
import {
    getFilterFieldValues,
    getLimbosByCategory,
} from '../../api/services/listing.service'
import NoDataFound from '../../components/NodataFound/NoDataFound'
import Loader from '../../components/Loader/Loader'
import {
    addFavourite,
    removeFavourite,
} from '../../api/services/favourites.service'
import './categorylisting.scss'
import SortBy from '../../components/SortBy/SortBy'
import { getBannerImage, getQueryString } from '../../helper/utils'
import noImg from '../../assets/images/no-image.png'
import { toast } from 'react-toastify'
import { LuFilterX } from 'react-icons/lu'

const ListingCategoryList = () => {
    const { id } = useParams()
    const [list, setList] = useState<any>([])
    const [filterOptions, setFilterOptions] = useState<any>([])
    const [sortOptions, setSortOptions] = useState<any>([])
    const [pagination, setPagination] = useState<any>({
        totalPages: '',
        currentPage: 1,
        pageSize: 5,
    })
    const [loading, setLoading] = useState(true)
    const [filter, setFilters] = useState<any>([])
    const [filterParams, setFilterParams] = useState<any>([])
    const [isFiltered, setIsFiltered] = useState(false)
    const [parentFieldId, setParentFieldId] = useState<any>([])
    const [, setHomeBanner] = useState<any>()
    const [sortExpression, setSortExpression] = useState({
        sortKey: 'listingName',
        sortDirection: 'ascending',
    })
    const dataString = localStorage.getItem('email')
    const email = JSON.parse(dataString || '')

    const [searchParams, setSearchParams] = useSearchParams() // useSearchParams hook to access query parameters

    const fetchListingData = async (payload: any, type: any) => {
        setLoading(true)
        getLimbosByCategory(payload)
            .then(
                (response: any) => {
                    const result = response?.data?.data
                    setList(result)
                    type !== 'apply' && setFilterOptions(response?.data?.filter)
                    setSortOptions(response?.data?.sort)
                    setPagination({
                        totalPages: response?.data?.pager?.totalPages,
                        currentPage: response?.data?.pager?.currentPage,
                        pageSize: response?.data?.pager?.pageSize,
                    })
                    setLoading(false)
                }
                // (error) => {
                //     const resMessage =
                //         (error.response &&
                //             error.response.data &&
                //             error.response.data.error) ||
                //         error.message ||
                //         error.toString()
                // }
            )
            .catch((error: any) => {
                console.log(error)
                setLoading(false)
            })
    }

    const handleFilters = (
        isCheckboxChecked: any,
        limboFieldId: any,
        limboFieldName: any,
        item: any
    ) => {
        if (!item?.fieldValueId) {
            // Handle the case where fieldValueId is undefined or null
            return
        }

        if (isCheckboxChecked) {
            //check if item alreday exits
            const itemExists = filter.some(
                (item: any) => item.limboFieldId === limboFieldId
            )

            if (!itemExists) {
                setFilters((prev: any) => [
                    ...prev,
                    {
                        limboFieldId: limboFieldId,
                        limboFieldName: limboFieldName,
                        filterValue: [item],
                    },
                ])
            } else {
                // If the item with the same id already exists, update its values
                setFilters((prev: any) =>
                    prev.map((nitem: any) =>
                        nitem.limboFieldId === limboFieldId
                            ? {
                                  ...nitem,
                                  filterValue: [...nitem.filterValue, item],
                              }
                            : nitem
                    )
                )
            }
        } else {
            // Remove data when isChecked is false
            setFilters((prev: any) =>
                prev
                    .map((nitem: any) =>
                        nitem.limboFieldId === limboFieldId
                            ? {
                                  ...nitem,
                                  filterValue: nitem.filterValue.filter(
                                      (value: any) =>
                                          value.fieldValueId !==
                                          item.fieldValueId
                                  ),
                              }
                            : nitem
                    )
                    .filter((kitem: any) => kitem.filterValue.length > 0)
            )
        }
    }

    const handleCheckBox = (
        item: any,
        childLimboFieldId: any,
        limboFieldId: any,
        limboFieldName: any,
        isChecked: any
    ) => {
        const { isCheckboxChecked, name } = isChecked

        let updatedParentFieldId: any[] = []

        if (isCheckboxChecked) {
            // Add item?.fieldValueId if it's not already present
            if (!parentFieldId.includes(item?.fieldValueId)) {
                updatedParentFieldId = [...parentFieldId, item?.fieldValueId]
                setParentFieldId(updatedParentFieldId)
            }
        } else {
            // Remove item?.fieldValueId if it exists
            updatedParentFieldId = parentFieldId.filter(
                (fieldId: any) => fieldId !== item?.fieldValueId
            )
            setParentFieldId(updatedParentFieldId)
        }

        // Call handleFilters
        handleFilters(isCheckboxChecked, limboFieldId, limboFieldName, item)

        // Generate query string and fetch filter field values
        const uniqueParentFieldId = new Set(updatedParentFieldId)
        // updateURL()

        getFilterFieldValues(
            getQueryString(childLimboFieldId, Array.from(uniqueParentFieldId))
        )
            .then((response) => {
                setFilterOptions((prev: any) => {
                    return prev.map((filter: any) => {
                        return filter?.limboFieldId === childLimboFieldId
                            ? {
                                  ...filter,
                                  values: response?.data,
                              }
                            : filter
                    })
                })

                //  isLoading(false)
            })
            .catch((error: any) => {
                console.error('Error fetching filter field values:', error)
                // Handle the error, e.g., display an error message to the user
            })
    }

    const applyFilters = () => {
        const payload = {
            limboTypeId: id,
            page: pagination.currentPage,
            pageSize: pagination.pageSize,
            filterApplied: filter,
            sortExpression: sortExpression,
        }

        setIsFiltered(true)
        fetchListingData(payload, 'apply')
        setIsFiltered(false)

        setQueryParams()
        localStorage.setItem('filter', JSON.stringify(filterOptions))
    }

    const addToFavourite = (listingId: string) => {
        const payload = {
            userid: email,
            listingid: listingId,
        }
        addFavourite(payload)
            .then((response) => {
                if (response?.status === 200) {
                    const listingPayload = {
                        limboTypeId: id,
                        page: pagination.currentPage,
                        pageSize: pagination.pageSize,
                        filterApplied: filterParams ? filterParams : filter,
                        sortExpression: sortExpression,
                    }
                    fetchListingData(listingPayload, 'apply')
                }
            })
            .catch((error) =>
                toast(`${error}`, {
                    type: 'warning',
                    autoClose: 2000,
                    toastId: 'customId',
                })
            )
    }

    const applySort = (expression: any) => {
        setSortExpression(expression)
        const payload = {
            limboTypeId: id,
            page: pagination.currentPage,
            pageSize: pagination.pageSize,
            filterApplied: filterParams ? filterParams : filter,
            sortExpression: expression,
        }

        fetchListingData(payload, 'apply')
    }

    const handlePagination = (data: any) => {
        const { pageSize, page = 1 } = data

        const payload = {
            limboTypeId: id,
            page: page,
            pageSize: pageSize,
            filterApplied: filterParams ? filterParams : filter,
            sortExpression: sortExpression,
        }

        fetchListingData(payload, 'apply')
    }

    const alreadyChecked = (item: any) => {
        const foundItem = filter.find((filterItem: any) => {
            return filterItem.filterValue.find(
                (i: any) => i.fieldValue === item
            )
        })

        return !!foundItem // Return true if item is found, false otherwise
    }

    // Update the URL with the current filter and sort state
    const setQueryParams = () => {
        setSearchParams({ filter: JSON.stringify(filter) })
    }

    const clearFilters = () => {
        // setParentFieldId([])
        setFilters([])
        setFilterParams([])
        setSearchParams([])

        // Remove the filter parameter from the URL if filterParams is empty
        if (searchParams.get('filter')) {
            const newSearchParams = new URLSearchParams(searchParams)
            newSearchParams.delete('filter')
            setSearchParams(newSearchParams.toString())
        }

        localStorage.removeItem('filter')
    }

    useEffect(() => {
        // Retrieve and set filters and sortExpression from query parameters
        if (searchParams) {
            const params = searchParams.get('filter')
            if (params) {
                const filtersFromParams = JSON.parse(params)
                setFilterParams(filtersFromParams)
                setFilters(filtersFromParams) // Update local state with filters from params
            }
        }
    }, [searchParams])

    useEffect(() => {
        const HomeTopBannerImage = getBannerImage('ListingPageBanner')
        setHomeBanner(HomeTopBannerImage)
        // Retrieve filter options from local storage
        const localStorageFilterOptions = localStorage.getItem('filter')
        if (localStorageFilterOptions) {
            const parsedData = JSON.parse(localStorageFilterOptions)
            setFilterOptions(parsedData)
        }
    }, [])

    useEffect(() => {
        const localStorageFilterOptions = localStorage.getItem('filter')

        if (!localStorageFilterOptions) {
            const payload = {
                limboTypeId: id,
                page: pagination.currentPage,
                pageSize: pagination.pageSize,
                filterApplied: filterParams ? filterParams : filter,
                sortExpression: sortExpression,
            }
            fetchListingData(payload, 'first')
        } else {
            const payload = {
                limboTypeId: id,
                page: pagination.currentPage,
                pageSize: pagination.pageSize,
                filterApplied: filterParams ? filterParams : filter,
                sortExpression: sortExpression,
            }
            fetchListingData(payload, 'apply')
        }
        const HomeTopBannerImage = getBannerImage('ListingPageBanner')
        setHomeBanner(HomeTopBannerImage)
    }, [filterParams])

    useEffect(() => {
        // Remove filter from local storage when navigating away
        const removeFilterOnNavigate = () => {
            localStorage.removeItem('filter')
        }

        // Cleanup function
        return () => {
            removeFilterOnNavigate()
        }
    }, [])

    const removeFromFavourite = (id: any) => {
        removeFavourite({ userid: email, listingid: id })
            .then((response: any) => {
                console.log(response.status)
                location.reload()
            })
            .catch((error) => console.log(error))
    }

    return (
        <div className="listingwrap">
            <div className="ads">
                <Ads slot="ListingPageBanner" />
            </div>

            <div className="sortrow">
                <h2>{list[0]?.listingName}</h2>

                <SortBy
                    sortOptions={sortOptions}
                    applySort={applySort}
                    pagination={pagination}
                    handlePagination={handlePagination}
                />
            </div>
            <div className="limboListing">
                <div className="filterleft">
                    {!!filterOptions?.length && filterOptions?.length > 0 && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <p>FILTERS</p>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'baseline',
                                }}
                            >
                                <p>CLEAR FILTERS</p>
                                <LuFilterX
                                    style={{ cursor: 'pointer' }}
                                    onClick={clearFilters}
                                    data-toggle="tooltip"
                                    title="Clear Filter"
                                />
                            </div>
                        </div>
                    )}
                    {!!filterOptions?.length &&
                        filterOptions?.map((filter: any, idx: any) => {
                            return (
                                <CategoryFilter
                                    data={filter?.values}
                                    key={idx}
                                    limboFieldName={filter?.limboFieldName}
                                    limboFieldId={filter?.limboFieldId}
                                    childLimboFieldId={
                                        filter?.childLimboFieldId[0]
                                    }
                                    handleCheckBox={handleCheckBox}
                                    filterParams={filterParams}
                                    alreadyChecked={alreadyChecked}
                                />
                            )
                        })}
                    {!!filterOptions?.length && filterOptions?.length > 0 && (
                        <div className="priceFilter filterblock">
                            <button
                                className="btn-blue"
                                onClick={applyFilters}
                                disabled={
                                    filterParams.length === 0 &&
                                    filter.length === 0
                                }
                            >
                                Apply Filter
                            </button>
                        </div>
                    )}
                </div>
                <div className="listingpanel">
                    {!!isFiltered || !!loading ? (
                        <Loader />
                    ) : list.length > 0 ? (
                        list?.map((item: any, idx: any) => {
                            return (
                                <div className="listview" key={idx}>
                                    {item?.images && item?.images?.length ? (
                                        <div>
                                            <DetailsPageSlider
                                                data={item?.images}
                                            />
                                        </div>
                                    ) : (
                                        <img src={noImg} alt="img" />
                                    )}
                                    <LimboListItem
                                        data={item}
                                        addToFavourite={addToFavourite}
                                        isAlreadyFavorite={
                                            item?.isAlreadyFavorite
                                        }
                                        removeFromFavourite={
                                            removeFromFavourite
                                        }
                                    />
                                </div>
                            )
                        })
                    ) : (
                        <NoDataFound />
                    )}
                </div>
            </div>
        </div>
    )
}

export default ListingCategoryList
