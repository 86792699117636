import React, { useEffect, useState } from 'react'
import HomeCarousel from '../../components/HomeCarousel/HomeCarousel'
import Ads from '../../components/Ads/Ads'
import ShopByCategory from '../../components/ShopByCategory/ShopByCategory'
import { getCategory } from '../../api/services/category.service'
import { getListingList } from '../../api/services/listing.service'
import adsService from '../../api/services/ads.service'
import LandingPageListing from './LandingPageListing/LandingPageListing'
import { getBannerImage } from '../../helper/utils'

const LandinPage = () => {
    const [category, setCategory] = useState([])
    const [ads, setAds] = useState([])
    const [listing, setListing] = useState([])
    const [filteredListing, setFilteredListing] = useState([])
    const [loading, setloading] = useState(false)
    const [loadingListing, setloadingListing] = useState(false)
    const [adsSlot, setSlot] = useState([])
    const [, setHomeBanner] = useState<any>()

    const getAds = () => {
        adsService
            .adsService()
            .then((response) => {
                setAds(response?.data?.result)
            })
            .catch((error) => {
                console.error('error fetching data: ', error)
            })
    }

    const getsource = () => {
        const userRole = localStorage.getItem('userRole')

        if (userRole) {
            const role = JSON.parse(userRole)
            if (
                role === 'vendor-service-provider' ||
                role === 'vendor-sub-=user'
            ) {
                return 'listing'
            } else if (role === 'shopper') {
                return 'limbo'
            }
        }
    }

    const getLimboCategories = () => {
        setloading(true)
        getCategory(getsource())
            .then((response) => setCategory(response.data))
            .catch((error) => console.log(error))
            .finally(() => setloading(false))
    }

    const getListingData = () => {
        setloadingListing(true)
        getListingList('')
            .then((response) => setListing(response.data))
            .catch((error) => console.log(error))
            .finally(() => setloadingListing(false))
    }

    const getSlot = () => {
        adsService
            .getAdsSlot()
            .then((response) => {
                setSlot(response.data.result)
            })
            .catch((error) => console.log(error))
    }

    const handleOnFilter = (type: any) => {
        if (type === 'Service') {
            const fileterData = listing?.filter((item: any) => {
                return item?.listingType === 'Service'
            })
            setFilteredListing(fileterData)
        } else if (type === 'Product') {
            const fileterData = listing?.filter((item: any) => {
                return item?.listingType === 'Product'
            })
            setFilteredListing(fileterData)
        } else {
            setFilteredListing(listing)
        }
    }

    useEffect(() => {
        getLimboCategories()
        getAds()
        getListingData()
        getSlot()
        const HomeTopBannerImage = getBannerImage('HomeTopBanner')
        setHomeBanner(HomeTopBannerImage)
    }, [])

    return (
        <>
            <HomeCarousel />
            <div className="ads">
                <Ads slot="HomeTopBanner" />
            </div>
            <ShopByCategory
                data={category}
                loading={loading}
                handleFilter={handleOnFilter}
            />
            <div className="dashboard-limbo-section">
                <LandingPageListing
                    loadingListing={loadingListing}
                    ads={ads}
                    data={
                        filteredListing && filteredListing?.length
                            ? filteredListing
                            : listing
                    }
                    adsSlot={adsSlot}
                />
            </div>
        </>
    )
}

export default LandinPage
