import { createSlice } from '@reduxjs/toolkit'

const imgUploadSlice = createSlice({
    name: 'imgUpload',
    initialState: {
        path: '',
        loading: false,
    },
    reducers: {
        addImg: (state, action) => {
            state.loading = action.payload.loading
            state.path = action.payload.path
        },
        removeImg: (state, action) => {
            state.loading = false
            state.path = ''
        },
    },
})

export const { addImg, removeImg } = imgUploadSlice.actions
export default imgUploadSlice.reducer
