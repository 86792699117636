import React, { useState } from 'react'
import { IoMdCheckmark } from 'react-icons/io'
import {
    checkout,
    checkoutSubscription,
} from '../../api/services/stripe.service'
import Loader from '../../components/Loader/Loader'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const SubscriptionCard = ({ plan, key }: any) => {
    const user = useSelector((state: any) => state.user)
    const navigate = useNavigate()
    const [loading, setloading] = useState(false)

    const handleSubscription = async (id: any, planId: any) => {
        const token = localStorage.getItem('user')
        if (!token) {
            navigate('/login')
            return
        }
        setloading(true)
        try {
            const sessionId = await checkoutSubscription({
                priceId: id,
                planId,
                sub: user.userData.cognitoUserName,
            })
            setloading(false)
            setloading(false)
        } catch (error) {
            setloading(false)
        }
    }

    return (
        <div className="subscription__card" key={key}>
            <div className="subscription__card__top">
                <div className="subscription__card__plan">{plan.name}</div>
                <div className="subscription__card__price">
                    <span className="subscription__card__price__number">
                        CA$ {plan.price}
                    </span>
                    <span className="subscription__card__price__duration">
                        {' '}
                        / month
                    </span>
                </div>
                <div className="subscription__card__desc">
                    {plan.description}
                </div>
            </div>
            <div className="subscription__card__bottom">
                <div className="subscription__card__list">
                    {plan.features.map((feature: any, index: number) => {
                        return (
                            <div
                                className="subscription__card__list__item"
                                key={index}
                            >
                                <IoMdCheckmark /> {feature}
                            </div>
                        )
                    })}
                </div>
                <button
                    className="subscription__card__btn"
                    onClick={() =>
                        handleSubscription(plan.stripeSubId, plan._id)
                    }
                >
                    {loading ? <Loader /> : 'Buy Now'}
                </button>
            </div>
        </div>
    )
}

export default SubscriptionCard
