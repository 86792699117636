import React, { useMemo, useState } from 'react'
import arrow from './arrow.png'
import { ListGroup, ListGroupItem } from 'react-bootstrap'
import './dropdown.scss'

const Dropdown = ({
    options,
    onItemSelect,
    handleLimboField,
    placeholder,
    value,
    field,
}: any) => {
    const {
        limboFieldType,
        limboFieldId,
        displayOrder,
        fieldDataType,
        limboFieldName,
    } = field

    const [searchTerm, setSearchTerm] = useState('')
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(!open)
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value)
    }

    const onItemClick = (item: any) => {
        const { fieldValue, fieldValueId } = item
        setSearchTerm(fieldValue)
        setOpen(false)
        onItemSelect(item, limboFieldId)
        handleLimboField(
            limboFieldId,
            limboFieldName,
            fieldValueId,
            fieldValue,
            fieldDataType,
            displayOrder,
            limboFieldType
        )
    }

    const filteredItems = useMemo(() => {
        return options
            .find((item: any) => item.id === limboFieldId)
            ?.data.filter((item: any) =>
                item.fieldValue.toLowerCase().includes(searchTerm.toLowerCase())
            )
    }, [options, limboFieldId, searchTerm])

    return (
        <div className="dropdown">
            <div className="dropdown-input" onClick={handleClick}>
                <input
                    id={limboFieldId}
                    type="text"
                    value={value ? value : searchTerm}
                    name={limboFieldName}
                    placeholder={placeholder}
                    onChange={handleInputChange}
                />
                <img src={arrow} alt="" width="30px" height="30px" />
            </div>
            {open && filteredItems && (
                <div className="dropdown-list">
                    <div className="list">
                        <ListGroup>
                            {filteredItems.map((item: any, idx: any) => (
                                <ListGroupItem
                                    key={idx}
                                    onClick={() => onItemClick(item)}
                                >
                                    {item.fieldValue}
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Dropdown
