import axios from 'axios'
import customAxios from '../axios'
import { END_POINTS } from '../domain'
import authHeader from './auth-header'

export const saveLimbo = (payload: any) => {
    return customAxios.post(`${END_POINTS.saveLimbo}`, payload)
}

export const getLimbosList = (type: any) => {
    const payload = { params: { limboType: type } };
    return customAxios.get(`${END_POINTS.getLimbosListing}`, payload )
}

export const limboNotificationVendor = () => {
    return customAxios.get(`${END_POINTS.limboNotificationVendor}`,)
}

export const getLimbosById = () => {
    return customAxios.get(`${END_POINTS.getLimbosListing}`,)
}

export const getLimboDetail = (id: any) => {
    return customAxios.get(`${END_POINTS.getLimboDetail}/${id}?includeDetails=1`,)
}

export const updateLimboStatus = (id: any, payload: any) => {
    return customAxios.put(`${END_POINTS.limboStatus}/${id}`, payload,)
}

export const getShopperSubs = () => {
    return axios.get(END_POINTS.shopperSubs, {
        headers: authHeader(),
    })
}
