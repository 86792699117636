import moment from 'moment'


//takes a moment time object and return the elapsed time until today
const returnTimePassed = (momentTimeObj) => {
    const timeStamp = momentTimeObj
        ? moment(momentTimeObj)
        : moment(new Date())

    const currTime = moment(new Date())

    const hoursDiff = currTime.diff(timeStamp, 'hours')
    const minutesDiff = currTime.diff(timeStamp, 'minutes')
    const daysDiff = currTime.diff(timeStamp, 'days')

    let timeText = ''
    if (minutesDiff <= 59) {
        timeText = minutesDiff + (minutesDiff > 1 ? ' mins ago' : ' min ago')
    } else if (hoursDiff <= 23) {
        timeText = hoursDiff + (hoursDiff > 1 ? ' hours ago' : ' hour ago')
    } else {
        timeText = daysDiff + (daysDiff > 1 ? ' days ago' : ' day ago')
    }

    return timeText
}

export default returnTimePassed
