import React, { useEffect, useMemo, useRef, useState } from 'react'
import './Chat.scss'
import io, { Socket } from 'socket.io-client'
import EmojiPicker from 'emoji-picker-react'
import { GoArrowRight, GoPaperclip } from 'react-icons/go'
import { FaRegFaceSmile } from 'react-icons/fa6'
import { PiDotsThreeOutlineVerticalBold } from 'react-icons/pi'
import InputEmoji from 'react-input-emoji'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import userService from '../../api/services/user.service'
import { set } from 'date-fns'

const messages = {}

type Props = {
    shopperId: string
    vendorId: string
    listingId: string
    closeCallback: () => void
}

type ChatObj = {
    senderType: string
    content: string
    contentType: string
    sender: string
    time_stamp?: string
    chatMessageId: string
}

type ChatObjArr = ChatObj[]

type MessageObj = {
    type: string
    content: string
}

function Chat(props: Props): JSX.Element {
    const { shopperId, vendorId, listingId, closeCallback } = props
    const loggedInUser = localStorage?.getItem('email')?.split('"')?.[1]
    const userRole = localStorage.getItem('userRole')

    if (userRole == '"admin"') {
        return <></>
    }

    const [name, setName] = useState<string>('')
    const [socket, setSocket] = useState<Socket | null>(null)
    const [message, setMessage] = useState<string>('')
    const [currRoom, setCurrRoom] = useState<string>('')
    const [chatMessageArray, setChatMessageArray] = useState<ChatObjArr>([
        {
            senderType: '',
            content: '',
            contentType: '',
            sender: '',
            chatMessageId: '',
        },
    ])
    const [onlineUsers, setOnlineUsers] = useState<string[]>([])

    const getNameOfTheUser = async () => {
        if (loggedInUser) {
            let shopperEmailData = null
            let vendorEmailData = null
            if (shopperId.includes('@')) {
                shopperEmailData = await userService.getuserInfo(shopperId)
            } else {
                shopperEmailData = await userService.getuserInfoWithCognitoId(
                    shopperId
                )
                const firstName = shopperEmailData?.data?.result?.first_name
            }

            if (vendorId.includes('@')) {
                vendorEmailData = await userService.getuserInfo(vendorId)
            } else {
                vendorEmailData = await userService.getuserInfoWithCognitoId(
                    vendorId
                )
                const firstName = vendorEmailData?.data?.result?.first_name
            }

            const shopperEmail = !shopperId.includes('@')
                ? shopperEmailData?.data?.result?.email_id || ''
                : shopperEmailData?.data?.result?.[0]?.email_id || ''

            const vendorEmail = !vendorId.includes('@')
                ? vendorEmailData?.data?.result?.email_id || ''
                : vendorEmailData?.data?.result?.[0]?.email_id || ''

            if (loggedInUser === shopperEmail) {
                let name = ''
                if (vendorId.includes('@')) {
                    name = `${vendorEmailData?.data?.result?.[0]?.first_name} ${vendorEmailData?.data?.result?.[0]?.last_name}`
                } else {
                    name = `${vendorEmailData?.data?.result?.first_name} ${vendorEmailData?.data?.result?.last_name}`
                }
                setName(name.includes('undefined') ? 'name' : name)
            } else {
                let name = ''
                if (shopperId.includes('@')) {
                    name = `${shopperEmailData?.data?.result?.[0]?.first_name} ${shopperEmailData?.data?.result?.[0]?.last_name}`
                } else {
                    name = `${shopperEmailData?.data?.result?.first_name} ${shopperEmailData?.data?.result?.last_name}`
                }
                setName(name.includes('undefined') ? 'name' : name)
            }
        }
    }

    useEffect(() => {
        getNameOfTheUser()
    }, [])

    const sender: string = useMemo(() => {
        const temp = localStorage.getItem('email')
        return temp?.split('"')?.[1] || ''
    }, [])

    const chatBodyRef = useRef<HTMLDivElement>(null)
    const inputFileRef = useRef<HTMLInputElement>(null)
    const messageRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        const newSocket: Socket = io(`${process.env.REACT_APP_API_URL_SOCKET || 'http://localhost:3000'}`)
        if (shopperId && vendorId && listingId) {
            newSocket.removeAllListeners()
            setSocket(newSocket)
            // Setup events then join room
            newSocket.on('roomName', ({ roomName }: { roomName: string }) => {
                setCurrRoom(roomName)
                newSocket.emit('moreMessages', {
                    roomId: roomName,
                    noOfMessages: 100,
                })
            })
            newSocket.on('chatMessage', (data: ChatObj) => {
                setChatMessageArray((prev) => [...prev, data])
            })

            newSocket.on(
                'messageArray',
                ({ messageArray }: { messageArray: ChatObj[] }) => {
                    setChatMessageArray(messageArray)
                }
            )
            newSocket.on('userJoined', ({ joinedUser }) => {
                return true
            })

            newSocket.on('userLeft', ({ loggedInUser }) => {
                return true
            })

            newSocket.on('onlineUsers', ({ onlineUsers }) => {
                setOnlineUsers(onlineUsers)
            })

            // Join room
            newSocket.emit('joinRoom', {
                shopperEmail: shopperId,
                vendorEmail: vendorId,
                productId: `${listingId}`,
                joinedUser: loggedInUser,
            })
        }
        return () => {
            try {
                socket && socket.emit('userLeft', { loggedInUser, currRoom })
                socket && socket.removeAllListeners()
                newSocket && newSocket.removeAllListeners()
                setSocket(null)
            } catch (err) {
                console.log('err ', err)
            }
        }
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (currRoom) {
                socket && socket.emit('getOnlineUsers', { currRoom })
            }
        }, 3000)
        return () => clearInterval(interval)
    }, [currRoom])

    const sendMessage = (msg?: string) => {
        if (!msg) {
            msg = message
        }
        if (socket) {
            socket.emit('chatMessage', {
                room: currRoom,
                senderType: 'shopper',
                content: msg,
                contentType: 'string',
                sender: sender,
            })
            setMessage('') // Clear the input field after sending the message
        }
    }

    useEffect(() => {
        if (
            chatBodyRef &&
            chatBodyRef.current &&
            chatBodyRef.current &&
            chatBodyRef.current.scrollHeight &&
            chatBodyRef.current.scrollTo
        ) {
            chatBodyRef.current.scrollTo(0, chatBodyRef.current.scrollHeight)
        }
    }, [chatMessageArray])

    const handleClick = () => {
        if (
            inputFileRef &&
            inputFileRef?.current &&
            inputFileRef?.current?.click
        ) {
            inputFileRef.current.click()
        }
    }

    const handleChange = (event: any) => {
        const fileUploaded = event.target.files[0]
    }

    const day = useMemo(() => {
        const lastChatMessage =
            chatMessageArray &&
            chatMessageArray?.length &&
            chatMessageArray.length > 1 &&
            chatMessageArray[chatMessageArray.length - 1]
        const date =
            lastChatMessage && lastChatMessage?.time_stamp
                ? lastChatMessage.time_stamp
                : null
        if (date) {
            const currDate = new Date().getDay()
            const currMonth = new Date().getMonth()
            const currYear = new Date().getFullYear()
            const chatDate = new Date(date).getDay()
            const chatMonth = new Date(date).getMonth()
            const chatYear = new Date(date).getFullYear()
            if (
                currDate === chatDate &&
                currMonth === chatMonth &&
                currYear === chatYear
            ) {
                return 'Today'
            } else {
                return `${chatDate}-${chatMonth}-${chatYear}`
            }
        }
    }, [chatMessageArray, message])

    //using the chat body ref return if one of the element inside it is visible or not
    const isScrolledIntoView = (el: any) => {
        const rect = el.getBoundingClientRect()
        const elemTop = rect.top
        const elemBottom = rect.bottom
        const isVisible = elemTop >= 0 && elemBottom <= window.innerHeight
        return isVisible
    }

    const listenerFunction = (element: any) => {
        //check if the element is visible or not
        if (isScrolledIntoView(element)) {
            //get the id of the element
            const id = element.getAttribute('data-message-id')
            const loggedInUser = element.getAttribute('data-message-sender')
            const sender = element.getAttribute('data-chat-sender')
            //update the read status of the message
            if (loggedInUser !== sender) {
                updateReadStatus(id)
            }
        }
    }

    useEffect(() => {
        const listenerFunction = (element: any) => {
            //check if the element is visible or not
            if (isScrolledIntoView(element)) {
                //get the id of the element
                const id = element.getAttribute('data-message-id')
                const loggedInUser = element.getAttribute('data-message-sender')
                const sender = element.getAttribute('data-chat-sender')
                //update the read status of the message
                if (loggedInUser !== sender) {
                    updateReadStatus(id)
                }
            }
        }

        //assign a scroll event listenere to the chat bodsy ref
        chatBodyRef.current?.addEventListener('scroll', () => {
            //get all the message container elements
            const messageContainers =
                document.querySelectorAll('.messageContainer')
            //loop through the message containers and check if they are visible or not
            messageContainers.forEach((element) => {
                listenerFunction(element)
            })
        })

        return () => {
            chatBodyRef.current?.removeEventListener('scroll', () => {
                //get all the message container elements
                const messageContainers =
                    document.querySelectorAll('.messageContainer')
                //loop through the message containers and check if they are visible or not
                messageContainers.forEach((element) => {
                    listenerFunction(element)
                })
            })
        }
    }, [chatMessageArray])

    useEffect(() => {
        chatMessageArray.forEach((element: any) => {
            const messageId = element._id || element?.chatMessageId
            const htmlMessageElement = document.getElementById(messageId)
            if (element && messageId && htmlMessageElement) {
                listenerFunction(htmlMessageElement)
            }
        })
    }, [chatMessageArray.length])

    const updateReadStatus = (messageId: string) => {
        messageId && socket && socket.emit('messageRead', messageId)
    }

    return (
        <div className="chat-container">
            <div className="chat-title">
                <div className="name">{name || 'name'}</div>
                <div className="status">
                    {onlineUsers?.length > 1 ? 'online' : 'offline'}
                </div>
                <div
                    className="threedot"
                    onClick={() => {
                        socket?.emit('userLeft', { loggedInUser, currRoom })
                        closeCallback()
                    }}
                >
                    <IoIosCloseCircleOutline />
                </div>
            </div>
            <div className="chat-body" ref={chatBodyRef}>
                {chatMessageArray.map((chat: any) => {
                    const messageBox = ` ${
                        chat.sender === sender ? ' text-left' : ' text-right'
                    } message-box`
                    return (
                        <>
                            <div
                                className="messageContainer"
                                data-message-id={
                                    chat._id || chat?.chatMessageId
                                }
                                id={chat._id || chat?.chatMessageId}
                                key={chat._id || chat?.chatMessageId}
                                data-message-sender={sender}
                                data-chat-sender={chat.sender}
                            >
                                <div className={messageBox}>
                                    <div className={'message'}>
                                        {chat.content}
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })}
            </div>
            <div className="send-field">
                <InputEmoji
                    placeholder="Type your message..."
                    value={message}
                    onChange={setMessage}
                    cleanOnEnter
                    onEnter={sendMessage}
                    height={600}
                />
                {/* <div className="files" onClick={handleClick}>
                    <input
                        type="file"
                        className="file-input"
                        ref={inputFileRef}
                        onClick={handleChange}
                    />
                    <GoPaperclip />
                </div> */}
                <div
                    className="enter"
                    onClick={() => {
                        message && sendMessage(message)
                    }}
                >
                    <GoArrowRight />
                </div>
            </div>
        </div>
    )
}

export default Chat
