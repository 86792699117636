import { MDBDataTable } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { CataegoryHead, csvAppendCreateFields, csvCatCreateFields, csvCatUpdateFields } from '../../../../constant/constant'
import { getIconClassName } from '../../../../helper/utils'
import {
    addCategory,
    appendCsvCategory,
    getCategories,
    updateCsvCategory,
} from '../../../../api/services/category.service'
import Image from '../../../../components/Image/Image'
import { placeholderSm } from '../../../../helper/iconPath'
// import './category.scss'
import { FaCheck } from 'react-icons/fa6'
import { RxCross2 } from 'react-icons/rx'
// import { ModalComp } from '../../../../components/Modal/Modal'
// import CategoryUpdateForm from './Form/CategoryUpdateForm'
import Swal from 'sweetalert2'
import Loader from '../../../../components/Loader/Loader'
import { Button } from 'react-bootstrap'
import { ModalComp } from '../../../../components/Modal/Modal'
import CategoryUpdateForm from '../CategoryList/Form/CategoryUpdateForm'
import CsvAction from './CsvAction'

interface CsvFields {
    append: {
        onsubmit: any,
        title: string,
        fields: any
    };
    create: {
        onsubmit: any,
        title: string,
        fields: any
    };
    update: {
        onsubmit: any,
        title: string,
        fields: any
    }
}
const CSV = () => {
    const [error, seterror] = useState()
    const [data, setdata] = useState<any>([])
    const [loading, setloading] = useState(false)
    const [showModal, setshowModal] = useState(false)
    const [formType, setformType] = useState('')
    const handleEdit = (item: any) => {
        setformType('update')
        setshowModal(true)
        setFormData({
            id: item._id,
            categoryName: item.limboName,
            categoryType: item.limboType,
            categoryImagePath: item.limboImage,
            status: item.status,
            limbo: item.limbo,
            listing: item.listing,
        })
    }
    const handleAppend = (item: any) => {
        setshowModal(true)
        setformType('append')
        setFormData({
            id: item._id,
            limboName: item.limboName,
            limboType: item.limboType,
            limboImage: item.limboImage,
            status: item.status,
            limbo: item.limbo,
            listing: item.listing,
        })
    }

    const submitAppend = async () => {
        try {
            const res = await appendCsvCategory(formData.id, formData)
            console.log(res);
        } catch (error) {
            console.log(error);

        }
    }
    const submitUpdate = async () => {
        try {
            const res = await updateCsvCategory(formData.id, formData)
            getCategoryList()
            console.log(res);
            setshowModal(false)
        } catch (error) {
            console.log(error);
        }
    }
    const createCat = () => {
        setformType('create')
        setshowModal(true)
        setFormData({
            categoryName: '',
            categoryType: 'Product',
            file: '',
            limbo: false,
            listing: false,
        })
    }
    const [formData, setFormData] = useState<any>()
    const getCategoryList = async () => {
        setloading(true)
        try {
            const result: any = await getCategories('')
            setloading(false)
            setdata({
                columns: CataegoryHead,
                rows: result.data.map((row: any, index: any) => {
                    return {
                        ...row,
                        status: (
                            <div className={getIconClassName(row.status)}>
                                {row.status}
                            </div>
                        ),
                        limboImage: (
                            <Image
                                className="category__img"
                                src={row.limboImage}
                                alt="limbo image"
                                placeholder={placeholderSm}
                            />
                        ),
                        limbo: (
                            <div>{row.limbo ? <FaCheck title='active'/> : <RxCross2 title='inative'/>}</div>
                        ),
                        listing: (
                            <div>
                                {row.listing ? <FaCheck title='active'/> : <RxCross2 title='inative'/>}
                            </div>
                        ),
                        action: (
                            <div className="icon__wrapper">
                                <CsvAction handleAppend={handleAppend} handleEdit={handleEdit} row={row}/>
                            </div>
                        ),
                    }
                }),
            })
        } catch (error: any) {
            seterror(error.message)
            setloading(false)
        }
    }

    const createHandler = async () => {

        try {
            const result = await addCategory(formData)
            if (result) {
                Swal.fire({
                    title: 'Added',
                    text: 'Category added successfully',
                    icon: 'success',
                })
            }
            getCategoryList()
            setshowModal(false)
        } catch (error: any) {
            Swal.fire({
                title: 'Something went wrong',
                text: error.message,
                icon: 'error',
            })
        }
    }

    useEffect(() => {
        getCategoryList()
    }, [])

    if (loading) {
        return (
            <div className="loader__table">
                <Loader />
            </div>
        )
    }

    const csvFeilds: CsvFields = {
        append: {
            onsubmit: submitAppend,
            title: "Append category details",
            fields: csvAppendCreateFields
        },
        create: {
            onsubmit: createHandler,
            title: "Create category",
            fields: csvCatCreateFields
        },
        update: {
            onsubmit: submitUpdate,
            title: "Update category fields",
            fields: csvCatUpdateFields
        }
    }
    return (
        <div className="admin__table admin__table__csv">
            <div className="admin__table__createCat btn-row">
                <Button onClick={createCat}>Create Category</Button>
            </div>
            {error && (
                <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                </div>
            )}
            <MDBDataTable
                small
                data={data}
                className="responsive-table category"
                responsive
                sortable={false}
            />

            <ModalComp
                onSubmit={csvFeilds[formType as keyof CsvFields]?.onsubmit}
                className="category__update__modal"
                desc={
                    <CategoryUpdateForm
                        formData={formData}
                        setFormData={setFormData}
                        formType={formType}
                        field={csvFeilds[formType as keyof CsvFields]?.fields}
                    />
                }
                show={showModal}
                title={csvFeilds[formType as keyof CsvFields]?.title}
                setshow={setshowModal}
                submitText={formType}
            />

        </div>
    )
}

export default CSV
