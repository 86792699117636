import React from 'react'
import '../Login/login.scss'
import LoginForm from './LoginForm'

const Login = () => {
    return (
        <div className="loginPage">
            <div className="loginWrap">
                <div className="loginLeft">
                    <h3>Registered User</h3>
                    <p>
                        If you have an account, sign in with your email address.
                    </p>
                    <LoginForm />

                    <a href="/register">
                        Don’t have a Price Limbo account? Create one now
                    </a>
                </div>
                <div className="loginRight">
                    <img
                        src={process.env.PUBLIC_URL + '/images/logo.svg'}
                        alt="Logo"
                        className="loginlogo"
                    />
                    <ul>
                        <li>
                            <span className="icon">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/images/icon-camera.png'
                                    }
                                    alt="icon"
                                />
                            </span>
                            Participate in exciting Limbo competitions
                        </li>
                        <li>
                            <span className="icon">
                                {' '}
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/images/icon-fav.png'
                                    }
                                    alt="icon"
                                />
                            </span>
                            Find amazing deals and savings on new products
                        </li>
                        <li>
                            <span className="icon">
                                {' '}
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/images/icon-chat.png'
                                    }
                                    alt="icon"
                                />
                            </span>
                            Direct easy communication
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Login
