import React, { useEffect, useState } from 'react'
import "./subscription.scss"
import { useParams, useSearchParams } from 'react-router-dom'
import Loader from '../../components/Loader/Loader'
import SubscriptionPlans from "../../api/services/subscription.service"
import { IoMdCheckmark } from "react-icons/io";
import SubscriptionCard from './SubscriptionCard';
import { useNavigate } from 'react-router-dom';

const Subscriptions = () => {
    const { status } = useParams()
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState('')
    const [data, setdata] = useState<any>([])
    const navigate = useNavigate()
    const [searchParams,seatSearchParams] = useSearchParams()
    const description = searchParams.get('description')

    useEffect(() => {

        const getSubs = async () => {
            setloading(true)
            try {
                const plans = await SubscriptionPlans.getSubscriptionPlans()
                setloading(false)
                setdata(plans.data)

            } catch (error: any) {
                setloading(false)
                seterror(error.message)
            }
        }
        getSubs()
    }, [])

    if (loading) {
        return <div className="loader__table">
            <Loader />
        </div>;
    }
    const redirect = () => {
        if (status === 'success') {
            navigate('/success')
        } else {
            navigate('/')
        }
    }
    return (
        <div className='subscription'>
            <div className="subscription__title">
                Subscription Plans
            </div>
            <div className="subscription__header">
                <h1>available subscription plans</h1>
                <p>{description}</p>
            </div>
            <div className="subscription__card__wrapper">
                {
                    data?.map((plan: any, index: number) => {
                        return (
                            <SubscriptionCard plan={plan} key={index} />
                        )
                    })
                }
            </div>
            <div className="subscription__skipBtn">
                <button className='btn btn-primary mt-30' onClick={redirect}>Skip Now</button>
            </div>

        </div>
    )
}

export default Subscriptions
