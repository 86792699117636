import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import userService from '../../api/services/user.service'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { setUserData } from '../../redux/userSlice'

import MapWithSearch from '../LocationMap/Map'
import LocationSlider from '../LocationSlider/LocationSlider'

const MapPopup = ({ setShow, show, placement, handleLocation }: any) => {
    const [locationData, setLocationData] = useState<any>({})
    const dispatch = useDispatch()
    const [userLocation, setUserLocation] = useState<any>({ lat: 0, lng: 0 })
    const userData = useSelector((state: any) => state.user.userData)
    const [circleRadius, setCircleRadius] = useState<number>(20 * 20) // Initial radius in meters

    const getReverseGeoCodeData = async (lat: number, lng: number) => {
        //use open cage api to get the zip code and city name from lat and long
        try {
            if (lat && lng) {
                const url = `https://api.opencagedata.com/geocode/v1/json?q=${lat}+${lng}&key=3b93f6926b784fd3b917ef06809b8c1f`
                const response = await fetch(url)
                const data = await response.json()

                return {
                    components: data?.results?.[0]?.components,
                    lat: lat,
                    lng: lng,
                }
            }
        } catch (err) {
            console.log(' err ', err)
        }
    }

    const setUserLocationData = async (lat: any, long: any) => {
        if (lat === 0 && long === 0) {
            lat = userData?.latitude
            long = userData?.longitude
        }

        const data: any = await getReverseGeoCodeData(lat, long)
        setLocationData(data)
    }

    useEffect(() => {
        setUserLocationData(userLocation?.lat, userLocation?.lng)
    }, [userLocation?.lat, userLocation?.lng, userData])

    const saveDetails = async () => {
        if (placement === 'header') {
            const loggedInUser =
                localStorage?.getItem('email')?.split('"')?.[1] || ''
            const updatedRes = await userService.updateUserLocation(
                loggedInUser,
                locationData?.lat,
                locationData?.lng,
                locationData?.components?.state || '',
                locationData?.components?.city || '',
                locationData?.components?.city_district || '',
                locationData?.components?.postcode || ''
            )
            dispatch(
                setUserData({
                    ...userData,
                    ...{
                        state: locationData?.components?.state || '',
                        city: locationData?.components?.city || '',
                        district: locationData?.components?.city_district || '',
                        zip: locationData?.components?.postcode || '',
                        latitude: locationData?.lat,
                        longitude: locationData?.lng,
                    },
                })
            )
            setShow(false)
            toast('Location updated successfully')
        } else {
            handleLocation('location', { locationData, circleRadius })
            setShow(false)
        }
    }

    const handleOnChange = (name: any, value: any) => {
        setCircleRadius(40 * value)
    }

    return (
        <div className="mappopup">
            <Modal
                show={show}
                onHide={() => {
                    setShow(false)
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Search your location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MapWithSearch
                        locationData={userLocation}
                        setLocationData={setUserLocation}
                        circleRadius={circleRadius}
                        setCircleRadius={setCircleRadius}
                        currentLoc={locationData}
                        userDataAdd={
                            userData
                                ? [
                                      userData.district,
                                      userData.state,
                                      userData.zip,
                                  ]
                                : []
                        }
                        userData={userData}
                    />
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'space-between' }}>
                    <LocationSlider
                        field={'location'}
                        handleOnChange={handleOnChange}
                    />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <button
                            className="btn btn-grey"
                            onClick={() => {
                                setShow(false)
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-orange"
                            onClick={saveDetails}
                        >
                            Save
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default MapPopup
