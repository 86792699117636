import React from 'react'
import ImagePicker from '../components/ImagePicker/ImagePicker'
import DateTimePicker from '../components/DateTimePicker/DateTimePicker'

export const getFormFields = ({
    handleOnChange,
    onImageUpload,
    value,
    awsFolderName,
    field,
    multiple,
    errors,
}: any) => {
    const { type, name, placeholder, id, label, required, hidden } = field
    switch (type) {
        case 'checkbox':
            return (
                <div className="check-boxes-form-fields" id={id}>
                    <input
                        type="checkbox"
                        id={id}
                        name={name}
                        value={value}
                        onChange={(event) =>
                            handleOnChange(name, event.target.checked)
                        }
                        placeholder={placeholder}
                    ></input>
                    <label htmlFor={label}>
                        {label}
                        {required && <span style={{ color: 'red' }}>*</span>}
                    </label>
                </div>
            )
        case 'image':
            return (
                <div className="form-fields">
                    <label htmlFor={name}>{label}</label>
                    <ImagePicker
                        multiple={multiple}
                        onImageUpload={onImageUpload}
                        awsFolderName={awsFolderName}
                        field={field}
                        value={value}
                    />
                </div>
            )

        // case 'range':
        //     return (
        //         <div>
        //             <label>Select Location</label>
        //             {/* <MapPopup show={show} setShow={setShow} /> */}
        //         </div>
        //         // <LocationSlider handleOnChange={handleOnChange} field={field} />
        //     )

        case 'date':
            return (
                <div className="form-fields">
                    <label htmlFor={name}>
                        {name}
                        {required && <span style={{ color: 'red' }}>*</span>}
                    </label>
                    <DateTimePicker
                        field={field}
                        handleOnChage={handleOnChange}
                    />
                    {errors && (
                        <div style={{ color: 'red' }}>{errors[name]}</div>
                    )}
                </div>
            )
        case 'textarea':
            return (
                <div className="form-fields">
                    <label htmlFor={name}>
                        {label}{' '}
                        {required && <span style={{ color: 'red' }}>*</span>}
                    </label>
                    <textarea
                        name={name}
                        value={value}
                        onChange={(event) =>
                            handleOnChange(name, event.target.value)
                        }
                        rows={6}
                        cols={4}
                        placeholder={placeholder}
                    ></textarea>
                    {errors && (
                        <div style={{ color: 'red' }}>{errors[name]}</div>
                    )}
                </div>
            )
        default:
            return (
                <div
                    className="form-fields"
                    style={{ display: hidden ? 'none' : 'block' }}
                >
                    <label htmlFor={name}>
                        {label}{' '}
                        {required && <span style={{ color: 'red' }}>*</span>}
                    </label>
                    {/* {label === 'Price' && (
                        <span>{`${process.env.REACT_APP_CURRENCY_SIGN}$`}</span>
                    )} */}
                    <input
                        type={type}
                        id={id}
                        name={name}
                        value={value}
                        onChange={(event) =>
                            handleOnChange(name, event.target.value)
                        }
                        placeholder={placeholder}
                    />
                    {errors && (
                        <div style={{ color: 'red' }}>{errors[name]}</div>
                    )}
                </div>
            )
    }
}

const FormFields = ({
    handleOnChange,
    onImageUpload,
    value,
    awsFolderName,
    field,
    errors,
    show,
    setShow,
}: any) => {
    return getFormFields({
        handleOnChange,
        onImageUpload,
        value,
        awsFolderName,
        field,
        errors,
        show,
        setShow,
    })
}

export default FormFields
