import React from 'react'
import UserIcon from '../../assets/svg/user.svg'
import AdvertisementIcon from '../../assets/svg/advertisement.svg'
import ListingIcon from '../../assets/svg/listing.svg'
import LimboIcon from '../../assets/svg/limbo.svg'
import SubscriptionIcon from '../../assets/svg/subscribtion.svg'
import CategoryIcon from '../../assets/svg/categories.svg'

import './sidenavbar.scss'

export const roleList = [
    {
        role: 'admin',
        list: [
            {
                id: 'shopper-list',
                title: 'Shoppers Accounts',
                icon: UserIcon,
            },
            {
                id: 'vendor-list',
                title: 'Add/Remove/Edit Users',
                icon: UserIcon,
            },
            {
                id: 'ad-list',
                title: 'Advertisement List',
                icon: AdvertisementIcon,
            },
            {
                id: 'listing-list',
                title: 'Listing List',
                icon: ListingIcon,
            },
            {
                id: 'limbo-list',
                title: 'Limbo List',
                icon: LimboIcon,
            },
            {
                id: 'category-list',
                title: 'Categories List',
                icon: CategoryIcon,
            },
            {
                id: 'manageSubscription',
                title: 'Manage Subscription',
                icon: SubscriptionIcon,
            },
            {
                id: 'report-list',
                title: 'Reported Issues',
                icon: SubscriptionIcon,
            },
            {
                id: 'user-categories',
                title: 'Database Considerations',
                icon: CategoryIcon,
            },
            {
                id: 'upload-csv',
                title: 'CSV Upload',
                icon: CategoryIcon,
            },
        ],
    },
    {
        role: 'vendor-sub-user',
        list: [
            {
                id: 'profile',
                title: 'Profile',
                icon: AdvertisementIcon,
            },
            {
                id: 'favourites',
                title: 'Favourites',
                icon: ListingIcon,
            },
            {
                id: 'limbos',
                title: 'Limbo List',
                icon: LimboIcon,
            },
            {
                id: 'listings',
                title: 'My Listings',
                icon: ListingIcon,
            },
            {
                id: 'ads',
                title: 'Promotional Ads List',
                icon: AdvertisementIcon,
            },
        ],
    },
    {
        role: 'vendor-service-provider',
        list: [
            {
                id: 'profile',
                title: 'Profile',
                icon: AdvertisementIcon,
            },
            {
                id: 'favourites',
                title: 'Favourites',
                icon: ListingIcon,
            },
            {
                id: 'limbos',
                title: 'Limbo List',
                icon: LimboIcon,
            },
            {
                id: 'listings',
                title: 'My Listings',
                icon: ListingIcon,
            },
            {
                id: 'ads',
                title: 'Promotional Ads List',
                icon: AdvertisementIcon,
            },
            {
                id: 'sub-vendor-list',
                title: 'Vendor List',
                icon: AdvertisementIcon,
            },
        ],
    },
    {
        role: 'shopper',
        list: [
            {
                id: 'profile',
                title: 'Profile',
                icon: AdvertisementIcon,
            },
            {
                id: 'favourites',
                title: 'My Favourites',
                icon: ListingIcon,
            },
            {
                id: 'limbos',
                title: 'Limbo List',
                icon: LimboIcon,
            },
        ],
    },
]

interface SideNavBarProps {
    onNavCliCk: (item: any) => void
    role: any
}

const SideNavBar: React.FC<SideNavBarProps> = ({ onNavCliCk, role }) => {
    const OnSelect = (item: any) => {
        onNavCliCk(item)
    }

    const filteredData = roleList.filter((item) => item?.role === role)

    return (
        <div className="">
            {filteredData[0]?.list.map((item, idx) => {
                return (
                    <div key={idx} className="side-nav-bar">
                        <div className="nav" onClick={() => OnSelect(item)}>
                            <img
                                src={item?.icon}
                                className="img-fluid"
                                alt="side-nav-icon"
                            ></img>
                            <p>{item?.title}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default SideNavBar
