import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useParams } from 'react-router'
import './LimboDetailsPage.scss'
import BreadcrumbItem from '../../components/BreadcrumbItem/BreadcrumbItem'
import LimboDetails from '../../components/LimboDetails/LimboDetails'
import CurrentOfferTable from '../../components/CurrentOfferTable/CurrentOfferTable'
import Ads from '../../components/Ads/Ads'
import LimboClosed from '../../components/LimboClosed/LimboClosed'
import { getLimboDetail } from '../../api/services/limbo.service'
import noImg from '../../assets/images/no-image.png'
import DetailsPageSlider from '../../components/DetailsPageSlider/DetailsPageSlider'
import { getFileExtensionRegex } from '../../helper/utils'

const LimboDetailsPage = () => {
    const [offers, setOffers] = useState([])
    const [curretOffer, setCurrentOffer] = useState([])
    const [limboDetail, setLimboDetail] = useState<any>([])
    const [limboStatus, setLimboStatus] = useState('')
    const [endDate, setEndDate] = useState('')
    const [expireStatus, setExpireStatus] = useState(false)
    const { id } = useParams()

    const getLimboDetailInfo = () => {
        getLimboDetail(id)
            .then((response: any) => {
                setLimboDetail(response.data.data[0])
                setOffers(response.data.offers)
                setCurrentOffer(response.data.myOffer)
                setLimboStatus(response.data.data[0].status)
                setEndDate(response.data.data[0].endAt)
                const currentDate = new Date()
                const endDateCheck = moment(endDate, 'DD/MM/YYYY')
                if (
                    moment(response?.data?.data?.[0]?.endAt || new Date()).diff(
                        moment(new Date())
                    ) > 0
                ) {
                    setExpireStatus(false)
                } else {
                    setExpireStatus(true)
                }
            })
            .catch((error: any) => console.log(error))
    }

    useEffect(() => {
        getLimboDetailInfo()
    }, [])

    return (
        <div className="limbo-details-section ">
            {/* <BreadcrumbItem /> */}
            <div className="details-section">
                <div className="imgPanel">
                    <h2>{limboDetail?.limboName}</h2>
                    {limboDetail.images?.length > 0 &&
                    getFileExtensionRegex(limboDetail.images[0]) != 'svg' ? (
                        <DetailsPageSlider data={limboDetail?.images} />
                    ) : (
                        <img src={noImg} alt="img" />
                    )}
                </div>
                <LimboDetails
                    data={limboDetail && limboDetail}
                    curretOffer={curretOffer}
                    limboStatus={limboStatus}
                    expireStatus={expireStatus}
                    getLimboDetailInfo={getLimboDetailInfo}
                />
            </div>

            {limboDetail && limboStatus == 'closed' && (
                <LimboClosed text="Limbo Closed" />
            )}
            {limboDetail && expireStatus && limboStatus != 'closed' && (
                <LimboClosed text="Limbo expired" />
            )}
            <div className="current-offer-table">
                {(offers.length > 0 || curretOffer.length > 0) && (
                    <CurrentOfferTable
                        data={offers}
                        curretOffer={curretOffer}
                        limboStatus={limboStatus}
                        expireStatus={expireStatus}
                        limboDetail={limboDetail}
                    />
                )}
            </div>

            <div className="ads">
                <Ads slot="ListingPageBanner" />
            </div>
        </div>
    )
}

export default LimboDetailsPage
