import React, { useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { addUserFormFields } from '../constant'
import { addMultiUser } from '../../../../api/services/multi-user-vendor'
import './adduserform.scss'
import { handlePhoneChange } from '../../../../helper/utils'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

interface FormValues {
    firstName: string
    lastName: string
    username: string
    phoneNumber: string
}

const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(3, 'First name must be at least 3 characters')
        .max(20, 'First name must be at most 20 characters')
        .required('First name is required'),
    lastName: Yup.string()
        .min(3, 'Last name must be at least 3 characters')
        .max(20, 'Last name must be at most 20 characters')
        .required('Last name is required'),
    // phoneNumber: Yup.string().matches(
    //     /^\d{10}$/,
    //     'Phone number must be exactly 10 digits'
    // ),
    username: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
})

const initialValues: FormValues = {
    firstName: '',
    lastName: '',
    username: '',
    phoneNumber: '',
}

const AddUserForm: React.FC = () => {
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const handleAddUser = (formValue: FormValues) => {
        const { phoneNumber, ...data } = formValue
        const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '')

        addMultiUser({ ...data, phoneNumber: cleanedPhoneNumber })
            .then(
                (response) => {
                    if (response?.data?.success === true) {
                        navigate(-1)
                        toast.success(response?.data?.message)
                    }
                },
                (error: any) => {
                    toast(error.message)
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.error) ||
                        error.message ||
                        error.toString()
                    setLoading(false)
                    setError(resMessage)
                }
            )
            .catch((error) => console.log(error))
    }

    const PhoneNumberField = () => {
        return (
            <div className="phone-form-control">
                <span className="country-code" aria-readonly>
                    +1
                </span>
                <Field name="phoneNumber">
                    {({ field, form }: any) => (
                        <>
                            <input
                                {...field}
                                type="tel"
                                className={`form-control`}
                                id="phoneInput"
                                onChange={(event) =>
                                    handlePhoneChange(event, form)
                                }
                            />
                        </>
                    )}
                </Field>
            </div>
        )
    }

    return (
        <div className="userview">
            <h2>Add sub vendor</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleAddUser}
            >
                <Form>
                    {addUserFormFields.map((field, index) => {
                        return (
                            <div className="form-group" key={index}>
                                <label htmlFor={field.name}>
                                    {field.label}
                                    {field?.required && (
                                        <span className="red-text">*</span>
                                    )}
                                </label>

                                {field.name === 'phoneNumber' ? (
                                    <PhoneNumberField />
                                ) : (
                                    <Field
                                        name={field.name}
                                        type="text"
                                        className="form-control"
                                    />
                                )}
                                <ErrorMessage
                                    name={field.name}
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>
                        )
                    })}
                    <div
                        className="form-group "
                        style={{
                            width: '100%',
                            display: 'flex',
                        }}
                    >
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                        >
                            Add Sub Vendor
                        </button>
                    </div>

                    {error && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        </div>
                    )}
                </Form>
            </Formik>
        </div>
    )
}

export default AddUserForm
