import customAxios from '../axios'

const API_URL = `${process.env.REACT_APP_API_URL}`

class UserService {
    getPublicContent() {
        return customAxios.get(API_URL + 'all')
    }

    getUserBoard() {
        return customAxios.get(API_URL + 'user')
    }

    getModeratorBoard() {
        return customAxios.get(API_URL + 'mod')
    }

    getAdminBoard() {
        return customAxios.get(API_URL + 'admin')
    }

    edituserInfo(
        firstname: string,
        lastname: string,
        phone: string,
        email: string,
        address: string,
        zip: string,
        emailNotify: boolean,
        phoneNotify: boolean,
        businessname: string,
        website: string,
        onlineVendor: boolean
    ) {
        return customAxios.post(API_URL + 'updateProfile', {
            firstName: firstname,
            lastName: lastname,
            phone,
            email,
            address,
            zip,
            emailNotify,
            phoneNotify,
            businessname,
            website,
            online_vendor: onlineVendor
        })
    }
    deleteUser(email: string) {
        return customAxios.post(API_URL + 'updateProfile', {
            email,
            userStatus: false,
        })
    }

    getuserInfo(email: string) {
        return customAxios.get(API_URL + 'fetchUser/' + email)
    }

    getuserInfoWithCognitoId(cognitoUserId: string) {
        return customAxios.post(API_URL + 'fetchUser', {
            cognitoUserId,
        })
    }

    getuserListing(page: number, type: any) {
        const payload = { params: { showUser: type } };
        return customAxios.get(API_URL + 'listing/user/?page=' + page + '&pageSize=all', payload)
    }

    async getUnReadNotification(email: string) {
        let res: any = null
        try {
            res = await customAxios.post(
                API_URL + 'getUnreadNotification',
                {
                    emailId: email,
                },
            )
            return res?.data
        } catch (err: any) {
            return err?.response?.data
        }
    }

    async getLimboNotification(email: string) {
        let res: any = null
        try {
            res = await customAxios.post(API_URL + 'listAllNotification', {
                emailId: email,
            })
            return res?.data
        } catch (err: any) {
            return err?.response?.data
        }
    }

    searchResults(query: string) {
        return customAxios.get(
            API_URL +
            'listing/getListingGlobalSearchResult?page=1&pageSize=5&filterApplied=' +
            query +
            '&sortKey=listingName&sortDirection=descending&includeDetails=0'
        )
    }

    updateChatReadStatus(notificationId: string) {
        return customAxios.post(API_URL + 'updateChatReadStatus', {
            chatMessageId: notificationId,
        })
    }
    updateLimboReadStatus(notificationId: string) {
        return customAxios.post(API_URL + 'updateLimboReadStatus', {
            notificationId: notificationId,
        })
    }
    updateUserLocation(
        emailId: string,
        latitude: number,
        longitude: number,
        state: string,
        city: string,
        district: string,
        zip: number
    ) {
        return customAxios.post(API_URL + 'updateUserLocation', {
            emailId,
            latitude,
            longitude,
            state,
            city,
            district,
            zip,
        })
    }

    getUserCategories() {
        return customAxios.get(API_URL + 'category/data/userDefinedField',)
    }
    importUserCategory(id: string) {
        return customAxios.post(API_URL + 'category/importdData/' + id)
    }
    deleteUserCategory(id: string) {
        return customAxios.post(API_URL + 'category/data/userDefinedField/updateStatus', {
            id: id
        })

    }
    updateUserCategory(emailId: string, categories: string[]) {
        return customAxios.post(API_URL + '/user/updateCategories', {
            emailId,
            categories
        })

    }

    getPendingAds() {
        return customAxios.get(API_URL + 'admin/pendingAds')
    }

    getRejectedAds(userId: any, email: any) {
        return customAxios.post(API_URL + '/admin/getRejectedAdsNotification', {
            userId: userId, email: email
        })
    }

    updateAdsReadStatus(notificationId: string) {
        return customAxios.post(API_URL + '/admin/updateAdsReadStatus', {
            notificationId: notificationId,
        })
    }

    getPlaces(searchTerm: string) {
        return customAxios.post(API_URL + `/places`, {
            searchTerm: searchTerm
        })
    }

    getPlaceLatLong(placeId: string) {
        return customAxios.post(API_URL + `/placeLatLong`, {
            placeId: placeId
        })
    }

}

export default new UserService()
