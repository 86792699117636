import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router'
import {
    searchStart,
    searchSuccess,
    searchFailure,
    selectSearchResults,
} from '../../redux/searchSlice'
import UserService from '../../api/services/user.service'
import noImg from '../../assets/images/no-image.png'
import './Search.scss'

const SearchPage = () => {
    const results = useSelector(selectSearchResults)
    const queryString = window.location.search
    const params = new URLSearchParams(queryString)
    const paramNameValue = params.get('search')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        handleSearch()
    }, [paramNameValue])

    const handleSearch = () => {
        if (!paramNameValue) {
            // Don't search if the query is empty
            return
        }

        dispatch(searchStart())
        UserService.searchResults(paramNameValue).then(
            (response: any) => {
                response &&
                    response?.data &&
                    dispatch(searchSuccess(response?.data))
                if (response?.data?.length == 0) {
                    throw new Error(response)
                }
            },
            (error: any) => {
                console.error('Error fetching data:', error)
                dispatch(searchFailure())
            }
        )
    }

    return (
        <div className="searchPage">
            {results.data && results.data.length > 0 ? (
                <>
                    {results.data.map((result: any) => (
                        <div className="searchrow" key={result._id} onClick={() =>
                            navigate(`/listing/${result._id}`)
                        }>
                            <div className="searchImg">
                                {result.images.length > 0 ? (
                                    <img src={result.images[0]} />
                                ) : (
                                    <img src={noImg} alt="img" />
                                )}
                            </div>
                            <div className="searchDetail">
                                <h4>
                                    <a
                                    >
                                        {result.listingName}
                                    </a>
                                </h4>
                                <p>{result.description}</p>
                            </div>
                        </div>
                    ))}
                </>
            ) : (
                <div className="nodata">No data found</div>
            )}
        </div>
    )
}

export default SearchPage
