import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
    getListingList,
    updateListingStatus,
} from '../../../../api/services/listing.service'
import { MDBDataTable } from 'mdbreact'
import { listingHead } from '../../../../constant/constant'
import { IoTrashOutline } from 'react-icons/io5'
import { getIconClassName } from '../../../../helper/utils'
import Swal from 'sweetalert2'
import Loader from '../../../../components/Loader/Loader'
import { FaEye } from 'react-icons/fa'
import { getFirst100Words } from '../../../../helper/utils'
const ListingList = () => {
    const [data, setdata] = useState<any>()
    const [error, seterror] = useState('')
    const [loading, setloading] = useState(false)
    const [filterType, setFilterType] = useState('')
    const navigate = useNavigate()

    const disableListing = async (id: any) => {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: 'To change the status',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const result = await updateListingStatus(id, {
                        status: 'inactive',
                    })
                    getListing()
                    Swal.fire({
                        title: 'Changes!',
                        text: 'Status changed successfully.',
                        icon: 'success',
                    })
                }
            })
        } catch (error: any) {
            seterror(error.message)
        }
    }
    const getListing = async () => {
        setloading(true)
        try {
            const result = await getListingList(filterType)
            setloading(false)
            setdata({
                columns: listingHead,
                rows: result.data.map((row: any, index: any) => {
                    return {
                        ...row,
                        description: (
                            <>{getFirst100Words(row.description, 10)}</>
                        ),
                        status: (
                            <div className={getIconClassName(row.status)}>
                                {row.status}
                            </div>
                        ),
                        action: (
                            <div className="icon__wrapper">
                                <IoTrashOutline
                                    title="disable listing"
                                    onClick={() => disableListing(row._id)}
                                />
                                <FaEye
                                    title="view details"
                                    onClick={() =>
                                        navigate(`/listing/${row._id}`)
                                    }
                                />
                            </div>
                        ),
                    }
                }),
            })
        } catch (error: any) {
            seterror(error.message)
            setloading(false)
        }
    }
    useEffect(() => {
        getListing()
    }, [])
    useEffect(() => {
        getListing()
    }, [filterType])
    const handleChange = (e: any) => {
        setFilterType(e.target.value)
    }
    const role = localStorage.getItem('userRole')

    if (loading) {
        return (
            <div className="loader__table">
                <Loader />
            </div>
        )
    }
    return (
        <div className="dashboard-listing-list admin__table">
            {(role === '"vendor-service-provider"' ||
                role === '"vendor-sub-user"') && (
                <div className="btn-row">
                    <Link to={`/start-listing`} className="btn-orange">
                        Create Listing
                    </Link>
                </div>
            )}
            <div className="filterdropdown">
                <p>Filter by</p>
                <select value={filterType} onChange={handleChange}>
                    <option value="">Select...</option>
                    <option value="product">Product</option>
                    <option value="service">Service</option>
                </select>
            </div>
            {error && (
                <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                </div>
            )}
            <MDBDataTable
                small
                data={data}
                className="responsive-table"
                responsive
                sortable={false}
            />
        </div>
    )
}

export default ListingList
