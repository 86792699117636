import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import '../../pages/ForgotPassword/forgotPassword.scss'
import SubscriptionPlans from '../../api/services/subscription.service'
import adsService from '../../api/services/ads.service'
import { FaHome } from 'react-icons/fa'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

const PaymentSuccess = () => {
    const navigate = useNavigate()
    const { search } = useLocation()
    const searchParamas = new URLSearchParams(search)
    const paymentId = searchParamas.get('payment_id')
    const product = searchParamas.get('product')
    const [error, seterror] = useState<any>()
    const [loading, setloading] = useState(false)
    const [paymentSuccess, setpaymentSuccess] = useState(false)

    useEffect(() => {
        const checkPayment = async () => {
            if (product === 'Ad') {
                setloading(true)
                try {
                    const res = await adsService.adsPayment(paymentId)

                    setpaymentSuccess(true)
                    if (res.data.success) {
                        toast('payment success')
                        // navigate("/dashboard/ads")
                        setloading(false)
                    } else {
                        toast('payment pending')
                    }
                } catch (error: any) {
                    toast('payment failed')
                    seterror(error.message)
                    setloading(false)
                }
            }
            if (product === 'ShopperSubscription') {
                setloading(true)
                try {
                    const res = await SubscriptionPlans.checkShopperSubsPayment(
                        paymentId
                    )
                    setpaymentSuccess(true)
                    console.log(res)

                    if (res.data.success) {
                        // navigate("/dashboard/ads")
                    }
                    setloading(false)
                } catch (error: any) {
                    seterror(error.message)
                    setloading(false)
                }
            }
        }

        checkPayment()
    }, [])

    return (
        <div className="registerpage">
            <div className="registerWrap">
                <div className="registerLeft">
                    <img
                        src={process.env.PUBLIC_URL + '/images/sucessImg.png'}
                        alt="sucessImg"
                    />
                </div>
                <div className="registerRight">
                    {error?.length ? (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="registerpage__text">
                                <span>PAYMENT</span>
                                <span>successful</span>
                            </div>
                            <div className="form-group">
                                {loading && (
                                    <span className="spinner-border spinner-border-sm loader"></span>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <Button variant="primary" onClick={() => navigate('/')}>
                    HOME <FaHome />
                </Button>
            </div>
        </div>
    )
}
export default PaymentSuccess
