import React from 'react'
import { cancelImg } from '../../helper/iconPath'
import { Button } from 'react-bootstrap';
import { FaHome } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const PaymentCancel = () => {
    const navigate = useNavigate()
    return (
        <div className='payment__cancel__wrapper'>
            <div className='payment__cancel'>
                <div className='payment__cancel__text'>
                    <span>PAYMENT</span>
                    <span>CANCELLED</span>
                </div>
                <img src={cancelImg} alt="" />
            </div>
            <Button variant="primary" onClick={()=>navigate("/")}>HOME <FaHome /></Button>
        </div>
    )
}

export default PaymentCancel
