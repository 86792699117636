import Modal from 'react-bootstrap/Modal'
import { Form } from 'react-bootstrap'
import React, { useState } from 'react'
import { Button, Modal as BootstrapModal } from 'react-bootstrap'
import './modal.scss'
import { MdDelete } from 'react-icons/md'

export const ModalComp = ({
    title,
    desc,
    onSubmit,
    show,
    submitText,
    setshow,
    className,
}: any) => {
    return (
        <Modal
            show={show}
            onHide={() => setshow(false)}
            className={`${className}`}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{desc}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setshow(false)}>
                    Close
                </Button>
                <Button variant="primary" onClick={onSubmit}>
                    {submitText}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export const ReasonIinput = ({ reason, setreason }: any) => {
    return (
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Reason</Form.Label>
            <Form.Control
                type="text"
                placeholder="please enter the reason for report"
                onChange={(e) => setreason({ text: e.target.value })}
            />
            {reason.error && (
                <div className="errorText">please enter the reason</div>
            )}
        </Form.Group>
    )
}

interface ModalProps {
    handleDelete: () => void
}

const CustomModal: React.FC<ModalProps> = ({ handleDelete }) => {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const handleDeleteClick = () => {
        handleDelete()
        handleClose()
    }

    return (
        <>

            <MdDelete size={30}  onClick={handleShow} />
            <BootstrapModal show={show} onHide={handleClose}>
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>Remove List</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    Do you want to remove this List?
                </BootstrapModal.Body>
                <BootstrapModal.Footer>
                    <Button
                        variant="btn btn-report"
                        onClick={handleDeleteClick}
                    >
                        Yes
                    </Button>
                    <Button variant="btn" onClick={handleClose}>
                        No
                    </Button>
                </BootstrapModal.Footer>
            </BootstrapModal>
        </>
    )
}

export default CustomModal
