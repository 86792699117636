import React from 'react'
import { useNavigate } from 'react-router-dom';
import '../ForgotPassword/forgotPassword.scss'
const Success = () => {
    const navigate = useNavigate();
    return (
        <div className="registerpage">
            <div className="registerWrap">
                <div className="registerLeft">
                    <img
                        src={process.env.PUBLIC_URL + '/images/sucessImg.png'}
                        alt="sucessImg"
                    />
                </div>
                <div className="registerRight">
                    <h3>Congratulations</h3>
                    <p>Your account has been verified.</p>
                    <div className="form-group">
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            onClick={() => navigate('/login')}
                        >
                            Done
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Success
