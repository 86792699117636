import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router'
import './category.scss'
import { END_POINTS } from '../../api/domain'
import authHeader from '../../api/services/auth-header'

const CategoryList = () => {
    const [category, setCategory] = useState<any>([])
    const navigate = useNavigate()

    useEffect(() => {
        const getCategory = () => {
            axios
                .get(`${END_POINTS.getCategory}?source=limbo`, {
                    headers: authHeader(),
                })
                .then((response) => {
                    // Handle the successful response
                    setCategory(response.data)
                })
                .catch((error) => {
                    // Handle errors
                    console.error('Error:', error)
                })
        }
        getCategory()
    }, [])

    const handleCategory = (item: any) => {
        navigate(`/start-limbo/${item?._id}`)
    }

    if (!category) {
        return <p> No data found</p>
    }

    return (
        <div className='container'> 
        <h3 className='margintop30'>Select Category to proceed 
        <small>(You will get notification for selected category when a limbo is created in that category)</small>
        </h3>
        <div className="category-limbo">
            {category?.map((item: any) => {
                return (
                    <div
                        onClick={() => handleCategory(item)}
                        key={item?.limboTypeId}
                        className="category-card"
                    >
                        <img src={item?.limboImage} alt="image" />
                        <h3> {item?.limboName}</h3>
                    </div>
                )
            })}
        </div>
        </div>
    )
}

export default CategoryList
