import { configureStore } from '@reduxjs/toolkit'
import searchReducer from './searchSlice'
import toastReducer from './toastSlice'
import notificationReducer from './notificationSlice'
import userReducer from './userSlice'
import imgUploadReducer from './imgUploadSlice'
import favouriteReducer from './favouriteSlice'

const store = configureStore({
    reducer: {
        search: searchReducer,
        toast: toastReducer,
        notification: notificationReducer,
        user: userReducer,
        imgUpload: imgUploadReducer,
        favourite: favouriteReducer,
    },
})

export default store
