import React, { useEffect, useState } from 'react'
import { getBannerImage } from '../../helper/utils'

const Ads = ({ slot }: any) => {
    const [bannerImage, setbannerImage] = useState("")
    useEffect(() => {
        const getImg = async () => {
            const url = await getBannerImage(slot)
            setbannerImage(url)
        }
        getImg()
    }, [])

    return (
        <div>
            {
                !!bannerImage &&
                <img src={bannerImage} alt="ads" />
            }
        </div>
    )
}

export default Ads
