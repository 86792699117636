import customAxios from "../axios";
import { END_POINTS } from "../domain";
import authHeader from "./auth-header";

class SubscriptionPlans {
    getSubscriptionPlans() {
        return customAxios.get(END_POINTS.subscriptions,)
    }
    createShopperSubs(planId: any, sessionId: any,sub:any) {
        return customAxios.post(
            END_POINTS.shopperSubs,
            {
                planId,
                sessionId,
                sub
            },

        )
    }
    checkShopperSubsPayment(sessionId: any) {
        return customAxios.post(
            END_POINTS.checkSubsPayment,
            {
                sessionId
            },

        )
    }
    checkUserSubscription() {
        return customAxios.get(
            END_POINTS.userSubscription,
        )
    }
}

export default new SubscriptionPlans;