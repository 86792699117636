import { MDBDataTable } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import SubscriptionPlans from '../../../../api/services/subscription.service'
import { subscriptionHeaders } from '../../../../constant/constant'
import Loader from '../../../../components/Loader/Loader'

const ManageSubscription = () => {
    const [successful, setSuccessful] = useState(false)
    const [tableData, settableData] = useState<any>()
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState('')

    useEffect(() => {
        const getSubs = async () => {
            setloading(true)
            try {
                const plans = await SubscriptionPlans.getSubscriptionPlans()
                setloading(false)
                settableData({
                    rows: plans.data.map((row: any, index: number) => {
                        return {
                            ...row,
                            status: row.isActive ? 'Active' : 'InActive',
                            price: `${process.env.REACT_APP_CURRENCY_SIGN}$ ${row.price}`,
                        }
                    }),
                    columns: subscriptionHeaders,
                })
            } catch (error: any) {
                setloading(false)
                seterror(error.message)
            }
        }
        getSubs()
    }, [])

    if (loading) {
        return (
            <div className="loader__table">
                <Loader />
            </div>
        )
    }
    return (
        <div className="tableView admin__table">
            {error?.length ? (
                <div className="alert alert-danger">{error}</div>
            ) : (
                <MDBDataTable
                    small
                    data={tableData}
                    className="responsive-table"
                    responsive
                />
            )}
        </div>
    )
}

export default ManageSubscription
