import customAxios from '../axios'
const API_URL = `${process.env.REACT_APP_API_URL}`
export const getOffers = (id: any) => {
    return customAxios.get(`${API_URL}getoffers/${id}`)
}

export const createOffers = (limboId: any, offerPrice: any) => {
    return customAxios.post(`${API_URL}createOffer`, {
        limboId,
        offerPrice,
    })
}

export const updateOffers = (limboId: any, offerPrice: any, offerId: any) => {
    return customAxios.put(`${API_URL}updateOffer`, {
        limboId,
        offerPrice,
        offerId,
    })
}

export const handshakeOffer = (offerId: any) => {
    return customAxios.put(`${API_URL}handshake`, {
        offerId,
    })
}
