import React, { useEffect, useState } from 'react'
import {
    getLimbosList,
    updateLimboStatus,
} from '../../../../api/services/limbo.service'
import { MDBDataTable } from 'mdbreact'
import { LimbosHead } from '../../../../constant/constant'
import { IoTrashOutline } from 'react-icons/io5'
import { getClassName, getIconClassName } from '../../../../helper/utils'
import { getFirst100Words } from '../../../../helper/utils'
import Swal from 'sweetalert2'
import Loader from '../../../../components/Loader/Loader'
import { FaEye } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

const LimboListUser = () => {
    const [data, setdata] = useState<any>()
    const [error, seterror] = useState('')
    const [loading, setloading] = useState(false)
    const [filterType, setFilterType] = useState('')
    const navigate = useNavigate()

    const disableListing = async (id: any) => {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Change the status to disable',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const res = await updateLimboStatus(id, {
                        status: 'inactive',
                    })
                    getLimbos()
                    Swal.fire({
                        title: 'Changed',
                        text: 'Status changed successfully.',
                        icon: 'success',
                    })
                }
            })
        } catch (error: any) {
            seterror(error.message)
        }
    }

    const getLimbos = async () => {
        setloading(true)
        try {
            const res: any = await getLimbosList(filterType)

            if (res) {
                setloading(false)
                setdata({
                    columns: LimbosHead,
                    rows: res?.data?.data?.map((row: any, index: any) => {
                        return {
                            ...row,
                            description: (<>{getFirst100Words(row.description, 10)}</>),

                            status: (
                                <div className={getIconClassName(row.status)}>
                                    {row.status}
                                </div>
                            ),
                            action: (
                                <div
                                    className={`icon__wrapper ${getClassName(
                                        row.status
                                    )}`}
                                >
                                    <IoTrashOutline
                                    title='disable listing'
                                        onClick={() => disableListing(row._id)}
                                    />
                                    <FaEye
                                        title="view details"
                                        onClick={() =>
                                            navigate(`/limbo-detail/${row._id}`)
                                        }
                                    />
                                </div>
                            ),
                        }
                    }),
                })
            }
        } catch (error: any) {
            setloading(false)
            seterror(error.message)
        }
    }
    useEffect(() => {
        getLimbos()
    }, [])

    useEffect(() => {
        getLimbos()
    }, [filterType])

      const handleChange = (e: any) => {
        setFilterType(e.target.value);
      };
    if (loading) {
        return (
            <div className="loader__table">
                <Loader />
            </div>
        )
    }
    return (
        <div className="limbos__table admin__table">
            <div className='filterdropdown'>
      <p>Filter by</p>
      <select value={filterType} onChange={handleChange}>
        <option value="">Select...</option>
        <option value="product">Product</option>
        <option value="service">Service</option>
      </select>
      
    </div>
            {error && (
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            )}
            <MDBDataTable
                small
                data={data}
                className="responsive-table"
                responsive
                data-mdb-filter="limboType"
                searching={true}
            />
        </div>
    )
}

export default LimboListUser
