import React, { useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { useNavigate } from 'react-router'
import './dashBoardOwlCarousel.scss'

const DashboardOwlCarousel = ({ data }: any) => {
    const navigate = useNavigate()

    useEffect(() => {
        $('.owl-carousel').owlCarousel({
            loop: true,
            margin: 10,
            dotsEach: true,
            nav: true,
            autoplay: true,
            autoplayTimeout: 5000,
            smartSpeed: 1200,
        })
    }, [])

    return (
        <div>
            <div className="container">
                <OwlCarousel
                    className="owl-carousel owl-theme"
                    loop
                    nav
                    margin={15}
                    items={5}
                    autoplay={true}
                    autoplayTimeout={5000}
                    smartSpeed={1200}
                    responsive={{
                        0: {
                            items: 1,                           
                            dotsEach: false,
                        },
                        600: {
                            items: 3,
                            nav: true,
                        },
                        1000: {
                            items: 5,
                            nav: true,
                            loop: true,
                        },
                    }}
                >
                    {data.map((item: any, idx: any) => (
                        <div
                            className="item"
                            key={idx}
                            onClick={() =>
                                navigate(`/listing-list/${item?._id}`)
                            }
                        >
                            <ul>
                                <li>
                                    <img src={item?.limboImage} alt="product" />
                                    <p>{item.limboName}</p>
                                </li>
                            </ul>
                        </div>
                    ))}
                </OwlCarousel>
            </div>
        </div>
    )
}

export default DashboardOwlCarousel
