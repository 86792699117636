// searchSlice.js
import { createSlice } from '@reduxjs/toolkit'

const favouriteSlice = createSlice({
    name: 'favourite',
    initialState: {
        favouriteList: [],
    },
    reducers: {
        addToFavouriteList: (state, action) => {
            state.favouriteList.push(action.payload)
        },
    },
})

export const { addToFavouriteList } = favouriteSlice.actions
export default favouriteSlice.reducer
