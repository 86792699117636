import React from 'react'
import PageHeader from '../../components/PageHeader/PageHeader'

const About = () => {
    return (
        <div className="staticPage">
            <PageHeader title="About Us" />
            <div className="staticContent">
                <h4>Welcome to Price Limbo!</h4>
                <p>
                    In today&#39;s fast-paced, technology-driven society, where
                    maximizing the value of our hard-earned dollars is crucial,
                    navigating the unpredictable landscape of price fluctuations
                    has become increasingly challenging. Global conflicts,
                    soaring gas prices, raw material shortages, and inflation
                    rates have collectively diminished our buying power. Now,
                    more than ever, consumers are fervently seeking one thing –
                    the Best Price!
                </p>
                <p>
                    Have you, as a consumer, ever made a purchase during a sale
                    only to discover later that you could have paid
                    significantly less for the exact same item? This paradox can
                    be attributed to various factors, a few of which are
                    outlined below.
                </p>
                <ol>
                    <li>
                        Many small businesses may lack flashy websites, radio or
                        television advertisements, or prominent traditional
                        storefronts in busy locations. Despite these apparent
                        limitations, these businesses often offer competitive or
                        better prices, benefiting from lower advertising costs
                        and streamlined business expenses.
                    </li>
                    <li>
                        Numerous commission-based salespersons in various
                        industries prioritize building a good, honest reputation
                        as the key to success. Their focus centers on pleasing
                        customers through transparent pricing and personalized
                        customer service, aiming to grow sales through referrals
                        from satisfied clients.
                    </li>
                    <li>
                        Retailers and dealers often receive bonuses based on
                        monthly, quarterly, and yearly sales figures. Even a
                        single additional sale can result in a substantial
                        bonus, providing a powerful incentive to offer customers
                        lower prices.
                    </li>
                </ol>
                <p>
                    For business owners and commission-based salespeople, the
                    struggle against limitations like business location,
                    advertising visibility, and cold-calling frustrations is all
                    too familiar. Imagine the appeal of customers who already
                    know exactly what they want, requiring minimal explanation
                    or product demos. Here are a few scenarios to consider.
                </p>
                <ul>
                    <li>
                        A consumer, well-researched and ready to purchase
                        appliances or furniture, only requires the best price
                        from you. No need for the typical stalking-like sales
                        approach - just provide the best offer.
                    </li>
                    <li>
                        A customer looking for a specific motor vehicle,
                        well-informed through dealership visits, referrals, or
                        online searches. They know the details; all they need is
                        the best price, saving time on test drives and feature
                        explanations.
                    </li>
                    <li>
                        A home renovator with a clear list of required building
                        materials seeks the best price from your hardware store.
                        Your store may lack the recognition of a big-box
                        competitor, but you have the opportunity to offer the
                        best deal.
                    </li>
                </ul>
                <p>
                    Consumers and clients today are pressed for time and
                    patience, unwilling to spend countless hours searching for
                    the best prices and product availability. Similarly,
                    businesses and salespersons prefer decisive customers who
                    know what they want, avoiding prolonged demos and
                    negotiations that may lead to frustration or changing
                    preferences.
                </p>
                <strong
                    style={{
                        fontSize: '18px',
                        paddingBottom: '15px',
                        paddingRight: '5px',
                    }}
                >
                    Price Limbo
                </strong>
                is an innovative platform which connects consumers and clients
                with businesses and salespersons from traditional storefronts
                and web-based stores. Let the limbo begin!
            </div>
        </div>
    )
}

export default About
