import React, { useEffect, useState } from 'react'
import { commonFormFields, initialValues } from './constant'
import { useParams } from 'react-router'
import '../../pages/Limbo/limboform.scss'
import {
    getCategoryById,
    getFormFieldValues,
} from '../../api/services/category.service'
import {
    getListingById,
    saveListing,
    updateListing,
} from '../../api/services/listing.service'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button } from 'react-bootstrap'
import CategoryFormFields from '../../helper/CategoryFormFields'
import FormFields from '../../helper/FormFields'

const ListingForm = () => {
    const [formData, setFormData] = useState<any>(initialValues)
    const [listingData, setlistingData] = useState<any>([])
    const [images, setImages] = useState<any>([])
    const [createListngForm, setCreateListingForm] = useState<any>({})
    const [parsedListingJson, setParsedListingJson] = useState<any>([])
    const [parentLimboFieldId, setParentLimboFieldId] = useState<any>(null)
    const { id } = useParams()
    const [options, setOptions] = useState<any>([])
    const naviagte = useNavigate()
    const [date, setDate] = useState({
        startDate: new Date(),
        endDate: new Date(),
    })
    const [searchParams] = useSearchParams()
    const [title, setTitle] = useState<any>({})

    const type = searchParams.get('type') || 'start'

    const onImageUpload = (data: any) => {
        setImages(data)
    }

    const handleCheckbox = (event: any, item: any) => {
        const { limboFieldId, limboFieldName, fieldDataType, displayOrder } =
            item
        const isChecked = event.target.checked

        // Find if the item is already present in listingData
        const isItemInListingData = listingData.some(
            (data: any) => data.listingFieldId === limboFieldId
        )

        if (isChecked && !isItemInListingData) {
            // Add the item to listingData if it's checked and not already present

            setlistingData((prev: any) => [
                ...prev,
                {
                    listingFieldId: limboFieldId,
                    listingFieldName: limboFieldName,
                    fieldValueId: null,
                    fieldValue: 'Yes',
                    fieldDataType: fieldDataType,
                    displayOrder: displayOrder,
                },
            ])
        } else if (!isChecked && isItemInListingData) {
            // Remove the item from listingData if it's unchecked and already present
            setlistingData((prev: any) =>
                prev.filter((data: any) => data.listingFieldId !== limboFieldId)
            )
        }

        // Toggle visibility based on checkbox state
        const dependentChildField = parsedListingJson.find(
            (i: any) => i?.limboFieldId === limboFieldId
        )
        const dependentChildLimboFieldId =
            dependentChildField?.childLimboFieldId[0]
        const element = document.getElementById(dependentChildLimboFieldId)

        if (element) {
            element.style.display = isChecked ? 'block' : 'none'
        }
    }

    //api call to get form field values
    const getFieldValues = (fieldId: any, listingId: any, temp: any) => {
        setParentLimboFieldId(fieldId)

        getFormFieldValues(fieldId, listingId)
            .then((response) => {
                // Check if the item with the same id already exists in the options array
                const itemExists = options.some(
                    (item: any) => item.id === listingId
                )

                // If the item with the same id doesn't exist, add it to the options array
                if (!itemExists) {
                    setOptions((prev: any) => [
                        ...prev,
                        {
                            id: listingId,
                            data: response.data,
                            fieldId: fieldId,
                            action: temp.childTriggerAction,
                            parentFieldValue: temp?.parentFieldValue,
                        },
                    ])
                } else {
                    // If the item with the same id already exists, update its values
                    setOptions((prev: any) =>
                        prev.map((item: any) =>
                            item.id === listingId
                                ? {
                                      ...item,
                                      data: response.data,
                                      fieldId: fieldId,
                                      action: temp.childTriggerAction,
                                      parentFieldValue: temp?.parentFieldValue,
                                  }
                                : item
                        )
                    )
                }
            })
            .catch((error) => {
                console.log('Error:', error)
            })
    }

    //handle input field on change
    const handleOnChange = (name: any, value: any) => {
        setFormData((prev: any) => ({ ...prev, [name]: value }))
    }

    //handling limbofields
    const handleLimboField = (
        id: any,
        name: any,
        fieldValueId: any,
        fieldValue: any,
        fieldDataType: any,
        displayOrder: any,
        type: any
    ) => {
        //check if item alreday exits
        const itemExists = listingData.some(
            (item: any) => item.listingFieldId === id
        )

        if (!itemExists) {
            setlistingData((prev: any) => [
                ...prev,
                {
                    listingFieldId: id,
                    listingFieldName: name,
                    fieldValueId: parseInt(fieldValueId),
                    fieldValue: fieldValue,
                    fieldDataType: fieldDataType,
                    displayOrder: displayOrder,
                    ...(type === 'Combo' || type === 'Dropdown'
                        ? { parentLimboFieldId: parentLimboFieldId }
                        : {}),
                    ...(typeof fieldValueId === 'string' && type === 'Combo'
                        ? { userDefinedFieldValue: true }
                        : {}),
                },
            ])
        } else {
            // If the item with the same id already exists, update its values
            setlistingData((prev: any) =>
                prev.map((nitem: any) =>
                    nitem.listingFieldId === id
                        ? {
                              ...nitem,
                              fieldValueId: parseInt(fieldValueId),
                              fieldValue: fieldValue,
                              fieldDataType: fieldDataType,
                              ...(type === 'Combo' || type === 'Dropdown'
                                  ? { parentLimboFieldId: parentLimboFieldId }
                                  : {}),
                              ...(typeof fieldValueId === 'string' &&
                              type === 'Combo'
                                  ? { userDefinedFieldValue: true }
                                  : {}),
                          }
                        : nitem
                )
            )
        }
    }

    //on form submission
    const handleSubmit = (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        // Validate form fields
        if (
            !createListngForm.limboName ||
            !createListngForm.limboType ||
            !formData.description ||
            !formData.price ||
            !formData.address ||
            !formData.city ||
            !formData.postalCode ||
            !listingData
        ) {
            toast('Please fill in all fields', {
                type: 'warning',
                autoClose: 2000,
                toastId: 'customId',
            })

            return // Stop execution if any field is empty
        }

        //call api to save limbos

        const payload = {
            listingTypeId: id,
            listingName: createListngForm.limboName,
            listingType: createListngForm.limboType,
            description: formData.description,
            price: formData.price?.replace(/\D/g,''),
            images: images,
            location: 0,
            address: formData.address,
            city: formData.city,
            postalCode: formData.postalCode,
            listingDetail: listingData,
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        saveListing(payload)
            .then((response) => {
                if (response.status === 200) {
                    // API call was successful (status code 200)
                    toast(`${response.data.message}`, {
                        type: 'success',
                        autoClose: 2000,
                        toastId: 'customId',
                    })
                    naviagte('/')
                } else {
                    // API call was not successful, handle the error
                    toast(`${response.status}`, {
                        type: 'error',
                        autoClose: 2000,
                        toastId: 'customId',
                    })
                }
            })
            .catch((error) => {
                // Handle errors that occurred during the API call
                console.log(error)

                if (error?.response) {
                    toast(`${error.response.data.message}`, {
                        type: 'warning',
                        autoClose: 2000,
                        toastId: 'customId',
                    })
                } else {
                    toast(`${error}`, {
                        type: 'warning',
                        autoClose: 2000,
                        toastId: 'customId',
                    })
                }
            })
            .catch((error: any) => {
                if (error.message) {
                    toast.error(error.message || 'An unexpected error occurred')
                }
                console.log(error.message)

                // alert('Catch Block: ' + error);
            })
    }

    //callback is received when dropdwon item is selected
    const onItemSelect = (item: any, id: any) => {
        const selectedItem = parsedListingJson.filter(
            (item: any) => item?.limboFieldId === id
        )

        getFieldValues(
            item?.fieldValueId,
            selectedItem[0]?.childLimboFieldId[0],
            selectedItem[0]
        )
    }

    //to get placeholder text for text box fields

    const getPlaceholderText = (field: any) => {
        const emptyText = options?.filter(
            (item: any) => item?.data?.length === 0
        )
        const emptyEle = parsedListingJson.find(
            (elem: any) => elem?.limboFieldId === emptyText[0]?.id
        )

        if (field?.defaultPlaceholder === 'null') {
            const placeholderText = options?.filter((item: any) => {
                return (
                    Array.isArray(item.action) &&
                    item.action.length > 0 &&
                    item.action[0] === 'GetPlaceholderText'
                )
            })
            return placeholderText[0]?.data[0]?.fieldValue || 'Enter details '
        } else if (emptyEle && field?.limboFieldType === 'Combo') {
            return emptyEle?.parentFieldValue
        } else {
            return field?.defaultPlaceholder
        }
    }

    const getButton = () => {
        const listingFieldsFilled = parsedListingJson.every((field: any) => {
            return (
                listingData.find(
                    (data: any) => data.listingFieldId === field.limboFieldId
                )?.fieldValue !== ''
            )
        })

        const commonFieldsFilled = commonFormFields.every((field) => {
            // Exclude image field from the check
            if (field.name === 'images') return true

            return (
                formData[field.name] !== '' // Check if field is not an empty string
            )
        })

        return listingFieldsFilled && commonFieldsFilled
    }

    const [updateId, setUpdateId] = useState<any>('')

    const hanldeUpdate = (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        const payload = {
            listingTypeId: updateId,
            listingName: createListngForm.limboName,
            listingType: createListngForm.limboType,
            description: formData.description,
            price: formData.price?.replace(/\D/g,''),
            images: images,
            location: 0,
            address: formData.address,
            city: formData.city,
            postalCode: formData.postalCode,
            listingDetail: listingData,
        }

        updateListing(id, payload)
            .then((response) => {
                if (response.status == 200) {
                    toast(`${response.data.message}`, {
                        type: 'success',
                        autoClose: 2000,
                        toastId: 'customId',
                    })
                    naviagte(`/dashboard/listings`)
                } else {
                    toast(`${response.data.message}`, {
                        type: 'error',
                        autoClose: 2000,
                        toastId: 'customId',
                    })
                }
            })
            .catch((error) =>
                toast(`${error}`, {
                    type: 'error',
                    autoClose: 2000,
                    toastId: 'customId',
                })
            )
    }

    const getDropdownOptions = (field: any) => {
        const array = field?.parentFieldValue.split('|').map((item: any) => {
            return { fieldValue: item, fieldValueId: item }
        })

        return [
            {
                id: field?.limboFieldId,
                data: array,
                fieldId: field?.parenFieldId,
                action: field.childTriggerAction,
                parentFieldValue: field?.parentFieldValue,
            },
        ]
    }

    const formObject: any = {
        start: {
            onSubmit: handleSubmit,
            type: 'start',
            title: 'Start Listing',
        },
        update: {
            onSubmit: hanldeUpdate,
            type: 'update',
            title: 'Update Listing',
        },
    }

    useEffect(() => {
        if (type === 'update') {
            const getData = async () => {
                const response = await getListingById(id, 'includeDetails')

                const data = response?.data?.data[0]

                const schema = JSON.parse(response?.data?.schema?.limboJson)
                setParsedListingJson(schema)
                setUpdateId(schema._id)

                setTitle({
                    type: data?.listingType,
                    name: data?.listingName,
                })
                if (data) {
                    const { listingDetail, images, ...formData } = data

                    setFormData(formData)
                    setImages(images)
                    setlistingData(listingDetail)
                }
            }

            if (id) {
                getData()
            }
        }
    }, [id])

    //get form ui on component mount
    useEffect(() => {
        //api call to get form json

        const getFormDetails = async () => {
            try {
                const response = await getCategoryById(id)

                if (response?.data && response.data.length > 0) {
                    const jsonObject = JSON.parse(response.data[0]?.limboJson)
                    setCreateListingForm(response.data[0])
                    setTitle({
                        type: response.data[0]?.limboType,
                        name: response.data[0]?.limboName,
                    })
                    setParsedListingJson(jsonObject)
                } else {
                    // console.log('Error: Empty or invalid response data')
                }
            } catch (error) {
                console.log('Error fetching form details:', error)
            }
        }
        getFormDetails()
    }, [])

    //call form field values api for the initial mount
    useEffect(() => {
        if (parsedListingJson[0]?.limboFieldId) {
            getFieldValues(0, parsedListingJson[0]?.limboFieldId, {})
        }
    }, [id, parsedListingJson[0]?.limboFieldId])

    const filteredFields = parsedListingJson?.filter(
        (field: any) =>
            field?.display === 'Listing' || field?.display === 'Both'
    )

    // const updateDropdownValue = (selectedValue) => {
    //     setFormData((prev) => ({
    //         ...prev,
    //         [field.limboFieldName]: selectedValue,
    //     }))
    // }

    return (
        <div className="create-limbo-container">
            <div className="form-container">
                <div className="form-title">
                    <h3>
                        {title?.type} :{title?.name}
                    </h3>
                </div>
                <form
                    onSubmit={formObject[type]?.onSubmit}
                    className="limbo-form"
                >
                    {filteredFields?.map((field: any, idx: any) => {
                        return (
                            <CategoryFormFields
                                key={field?.limboFieldId}
                                field={field}
                                placeholder={getPlaceholderText(field)}
                                dropdwon={listingData}
                                options={
                                    field?.limboFieldType === 'Dropdown' &&
                                    field?.parentLimboFieldId === 'null'
                                        ? getDropdownOptions(field)
                                        : options
                                }
                                onItemSelect={onItemSelect}
                                handleLimboField={handleLimboField}
                                handleOnChange={handleOnChange}
                                handleCheckbox={handleCheckbox}
                                // updateFormState={updateDropdownValue}
                                date={date}
                                setDate={setDate}
                                // value={
                                //     listingData.find(
                                //         (i: any) =>
                                //             i?.listingFieldId ===
                                //             field?.limboFieldId
                                //     )?.fieldValue
                                // }
                            />
                        )
                    })}

                    {commonFormFields.map((field, key) => {
                        return (
                            <FormFields
                                field={field}
                                key={key}
                                handleOnChange={handleOnChange}
                                onImageUpload={onImageUpload}
                                awsFolderName="listing"
                                value={formData[field?.name]}
                            />
                        )
                    })}
                    <div className="button-group">
                        <Button type="submit" disabled={!getButton()}>
                            {formObject[type]?.type}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ListingForm
