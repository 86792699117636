import React from 'react'
import { Route, Routes } from 'react-router-dom'
import RequireAuth from '../utils/RequireAuth'

// unprotectedRoutes
import { auth_routes } from './unprotected.routes'
// protectedRoutes
import { general_routes } from './general.routes'

const AppRoutes = () => {
    const protectedRoutes = [...general_routes]

    const unprotectedRoutes = [...auth_routes]
    return (
        <Routes>
            {unprotectedRoutes.map((e) => {
                return <Route key={e.path} path={e.path} element={e.ele} />
            })}

            {protectedRoutes.map((e: any) => {
                const childroute = e.child_routes ? e.child_routes : []
                return (
                    <Route
                        key={e.path}
                        path={e.path}
                        element={
                            <RequireAuth userroles={e?.availability}>
                                {e.ele}
                            </RequireAuth>
                        }
                    >
                        {childroute.length > 0 &&
                            childroute.map((item: any) => (
                                <Route
                                    key={item.path}
                                    path={item.path}
                                    element={
                                        <RequireAuth
                                            userroles={item?.availability}
                                        >
                                            {item.ele}
                                        </RequireAuth>
                                    }
                                >
                                    {' '}
                                </Route>
                            ))}
                    </Route>
                )
            })}
        </Routes>
    )
}
export default AppRoutes
