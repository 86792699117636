import React, { useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom';
import AuthService from '../../api/services/auth.service'
import '../ForgotPassword/forgotPassword.scss'
import { toast } from 'react-toastify';

const ResetPassword = () => {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [successful, setSuccessful] = useState(false)
    const navigate = useNavigate();
    const validationSchema = () => {
        return Yup.object().shape({
            password: Yup.string()
                .required('Please Enter your password')
                .min(8, 'Must be 8 characters or more')
                .matches(/[a-z]+/, 'One lowercase character')
                .matches(/[A-Z]+/, 'One uppercase character')
                .matches(/[@$!%*#?&]+/, 'One special character')
                .matches(/\d+/, 'One number'),
            cpassword: Yup.string()
                .required('Please retype your password.')
                .oneOf([Yup.ref('password')], 'Your passwords do not match.'),
            otp: Yup.string().required('This field is required!'),
        })
    }

    const handleResetPassword = (formValue: {
        password: string
        cpassword: string
        otp: string
    }) => {
        const { password, otp } = formValue

        AuthService.confirmPassword(password, otp).then(
            (response) => {
                toast(response.data.message)
                setSuccessful(true)
                setMessage(response.data.message)
                navigate('/login')
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.error) ||
                    error.message ||
                    error.toString()
                setSuccessful(false)
                setMessage(resMessage)
            }
        )
    }

    const initialValues = {
        password: '',
        cpassword: '',
        otp: '',
    }
    return (
        <div className="forgotPage">
            <div className="forgotWrap">
                <div className="forgotLeft">
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            '/images/restpasswordImg.png'
                        }
                        alt="restpasswordImg"
                    />
                </div>
                <div className="forgotRight">
                    <h3>Reset Account Password</h3>
                    <p>Please reset password.</p>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleResetPassword}
                    >
                        <Form>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <Field
                                    name="password"
                                    type="password"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="cpassword">
                                    Confirm Password
                                </label>
                                <Field
                                    name="cpassword"
                                    type="password"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="cpassword"
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="otp">OTP</label>
                                <Field
                                    name="otp"
                                    type="password"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="otp"
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>
                            <div className="form-group">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                    disabled={loading}
                                >
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>Reset Password</span>
                                </button>
                            </div>

                            {message && (
                                <div className="form-group">
                                    {successful ?
                  <div className="alert alert-success" role="alert">
                    {message}
                  </div> :
                  <div className="alert alert-danger" role="alert">
                  {message}
                </div>}
                                </div>
                            )}
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword
