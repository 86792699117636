import { loadStripe } from '@stripe/stripe-js';
import authHeader from './auth-header';
import { END_POINTS } from '../domain';
import customAxios from '../axios';
import adsService from './ads.service';
import SubscriptionPlans from './subscription.service'

export const checkout = async ({
    priceId,
    bannerSlot,
    status,
    bannerImage,
}: any) => {
    let stripe: any

    if (process.env.REACT_APP_STRIPE) {
        stripe = await loadStripe(process.env.REACT_APP_STRIPE);
    }
    const authorizationHeader: any = authHeader() as unknown as string;

    try {
        const response = await fetch(END_POINTS.checkoutOneTime, {
            method: 'POST',
            headers: {
                'Authorization': authorizationHeader?.Authorization,
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                priceId,
                product:"Ad"
            }),
        });

        if (!response.ok) {
            throw new Error("Failed to create checkout session");
        }

        const session = await response.json();

        const createAds = await adsService.createAd(priceId, bannerSlot, bannerImage, session.id).then(
            (response) => {
                console.log(response);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.error) ||
                    error.message ||
                    error.toString();
            }
        );
        const result = await stripe?.redirectToCheckout({
            sessionId: session.id,
        });

        if (result?.error) {
            console.error(result.error);
        }

        return session;
    } catch (error) {
        console.error(error);
        throw error; // Rethrow the error to propagate it further
    }
}
export const checkoutSubscription = async ({
    priceId,
    planId,
    sub
}: any) => {
    let stripe: any

    if (process.env.REACT_APP_STRIPE) {
        stripe = await loadStripe(process.env.REACT_APP_STRIPE);
    }
    const authorizationHeader: any = authHeader() as unknown as string;

    try {
        const response = await fetch(END_POINTS.checkoutOneTime, {
            method: 'POST',
            headers: {
                'Authorization': authorizationHeader?.Authorization,
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                priceId,
                product:"ShopperSubscription"
            }),
        });

        if (!response.ok) {
            throw new Error("Failed to create checkout session");
        }

        
        const session = await response.json();
        const res = await SubscriptionPlans.createShopperSubs(planId,session.id,sub)
        const result = await stripe?.redirectToCheckout({
            sessionId: session.id,
        });

        if (result?.error) {
            console.error(result.error);
        }

        return session;
    } catch (error) {
        console.error(error);
        throw error; // Rethrow the error to propagate it further
    }
}


export const bannerSlotDetails = async () => {
    try {
        const result = await customAxios.get(END_POINTS.bannerSlots,)
        return { error: false, data: result }
    } catch (error) {
        return { error: true, data: error }
    }
}

export const subscriptionsPriceDetails = async (productId: any) => {

    try {
        const result = await customAxios.get(`${END_POINTS.subscriptionsPrice}/${productId}`,);
        const subscriptionPriceData = result.data;

        return { error: false, subscriptionPriceData };
    } catch (error) {
        return { error: true, data: error };
    }
};
export const userSubscriptions = async () => {
    try {
        const result = await customAxios.get(`${END_POINTS.userSubscriptions}`,);
        const userSubscriptionsData = result.data;

        return { error: false, userSubscriptionsData };
    } catch (error) {
        return { error: true, data: error };
    }
};
// export const isPlanAlreadySubscribed = async ({ priceId }: any) => {
//     try {
//         const result = await axios.post(`${END_POINTS.isPlanAlreadySubscribed}`, {
//             priceId
//         },);

//         return result;
//     } catch (error) {
//         return { error: true, errorMessage: error };

//     }
// }
export const isAdAlready = async ({ userID, bannerSlot }: any) => {
    try {
        const result = await customAxios.post(`${END_POINTS.isAdAlreadyCreated}`, {
            userID, bannerSlot
        },);

        return result;
    } catch (error) {
        return { error: true, errorMessage: error };

    }
}
