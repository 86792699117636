import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import SubscriptionPlans from '../../../../api/services/subscription.service'
import Loader from '../../../../components/Loader/Loader'

const SubsHeader = ({ subscription, getShopperSubscription }: any) => {
    const navigate = useNavigate()
    const userRole = localStorage.getItem('userRole')
    const [loading, setloading] = useState(false)

    const checkPayment = async () => {
        setloading(true)
        try {
            const res = await SubscriptionPlans.checkShopperSubsPayment(
                subscription.sessionId
            )
            if (res) {
                await getShopperSubscription()
            }
        } catch (error: any) {
            console.log(error)
        } finally {
            setloading(false)
        }
    }

    if (subscription?.paymentStatus === 'pending') {
        return (
            <Button onClick={() => checkPayment()}>
                {' '}
                {loading ? <Loader /> : 'Refetch'}
            </Button>
        )
    }

    return (
        <div>
            {userRole === `"vendor-service-provider"` && (
                <>
                    {subscription?.name ? (
                        <div className={`limbos__subscription`}>
                            <div>{subscription.name}</div>
                            <>
                                <button className="limbo__subscription__btn plan__active">
                                    <span>Active </span>
                                </button>
                                <span className="limbo__subscription__btn">
                                    Listing left {subscription?.listingLimit}{' '}
                                </span>
                            </>
                        </div>
                    ) : (
                        <div className="buy__subscription">
                            <button
                                className="limbo__subscription__btn"
                                onClick={() =>
                                    navigate(
                                        '/subscription/loggedin?description=You have no active subscription plan, please buy plan to add new listing'
                                    )
                                }
                            >
                                <span>Buy Subscription</span>
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default SubsHeader
