export const AdsHead = [
  {
    label: 'Banner Position',
    field: "bannerSlot",
    sort: 'asc',
    width: 150
  },
  {
    label: 'Image',
    field: "bannerImage",
    sort: 'asc',
    width: 150
  },
  {
    label: 'Start Date',
    field: "startDate",
    sort: 'asc',
    width: 270
  },
  {
    label: 'Expiry Date',
    field: "expiryDate",
    sort: 'asc',
    width: 200
  },
  {
    label: 'Status',
    field: "status",
    sort: 'asc',
    width: 200
  },
  {
    label: "Action",
    field: "action",
    sort: 'disabled'
  }
]