import React, { ReactNode, useState } from 'react'
import Moment from 'react-moment'
import ChatIcon from '../../assets/images/chat-icon.svg'
import './ListingDetails.scss'
import Chat from '../../pages/Chat/Chat'
import './ListingDetails.scss'
import Report from '../Report/Report'

const ListingDetails = ({
    listingData,
    id,
    favValue,
    AddFavourite,
    RemoveFavourite,
}: any) => {
    const [showChat, setShowChat] = useState(false)
    const shopperEmail = localStorage.getItem('email')

    const toggleShowChat = () => {
        setShowChat((prev) => !prev)
    }

    const handleAddFavourite = () => {
        AddFavourite()
    }

    const handleRemoveFavourite = () => {
        RemoveFavourite()
    }

    return (
        <div className="time-detail">
            <div className="time-details">
                <div className="limbo-created">
                    Listed Created :
                    <Moment format="MMMM Do YYYY, h:mm:ss a">
                        {listingData.createdDate}
                    </Moment>
                </div>
            </div>
            <div className="chat-vendor">
                <img src={ChatIcon} alt="icon" />
                <span>
                    <div
                        className="chat-button"
                        onClick={toggleShowChat}
                        style={{}}
                    >
                        Chat
                    </div>
                </span>
            </div>
            <div className="limbo-title">
                <div className="info">
                    <p>{listingData.description}</p>
                </div>
            </div>

            <div className="limbo--details">
                <div className="limbo-item">
                    <h6>Additional Detail</h6>
                    {listingData.listingDetail &&
                        listingData.listingDetail.map((item: any) => (
                            <div
                                className="flex-inline"
                                key={item.listingFieldName}
                            >
                                <div className="label">
                                    {item.listingFieldName}
                                </div>
                                <div className="label_name">
                                    {item.fieldValue as ReactNode}
                                </div>
                            </div>
                        ))}
                </div>
            </div>

            <div className="btn-section report__wrapper">
                {favValue == 1 ? (
                    <button
                        className="btn-orange"
                        onClick={handleRemoveFavourite}
                    >
                        Remove from Favourite
                    </button>
                ) : (
                    <button className="btn-orange" onClick={handleAddFavourite}>
                        Add to Favourite
                    </button>
                )}
                {showChat ? (
                    <Chat
                        shopperId={
                            shopperEmail ? shopperEmail?.split('"')?.[1] : ''
                        }
                        vendorId={listingData?.createdBy}
                        listingId={id || ''}
                        closeCallback={() => {
                            toggleShowChat()
                        }}
                    />
                ) : (
                    ''
                )}
                <Report
                    id={listingData?._id}
                    reportText="Report this Listing"
                    productType="listing"
                />
            </div>
        </div>
    )
}

export default ListingDetails
