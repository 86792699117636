import React, { useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import '../ForgotPassword/forgotPassword.scss'
import AuthService from '../../api/services/auth.service'
import { toast } from 'react-toastify'
const Verification = () => {
    const [loading, setLoading] = useState(false)
    const [successful, setSuccessful] = useState(false)
    const [message, setMessage] = useState('')
    const navigate = useNavigate()
    const validationSchema = () => {
        return Yup.object().shape({
            otp: Yup.string().required('This field is required!'),
        })
    }
    const handleOtp = (formValue: { otp: string }) => {
        const { otp } = formValue
        AuthService.verifyOtp(otp).then(
            (response) => {
                setSuccessful(true)
                setMessage(response.data.message)
                toast.success('OTP verified')
                navigate('/success')
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.error) ||
                    error.message ||
                    error.toString()
                setSuccessful(false)
                setMessage(resMessage)
            }
        )
    }
    const resendOtp = () => {
        AuthService.resendOtp().then(
            (response) => {
                setSuccessful(true)
                setMessage(response.data.message)
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.error) ||
                    error.message ||
                    error.toString()
                setSuccessful(false)
                setMessage(resMessage)
            }
        )
    }

    const initialValues = {
        otp: '',
    }
    return (
        <div className="registerpage">
            <div className="registerWrap">
                <div className="registerLeft">
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            '/images/verificationImg.png'
                        }
                        alt="verificationImg"
                    />
                </div>
                <div className="registerRight">
                    <h3>Enter Verification Code</h3>
                    <p>Please enter the verification code sent to your email</p>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleOtp}
                    >
                        <Form>
                            <div className="form-group">
                                <Field
                                    name="otp"
                                    type="text"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="otp"
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>
                            <div className="otplinks">
                                <span>Didn’t receive an OTP?</span>
                                <a href="#" onClick={resendOtp}>
                                    Resend code
                                </a>
                            </div>
                            <div className="form-group">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                    disabled={loading}
                                >
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>Verify OTP</span>
                                </button>
                            </div>
                            {message && (
                                <div className="form-group">
                                    {successful ? (
                                        <div
                                            className="alert alert-success"
                                            role="alert"
                                        >
                                            {message}
                                        </div>
                                    ) : (
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                        >
                                            {message}
                                        </div>
                                    )}
                                </div>
                            )}
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    )
}
export default Verification
