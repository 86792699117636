import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import 'bootstrap/dist/css/bootstrap.min.css'
import './homecarousel.scss'
import { data } from '../../constant/constant'

const HomeCarousel = () => {
    return (
        <div>
            <Carousel>
                {data?.HomeCarouselData?.map((item) => {
                    return (
                        <Carousel.Item key={item?.id}>
                            <img src={item.BannerImg} alt="banner image" />
                            <Carousel.Caption>
                                <h2>{item?.h2} </h2>
                                <h1>{item?.h1}</h1>
                            </Carousel.Caption>
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        </div>
    )
}

export default HomeCarousel
