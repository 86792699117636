import React, { useEffect, useState } from 'react'

const Image = ({ src, placeholder, alt,className }: any) => {
    const [imgLoad, setimgLoad] = useState(false)

    const handleImgLoad = () => {
        setimgLoad(true)
    }
    
    return (
        <div className={className}>
            {
                !imgLoad && <img src={placeholder} alt="placeholder" />
            }

            <img src={src} style={{ display: `${!imgLoad ? 'none' : 'block'} ` }} alt={alt ?? "image"} onLoad={handleImgLoad} />
        </div>
    )
}

export default Image
