import React, { useEffect } from 'react'
import { getCategory } from '../../api/services/category.service'
import Loader from '../Loader/Loader'
import userService from '../../api/services/user.service'
import { useSelector } from 'react-redux'
import { Tooltip } from 'react-bootstrap'
import Select from 'react-select'

interface categoriesProps {
    category: { data: any[]; loading: boolean }
    setCategory: any
    selected: string[]
    setSelected: any
    handleChange: (a: any) => void
}

function LimboCategories({
    category,
    setCategory,
    selected,
    setSelected,
    handleChange,
}: categoriesProps) {
    const userData = useSelector((state: any) => state.user.userData)

    // const [category, setCategory] = useState<{ data: any[]; loading: boolean }>(
    //     { data: [], loading: false }
    // )

    // const [selected, setSelected] = useState<string[]>(
    //     userData?.categories_supported || []
    // )

    // const updateCategories = async (selectedCategories: string[]) => {
    //     const response = await userService.updateUserCategory(
    //         userData?.email_id,
    //         selectedCategories
    //     )
    //     if (response?.status === 200) {
    //         toast('Categories updated successfully', {
    //             type: 'success',
    //             toastId: 'updateCategories',
    //             autoClose: 2000,
    //         })
    //     }
    // }

    const handleSelected = (id: string) => {
        const newCategories = [...selected, id]
        setSelected(newCategories)
        // updateCategories(newCategories)
    }

    const handleRemove = (id: string) => {
        const newCategories = selected.filter((item) => item !== id)
        setSelected(newCategories)
        // updateCategories(newCategories)
    }

    const getCategories = async () => {
        try {
            setCategory((prev: any) => ({ ...prev, loading: true }))
            const response: any = await getCategory('limbo')
            setCategory({ data: response.data, loading: false })
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const getSupportedCategories = async () => {
        let supportedCategories = []
        const loggedInUser =
            localStorage?.getItem('email')?.split('"')?.[1] || ''
        const userInfo = await userService.getuserInfo(loggedInUser)
        supportedCategories = userInfo?.data?.result?.[0]?.categories_supported
        if (supportedCategories) {
            setSelected(supportedCategories)
        }
    }

    useEffect(() => {
        getSupportedCategories()
        getCategories()
    }, [])

    if (category?.loading && category?.loading)
        return (
            <div>
                <Loader />
            </div>
        )

    const addTooltip = (
        <Tooltip
            id="tooltip"
            style={{ backgroundColor: 'white', color: 'black' }}
        >
            Click to add ✅ !
        </Tooltip>
    )

    const removeTooltip = (
        <Tooltip
            id="tooltip"
            style={{ backgroundColor: 'white', color: 'black' }}
        >
            Click to remove 🚫!
        </Tooltip>
    )

    return (
        <>
            <div className="select-dropdown">
                <Select
                    value={category.data.filter((item: any) =>
                        selected.includes(item._id)
                    )}
                    isMulti={true}
                    options={category.data}
                    getOptionLabel={(x) => x.limboName}
                    getOptionValue={(x) => x._id}
                    closeMenuOnSelect={false}
                    onChange={(e) => {
                        handleChange(e)
                    }}
                    placeholder=""
                    styles={{
                        container: (base, state) => ({
                            ...base,
                            width: '100%',
                            // maxHeight:'50px',
                            minHeight: '50px',
                        }),
                        control: (base, state) => ({
                            ...base,
                            width: '100%',
                            // maxHeight:'50px',
                            minHeight: '50px',
                        }),
                        valueContainer: (base, state) => ({
                            ...base,
                            width: '100%',
                            maxHeight: '80px',
                            minHeight: '50px',
                            overflow: 'auto',
                        }),
                    }}
                />
            </div>
            {/* <div className="category-selected">
                <div className="category-limbo">
                    {category?.data.map((item: any) => {
                        return (
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    selected.includes(item._id)
                                        ? removeTooltip
                                        : addTooltip
                                }
                                key={item._id}
                            >
                                <div
                                    className={
                                        selected.includes(item._id)
                                            ? 'category-card-choose selected'
                                            : 'category-card-choose unselected'
                                    }
                                    onClick={() => {
                                        selected.includes(item._id)
                                            ? handleRemove(item._id)
                                            : handleSelected(item._id)
                                    }}
                                    key={item?.limboTypeId}
                                >
                                    <img src={item?.limboImage} alt="image" />
                                    <h3> {item?.limboName}</h3>
                                    <div className="icon">
                                        <div
                                            className={
                                                selected.includes(item._id)
                                                    ? 'container selected'
                                                    : 'container unselected'
                                            }
                                        >
                                            {selected.includes(item._id) ? (
                                                <FaCheck />
                                            ) : (
                                                <FaTimes />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        )
                    })}
                </div>
            </div> */}
        </>
    )
}

export default LimboCategories
