import React, { useEffect, useState } from 'react'
import './VendorInfo.scss'
import MapPopup from '../MapWithUserLocation/MapPopup'

const VendorInfo = ({ data }: any) => {
    return (
        <div className="vendor-info-section">
            <div className="vendorinfo-label">
                <h4>Vendor Info</h4>
            </div>
            
            <div className="vendor-info">
                
                    <div className="flex-inline">
                        <div className="label">
                        <strong>Business Name: </strong>{data?.business_name} <br />
                        <strong>Business Address: </strong>{data?.address} <br />
                        <strong>Business Website: </strong> {data?.business_website} <br />
                        <strong>Contact Name:  </strong>{data?.first_name} {data?.last_name} <br />
                        <strong>Contact Email: </strong>{data?.email_id} <br />
                        <strong>Contact Phone Number: </strong>{data?.phoneNumber} <br />
                        </div>
                    </div>
                    
                   

                    <MapPopup text="view on map" />
            </div>
        </div>
    )
}

export default VendorInfo
