import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { Formik, Form } from 'formik'
import 'react-datepicker/dist/react-datepicker.css'
import '../../../Login/login.scss'
import AdService from '../../../../api/services/ads.service'
import {
    bannerSlotDetails,
    checkout,
    subscriptionsPriceDetails,
} from '../../../../api/services/stripe.service'
import { toast } from 'react-toastify'
import ImagePicker from '../../../../components/ImagePicker/ImagePicker'

const CreateAd = () => {
    const [eroorOnLoad, setErrorLoad] = useState(false)
    const [message, setMessage] = useState('')
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [adtype, setAdtype] = useState('')
    const [slotLoading, setslotLoading] = useState(false)
    const [adsOptions, setAdsOptions] = useState([{}])
    const [productId, setproductId] = useState('')
    const [priceDetails, setpriceDetails] = useState<any>()
    const [btnLoading, setbtnLoading] = useState(false)
    const [bannerImage, setBannerImage] = useState<any>([])

    const [bannerSlotId, setbannerSlotId] = useState()

    useEffect(() => {
        getAdsSlot()
    }, [])

    const handleOptionChange = async (event: any) => {
        setslotLoading(true)
        setAdtype(event.value)
        const result: any = await bannerSlotDetails()
        setslotLoading(false)
        const slots = result?.data?.data?.result
        const productId = slots.find((item: any) => item._id === event.value)
        setbannerSlotId(productId._id)
        setproductId(productId.productId)
    }

    useEffect(() => {
        const getProductDetails = async () => {
            const productDetails: any = await subscriptionsPriceDetails(
                productId
            )
            setpriceDetails(productDetails)
        }
        if (productId?.length) {
            getProductDetails()
        }
    }, [productId])

    const handleAd = async () => {
        setbtnLoading(true)
        setErrorLoad(true)
        if (!bannerImage?.length) {
            toast('Please upload image')
        }
        try {
            if (bannerImage?.length) {
                const sessionId = await checkout({
                    priceId: priceDetails?.subscriptionPriceData[0]?.priceId,
                    bannerSlot: bannerSlotId,
                    expiryDate: endDate,
                    startDate: startDate,
                    status: 2,
                    bannerImage: bannerImage,
                })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setbtnLoading(false)
        }
    }

    const getAdsSlot = () => {
        setslotLoading(true)
        AdService.getAdsSlot().then(
            (response: any) => {
                const adoptions = [{}]
                response.data.result.map((item: any, idx: any) => {
                    adoptions.push({ value: item._id, label: item.slotName })
                })
                setAdsOptions(adoptions)
                setslotLoading(false)
            },
            (error: any) => {
                setMessage(error.message)
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.error) ||
                    error.message ||
                    error.toString()
                setslotLoading(false)
            }
        )
    }

    const initialValues = {
        adtype: '',
        startDate: '',
        endDate: '',
        adimage: '',
    }
    const subscriptionPrice: any = priceDetails?.subscriptionPriceData[0]?.price

    const onImageUpload = (file: any) => {
        setBannerImage(file[0])
    }

    return (
        <div className="createads">
            {message && (
                <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                        {message}
                    </div>
                </div>
            )}
            <Formik initialValues={initialValues} onSubmit={handleAd}>
                <Form>
                    <div className="form-group">
                        <label htmlFor="adtype">
                            Ad Type<span className="red-text">*</span>
                        </label>
                        <Select
                            name="adtype"
                            onChange={handleOptionChange}
                            options={adsOptions}
                            className="selectdropdown"
                        />
                        {eroorOnLoad && adtype == '' && (
                            <div className="alert alert-danger">
                                This field is required
                            </div>
                        )}
                    </div>
                    {bannerSlotId && (
                        <div className="form-group">
                            <label htmlFor="price">
                                Price for selected slot
                            </label>
                            <div className="div">
                                {!subscriptionPrice || slotLoading ? (
                                    <span className="spinner-border spinner-border-sm loader"></span>
                                ) : (
                                    `${subscriptionPrice / 100} CAD$`
                                )}
                            </div>
                        </div>
                    )}

                    <ImagePicker
                        awsFolderName="ads"
                        onImageUpload={onImageUpload}
                    />
                    {/* <div className="form-group">
                        <label htmlFor="adimage">
                            Ad Image<span className="red-text">*</span>
                        </label>
                        <Upload />
                    </div> */}
                    {/* <div className="form-group">
                        <label htmlFor="startDate">Start Date<span className='red-text'>*</span></label>
                        <DatePicker
                            showTimeSelect
                            selected={startDate}
                            name="startDate"
                            onChange={(date: any) => setStartDate(date)}
                        />
                        {eroorOnLoad && startDate == null && (
                            <div className="alert alert-danger">
                                This field is required
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="endDate">
                            End Date<span className="red-text">*</span>
                        </label>
                        <DatePicker
                            showTimeSelect
                            selected={endDate}
                            name="endDate"
                            onChange={(date: any) => setEndDate(date)}
                        />
                        {eroorOnLoad && endDate == null && <div className='alert alert-danger'>This field is required</div>}
                    </div>  */}
                    <div className="form-group">
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={btnLoading}
                        >
                            <span>Create Ad</span>
                            {btnLoading && (
                                <span className="spinner-border spinner-border-sm loader"></span>
                            )}
                        </button>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default CreateAd
