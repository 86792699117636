// searchSlice.js
import { createSlice } from '@reduxjs/toolkit'

const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        limboData: [],
        limboLoading: false,
        chatData: [],
        chatLoading: false,
        adsData: [],
        adsLoading: false,
    },
    reducers: {
        setLimboData: (state, action) => {
            state.limboData = action.payload
        },
        setLimboLoading: (state, action) => {
            state.limboLoading = action.payload
        },
        setChatLoading: (state, action) => {
            state.chatLoading = action.payload
        },
        setChatData: (state, action) => {
            state.chatData = action.payload
        },
        setAdsLoading: (state, action) => {
            state.adsLoading = action.payload
        },
        setAdsData: (state, action) => {
            state.adsData = action.payload
        },
        removeNotification: (state) => {
            state.limboData = [];
            state.limboLoading = false;
            state.chatData = [];
            state.chatLoading = false;
            state.adsData = [];
            state.adsLoading = false;
        }
    },
})

export const {
    setChatData,
    setChatLoading,
    setLimboData,
    setLimboLoading,
    setAdsData,
    setAdsLoading,
    removeNotification
} = notificationSlice.actions

export default notificationSlice.reducer
