import React from 'react'
import pageNotFound from './404.jpg'
import './pagenotfound.scss'

const PageNotFound = () => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className='pageNotFound__wrapper'
        >
            <img src={pageNotFound} alt="" width="50%"  />
            <div className='pageNotFound'>
                <span>PAGE</span>
                <span>NOT</span>
                <span>FOUND</span>
            </div>
        </div>
    )
}

export default PageNotFound
