export const navItems = [
    {
        id: 1,
        MenuItem: 'Home',
        to: '/',
    },
    {
        id: 2,
        MenuItem: 'Services',
        to: '/services',
    },
    {
        id: 3,
        MenuItem: 'Products',
        to: '/product',
    },
    
]
