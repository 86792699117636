import { MDBDataTable } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import { userCategoriesColumn } from '../../../../constant/constant'
import { getReport, seenByAdmin } from '../../../../api/services/report.service'
import Loader from '../../../../components/Loader/Loader'
import '../../../../components/Report/report.scss'
import { AiFillEye } from 'react-icons/ai'
import reportApiResponse from '../../../../constant/mockdata'
import userService from '../../../../api/services/user.service'
import { setLoading } from '../../../../redux/userSlice'
import { showToast } from '../../../../redux/toastSlice'
import { IoTrashOutline } from 'react-icons/io5'

const UserCategoryList = () => {
    const [tableData, settableData] = useState({
        columns: userCategoriesColumn,
        rows: [],
    })
    const [error, seterror] = useState<any>()
    const [loading, setloading] = useState(false)

    const importCategory = async (id: string) => {
        if (id) {
            const res = await userService.importUserCategory(id)
            showToast('Category Imported Successfully')
        }
    }

    const deleteCategory = async (id: string) => {
        if (id) {
            const res = await userService.deleteUserCategory(id)
            showToast('Category Deleted Successfully')
            getData()
        }
    }

    const getData = async () => {
        setloading(true)
        try {
            const res = await userService.getUserCategories()
            setloading(false)

            if (res.data) {
                const newRes = res?.data?.map((item: any) => {
                    const newFieldToAdd =
                        item?.fieldToAdd && item.fieldToAdd.split('->')[1]
                    return {
                        ...item,
                        fieldToAdd: newFieldToAdd,
                        status:
                            item?.status === 'Active' ? (
                                <AiFillEye
                                title='status active'
                                    style={{ color: 'green' }}
                                    size={20}
                                />
                            ) : (
                                <AiFillEye title='status inactive' style={{ color: 'red' }} size={20} />
                            ),

                        import: (
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                    importCategory(item._id)
                                }}
                            >
                                Import
                            </button>
                        ),
                        delete: (
                            <IoTrashOutline
                                className="delete__icon"
                                title='delete category'
                                onClick={() => {
                                    deleteCategory(item._id)
                                }}
                                size={20}
                            />
                        ),
                    }
                })
                settableData({
                    columns: userCategoriesColumn,
                    rows: newRes || [],
                })
                setLoading(false)
            } else {
                return
            }
        } catch (error: any) {
            setloading(false)
            seterror(error?.message)
        }
    }
    useEffect(() => {
        getData()
    }, [])

    if (loading) {
        return (
            <div className="loader__table">
                <Loader />
            </div>
        )
    }

    return (
        <div className="admin__table">
            {error && <div className="alert alert-danger">{error}</div>}
            <MDBDataTable
                data={tableData}
                className="responsive-table report__table"
                responsive
                sortable={false}
            />
        </div>
    )
}

export default UserCategoryList
