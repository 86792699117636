import React, { useEffect, useState } from 'react'
import { getProduct } from '../../api/services/category.service'
import { useNavigate } from 'react-router'
import NoDataFound from '../../components/NodataFound/NoDataFound'
import noimg from '../../assets/images/no-image.png'
import './product.scss'

const Product = () => {
    const [product, setProduct] = useState([])
    const navigate = useNavigate()

    const getData = () => {
        getProduct()
            .then((response) => {
                setProduct(response.data)
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        getData()
    }, [])

    const handleCategory = (item: any) => {
        navigate(`/listing-list/${item?._id}`)
    }

    return (
        <div className="product">
            <div className="listingwrap">
                <div className="limboListing">
                    <div className="listingpanel">
                        {product && product?.length ? (
                            product?.map((item: any, idx: any) => {
                                return (
                                    <div
                                        onClick={() => handleCategory(item)}
                                        key={item?._id}
                                        className="category-card"
                                    >
                                        <img
                                            src={
                                                item?.limboImage
                                                    ? item?.limboImage
                                                    : noimg
                                            }
                                            alt="image"
                                        />
                                        <h3> {item?.limboName}</h3>
                                    </div>
                                )
                            })
                        ) : (
                            <NoDataFound />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Product
