import customAxios from '../axios'
import { END_POINTS } from '../domain'

export const addMultiUser = (payload: any) => {
    return customAxios.post(`${END_POINTS.addMultiUser}`, payload)
}

export const getMultiUserList = () => {
    return customAxios.get(`${END_POINTS.getMultiUserList}`)
}

export const changePassword = (payload: any) => {
    return customAxios.post(`${END_POINTS.changePasswordVendor}`, payload)
}
