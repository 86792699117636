import React from 'react';
import '../../App.scss';
import '../PageHeader/pageheader.scss';

interface Heading {
  title: string;
}

const PageHeader = (props:Heading) => {

  return (
    <div className='pageheader'>
        <h1>{props.title}</h1>
    </div>
  );
};

export default PageHeader;
