import React, { useState } from 'react'
import './Contact.scss'
import Form from 'react-bootstrap/Form'
import { Button, Col, Container } from 'react-bootstrap'
import { contantUs } from '../../api/services/contact.service'
import axios from 'axios'

const formFields = [
    {
        label: 'Name',
        name: 'name',
        type: 'text',
        placeholder: 'Name',
        required: true,
    },
    {
        label: 'Email',
        name: 'email',
        type: 'email',
        placeholder: 'Email',
        required: true,
    },
    {
        label: 'Phone',
        name: 'phoneNumber',
        type: 'number',
        placeholder: 'Phone',
        required: true,
    },
    {
        label: 'Subject',
        name: 'subject',
        type: 'text',
        placeholder: 'Subject',
        required: true,
    },
    {
        label: 'Message',
        name: 'message',
        type: 'textarea',
        placeholder: 'Message',
        required: true,
    },
]

const Contact = () => {
    const [formData, setFormData] = useState<any>({
        name: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: '',
    })

    const handleContactForm = (e: any) => {
        setFormData((prev: any) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })
    }

    function handleSubmit(e: any) {
        e.preventDefault()
        axios
            .post('http://localhost:8080/api/contantUs', formData)
            .then((response) => {

                setFormData({
                    name: '',
                    email: '',
                    phoneNumber: '',
                    subject: '',
                    message: '',
                })
            })
            .catch((err) => console.log(err))
    }

    return (
        <div className="justify-content-center">
            <div className="contact-form">
                <h4 className="mb-4">Contact Us</h4>
                <Container>
                    <Form
                        className="form-section"
                        method="post"
                        onSubmit={handleSubmit}
                    >
                        {formFields.map((field: any, index) => (
                            <Col
                                md={{ order: 12 }}
                                key={index}
                                className={`${field.name}`}
                            >
                                <label className="form-label">
                                    {field.label}
                                    {field.required && (
                                        <span className="asterisk">*</span>
                                    )}
                                </label>

                                {field.type === 'textarea' ? (
                                    <textarea
                                        className={`form-control`}
                                        id={field.id}
                                        required={field.required}
                                        onChange={handleContactForm}
                                        value={formData.message}
                                        name="message"
                                    ></textarea>
                                ) : (
                                    <input
                                        type={field.type}
                                        className="form-control"
                                        id={field.id}
                                        placeholder={field.placeholder}
                                        required={field.required}
                                        onChange={handleContactForm}
                                        value={formData[`${field.name}`]}
                                        name={field?.name}
                                    />
                                )}
                            </Col>
                        ))}
                        <Button
                            className="btn-orange"
                            type="submit"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Form>

                    <p className="message">
                        For any question contact at +123-4567-890{' '}
                    </p>
                </Container>
            </div>
        </div>
    )
}

export default Contact
