import React, { useState, useEffect } from 'react'
import { MDBDataTable } from 'mdbreact'
import UserService from '../../../../api/services/user.service'
import { userHead } from '../../../../constant/user'
import { getIconClassName } from '../../../../helper/utils'
import Swal from 'sweetalert2'
import Loader from '../../../../components/Loader/Loader'
import { IoTrashOutline } from 'react-icons/io5'
export type tableData = {
    columns: {
        label?: string
        field?: string
        sort?: string
        width?: number
        searchable?: boolean
        [rest: string]: any
    }[]
    rows: {
        clickEvent?: () => void
        [rest: string]: any
    }[]
}

const VendorListing = () => {
    const [successful, setSuccessful] = useState(false)
    const [tableData, settableData] = useState<tableData>()
    const [error, seterror] = useState('')
    const [loading, setloading] = useState(false)

    useEffect(() => {
        getuserListing(1)
    }, [])

    const handleClick = () => {
        // Simulating an error by throwing an exception
        throw new Error('Intentional error for testing error boundary')
    }
    const deleteUser = (email: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'To disable the user',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then(async (result) => {
            if (result.isConfirmed) {
                UserService.deleteUser(email).then(
                    (response) => {
                        setSuccessful(true)
                        getuserListing(1)
                    },
                    (error: any) => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.error) ||
                            error.message ||
                            error.toString()
                        setSuccessful(false)
                        seterror(error.message)
                    }
                )
                Swal.fire({
                    title: 'Deleted',
                    text: 'User disabled successfully.',
                    icon: 'success',
                })
            }
        })
    }

    const getuserListing = (page: number) => {
        setloading(true)
        UserService.getuserListing(page, 'vendor').then(
            (response) => {
                setloading(false)
                setSuccessful(true)
                settableData({
                    columns: userHead,
                    rows: response.data.users.map((row: any, index: number) => {
                        return {
                            ...row,
                            status: (
                                <div
                                    className={getIconClassName(
                                        row.isActive ? 'active' : 'disabled'
                                    )}
                                >
                                    {row.isActive ? 'active' : 'disabled'}
                                </div>
                            ),
                            action: (
                                <IoTrashOutline
                                    title='delete user'
                                    onClick={() => deleteUser(row.email_id)}
                                />
                            ),
                        }
                    }),
                })
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.error) ||
                    error.message ||
                    error.toString()
                seterror(error.message)
                setSuccessful(false)
                setloading(false)
            }
        )
    }

    if (loading) {
        return (
            <div className="loader__table">
                <Loader />
            </div>
        )
    }
    return (
        <div className="tableView admin__table">
            {error && error?.length && (
                <div className="alert alert-danger">{error}</div>
            )}
            
            {/* <button onClick={()=>handleClick()}>Produce Error</button> */}
            <MDBDataTable
                small
                data={tableData}
                className="responsive-table"
                responsive
            />
        </div>
    )
}

export default VendorListing
