// searchSlice.js
import { createSlice } from '@reduxjs/toolkit';

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    query: '',
    results: [],
    loading: false,
  },
  reducers: {
    setSearchQuery: (state, action) => {
      state.query = action.payload;
    },
    searchStart: (state) => {
      state.loading = true;
    },
    searchSuccess: (state, action) => {
      state.results = action.payload;
      state.loading = false;
    },
    searchFailure: (state) => {
      state.loading = false;
    },
  },
});

export const {
  setSearchQuery,
  searchStart,
  searchSuccess,
  searchFailure,
} = searchSlice.actions;

export const selectSearchQuery = (state) => state.search.query;
export const selectSearchResults = (state) => state.search.results;
export const selectSearchLoading = (state) => state.search.loading;

export default searchSlice.reducer;