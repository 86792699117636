import React, { useState, useEffect } from 'react'
import { useCountdown } from './useCountdown';
import './LimboTimer.scss'

const LimboTimer = ({endTime} :any ) => {
    const [days, hours, minutes, seconds] = useCountdown(endTime);

    return (
        <div className="timer">
           {endTime && <ul>
                <li>
                    {days} <span className="days">Days</span>
                </li>
                <li>
                    {hours} <span className="days">Hour</span>
                </li>
                <li>
                    {minutes} <span className="days">Min.</span>
                </li>
                <li>
                    {seconds} <span className="days">Sec.</span>
                </li>
            </ul>}
        </div>
    )
}

export default LimboTimer
