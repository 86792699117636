import React from 'react'
import Dropdown from '../components/Dropdown/Dropdown'
import DateTimePicker from '../components/DateTimePicker/DateTimePicker'

export const getFormFields = ({
    handleOnChange,
    placeholder,
    options,
    onItemSelect,
    handleLimboField,
    handleCheckbox,
    date,
    setDate,
    field,
    value,
    errorMessage,
    formType,
}: any) => {
    const {
        limboFieldType,
        limboFieldId,
        displayOrder,
        fieldDataType,
        limboFieldName,
        required,
        display,
    } = field

    switch (limboFieldType) {
        case 'Dropdown':
        case 'Combo':
            return (
                <div className="form-fields">
                    <label htmlFor={limboFieldName}>
                        {limboFieldName} <span style={{ color: 'red' }}>*</span>
                    </label>
                    <Dropdown
                        field={field}
                        placeholder={placeholder}
                        options={options}
                        onItemSelect={onItemSelect}
                        handleLimboField={handleLimboField}
                        value={value}
                    />
                    {errorMessage && (
                        <div style={{ color: 'red' }}>{errorMessage}</div>
                    )}
                </div>
            )

        case 'YesNo':
            return (
                <div className="check-boxes-form-fields" id={limboFieldId}>
                    <input
                        type="checkbox"
                        name={limboFieldName}
                        value={value}
                        // checked={value ? true : false}
                        onChange={(event) => {
                            handleCheckbox(event, {
                                limboFieldId,
                                limboFieldName,
                                fieldDataType,
                                displayOrder,
                                limboFieldType,
                            })
                        }}
                    ></input>
                    <label htmlFor={limboFieldName}>{limboFieldName}</label>
                </div>
            )
        case 'Textbox':
            return (
                <div
                    className="form-fields"
                    id={limboFieldId}
                    style={{ display: required === 'no' ? 'none' : 'flex' }}
                >
                    <label htmlFor={limboFieldName}>
                        {limboFieldName} <span style={{ color: 'red' }}>*</span>
                    </label>

                    <input
                        type={fieldDataType}
                        id={limboFieldId}
                        name={limboFieldName}
                        value={value}
                        onChange={(event) => {
                            handleLimboField(
                                limboFieldId,
                                limboFieldName,
                                event.target.name,
                                event.target.value,
                                limboFieldType,
                                displayOrder
                            )
                        }}
                        onBlur={(event) => {
                            handleLimboField(
                                limboFieldId,
                                limboFieldName,
                                event.target.name,
                                event.target.value,
                                limboFieldType,
                                displayOrder
                            )
                        }}
                        placeholder={placeholder}
                    />
                    {errorMessage && (
                        <div style={{ color: 'red' }}>{errorMessage}</div>
                    )}
                </div>
            )

        case 'Date':
            return (
                <div className="form-fields" id={limboFieldId}>
                    <label htmlFor={limboFieldName}>
                        {limboFieldName} <span style={{ color: 'red' }}>*</span>
                    </label>
                    <DateTimePicker
                        handleLimboField={handleLimboField}
                        placeholder={placeholder}
                        field={field}
                        date={date}
                        setDate={setDate}
                        handleOnChage={handleOnChange}
                    />
                    {errorMessage && (
                        <div style={{ color: 'red' }}>{errorMessage}</div>
                    )}
                </div>
            )
        case 'textarea':
            return (
                <div className="form-fields">
                    <label htmlFor={limboFieldName}>
                        {limboFieldName} <span style={{ color: 'red' }}>*</span>
                    </label>

                    <textarea
                        id={limboFieldId}
                        name={limboFieldName}
                        value={value}
                        onChange={(event) =>
                            handleLimboField(
                                limboFieldId,
                                limboFieldName,
                                event.target.name,
                                event.target.value,
                                limboFieldType,
                                displayOrder
                            )
                        }
                        rows={6}
                        cols={4}
                        placeholder={placeholder}
                    ></textarea>
                    {errorMessage && (
                        <div style={{ color: 'red' }}>{errorMessage}</div>
                    )}
                </div>
            )
        default:
            return (
                <div className="form-fields">
                    <label htmlFor={limboFieldName}>
                        {limboFieldName} <span style={{ color: 'red' }}>*</span>
                    </label>
                    <input
                        type="text"
                        id={limboFieldId}
                        name={limboFieldName}
                        value={value}
                        onChange={(event) =>
                            handleLimboField(
                                limboFieldId,
                                limboFieldName,
                                event.target.name,
                                event.target.value,
                                limboFieldType,
                                displayOrder
                            )
                        }
                        placeholder={placeholder}
                    />
                    {errorMessage && (
                        <div style={{ color: 'red' }}>{errorMessage}</div>
                    )}
                </div>
            )
    }
}

const CategoryFormFields = ({
    formType,
    placeholder,
    options,
    onItemSelect,
    handleOnChange,
    handleLimboField,
    onImageUpload,
    parentFieldValue,
    handleCheckbox,
    date,
    setDate,
    awsFolderName,
    field,
    value,
    errorMessage,
}: any) => {
    return getFormFields({
        formType,
        placeholder,
        options,
        onItemSelect,
        handleOnChange,
        handleLimboField,
        onImageUpload,
        parentFieldValue,
        handleCheckbox,
        date,
        setDate,
        awsFolderName,
        field,
        value,
        errorMessage,
    })
}

export default CategoryFormFields
