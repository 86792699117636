import React, { useState, useEffect } from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { MDBDataTable } from 'mdbreact'
import { AdsHead } from '../../../../constant/ads'
import { FaCheck } from 'react-icons/fa6'
import { RxCross2 } from "react-icons/rx";
import { getClassName } from '../../../../helper/utils'
import adsService from '../../../../api/services/ads.service'
import { FaTrash } from 'react-icons/fa'
import Swal from 'sweetalert2'
import Loader from '../../../../components/Loader/Loader'

const AdStatus = [
    {
        id: '0',
        status: 'Rejected',
        cssClass: 'rejected',
    },
    {
        id: '1',
        status: 'Approved',
        cssClass: 'approved',
    },
    {
        id: '2',
        status: 'Pending',
        cssClass: 'pending',
    },
    {
        id: 'expired',
        status: 'Expired',
        cssClass: 'rejected',
    },
]

const getAdStatus = (status: string) => {
    const adStatusList = AdStatus.filter(
        (adsItem: any) => adsItem.id === status
    )
    return adStatusList[0]
}

export type tableData = {
    columns: {
        label?: string
        field?: string
        sort?: string
        width?: number
        searchable?: boolean
        [rest: string]: any
    }[]
    rows: {
        clickEvent?: () => void
        [rest: string]: any
    }[]
}

const AdListing = () => {
    const [successful, setSuccessful] = useState(false)
    const [tableData, settableData] = useState<tableData>()
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState('')
    useEffect(() => {
        getuserListing(1)
    }, [])

    const manageAd = (id: any, type: any, status: string) => {
        if (type != status) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'To change the status',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
            }).then((result) => {
                if (result.isConfirmed) {
                    adsService.updateAd(id, type).then(
                        (response) => {
                            setSuccessful(true)
                            getuserListing(1)
                        },
                        (error: any) => {
                            const resMessage =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.error) ||
                                error.message ||
                                error.toString()
                            setSuccessful(false)
                        }
                    )
                    Swal.fire({
                        title: 'Changes!',
                        text: 'Status changed successfully.',
                        icon: 'success',
                    })
                }
            })
        }
    }

    const userRole = localStorage.getItem('userRole')

    const getuserListing = (page: number) => {
        setloading(true)
        adsService.getAdsListing(page).then(
            (response) => {
                setloading(false)
                setSuccessful(true)
                settableData({
                    columns: AdsHead,
                    rows: response.data.result.map(
                        (row: any, index: number) => {
                            return {
                                ...row,
                                status: (
                                    <div
                                        className={
                                            getAdStatus(row.status).cssClass
                                        }
                                    >
                                        {getAdStatus(row.status).status}
                                    </div>
                                ),
                                bannerImage: (
                                    <div>
                                        <img
                                            src={row.bannerImage}
                                            width={100}
                                        />
                                    </div>
                                ),
                                startDate: (
                                    <Moment format="MMMM Do YYYY, h:mm:ss a">
                                        {row.startDate}
                                    </Moment>
                                ),
                                expiryDate: (
                                    <Moment format="MMMM Do YYYY, h:mm:ss a">
                                        {row.expiryDate}
                                    </Moment>
                                ),
                                action: (
                                    <div className="ads__table">
                                        {userRole == `"admin"` ? (
                                            row.status === 'expired' || (
                                                <>
                                                    <button
                                                        onClick={() =>
                                                            manageAd(
                                                                row.id,
                                                                1,
                                                                row.status
                                                            )
                                                        }
                                                        className={getClassName(
                                                            row.status
                                                        )}
                                                    >
                                                        <FaCheck title='approve ad' className="checkIcon" />
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            manageAd(
                                                                row.id,
                                                                0,
                                                                row.status
                                                            )
                                                        }
                                                        className={getClassName(
                                                            row.status
                                                        )}
                                                    >
                                                        <RxCross2 title='reject ad' className="crossIcon" />
                                                    </button>
                                                </>
                                            )
                                        ) : (
                                            <button
                                                onClick={() =>
                                                    manageAd(
                                                        row.id,
                                                        2,
                                                        row.status
                                                    )
                                                }
                                                className={getClassName(
                                                    row.status
                                                )}
                                            >
                                                <FaTrash />
                                            </button>
                                        )}
                                    </div>
                                ),
                            }
                        }
                    ),
                })
            },
            (error: any) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.error) ||
                    error.message ||
                    error.toString()
                setloading(false)
                seterror(error.message)
                setSuccessful(false)
            }
        )
    }
    if (loading) {
        return (
            <div className="loader__table">
                <Loader />
            </div>
        )
    }

    return (
        <div className="tableView admin__table">
            {error?.length ? (
                <div className="alert alert-danger">{error}</div>
            ) : (
                <MDBDataTable
                    small
                    data={tableData}
                    className="responsive-table"
                    responsive
                />
            )}
        </div>
    )
}

export default AdListing
