import React, { useState, useEffect } from 'react'
import Moment from 'react-moment'
import AdService from '../../../../api/services/ads.service'
import './Ads.scss'
import NoDataFound from '../../../../components/NodataFound/NoDataFound'
import Loader from '../../../../components/Loader/Loader'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import adsService from '../../../../api/services/ads.service'
import { toast } from 'react-toastify'

interface TableColumn {
    label?: string
    field?: string
    sort?: string
    width?: number
    searchable?: boolean
    [rest: string]: any
}

interface TableRow {
    clickEvent?: () => void
    [rest: string]: any
}

interface TableData {
    columns: TableColumn[]
    rows: TableRow[]
}

export type { TableColumn, TableRow, TableData }

const AdStatus = [
    {
        id: '0',
        status: 'Rejected',
        cssClass: 'rejected',
    },
    {
        id: '1',
        status: 'Approved',
        cssClass: 'approved',
    },
    {
        id: '2',
        status: 'Pending',
        cssClass: 'pending',
    },
    {
        id: 'expired',
        status: 'Expired',
        cssClass: 'rejected',
    },
]

const Ads = () => {
    const [successful, setSuccessful] = useState(false)
    const [adsData, setAdsData] = useState([])
    const [loading, setloading] = useState(false)
    const userRole: any = localStorage.getItem('userRole')
    useEffect(() => {
        getuserListing(1)
    }, [])

    const getAdStatus = (status: string) => {
        const adStatusList = AdStatus.filter(
            (adsItem: any) => adsItem.id === status
        )
        return adStatusList[0]?.status
    }

    const getAdStatusClass = (status: string) => {
        const adStatusList = AdStatus.filter(
            (adsItem: any) => adsItem.id === status
        )
        return adStatusList[0]?.cssClass
    }
    const getuserListing = (page: number) => {
        setloading(true)
        AdService.getAdvertisementListforVendor().then(
            (response) => {
                setSuccessful(true)
                setAdsData(response?.data?.result)
                setloading(false)
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.error) ||
                    error.message ||
                    error.toString()
                setSuccessful(false)
                setloading(false)
            }
        )
    }

    const checkPayment = async (ad: any) => {
        if (ad.paymentStatus == 'success' || ad.paymentStatus == 'failed') {
            return null
        }
        try {
            const res = await adsService.adsPayment(ad.sessionId)
            if (res.data.success) {
                toast('payment success')
            }
        } catch (error: any) {
            toast('payment failed')
        } finally {
            getuserListing(1)
        }
    }
    if (loading) {
        return (
            <div className="loader__table">
                <Loader />
            </div>
        )
    }

    return (
        <>
            <div className="btn-row">
                {(JSON.parse(userRole) === 'vendor-service-provider' ||
                    JSON.parse(userRole) === 'vendor-sub-user') && (
                    <Link to={`/dashboard/createad`} className="btn-orange">
                        Create Ad
                    </Link>
                )}
            </div>
            {successful ? (
                <>
                    {adsData?.map((ad: any, idx: any) => {
                        return (
                            <div className="adsrow" key={idx}>
                                <div className="adImage">
                                    <img src={ad.bannerImage} />
                                </div>
                                <div className="adDetail">
                                    <h3>
                                        {ad.bannerSlot}
                                        <small
                                            className={getAdStatusClass(
                                                ad.status
                                            )}
                                        >
                                            ({getAdStatus(ad.status)})
                                        </small>
                                    </h3>
                                    <p>
                                        <Moment format="MMMM Do YYYY, h:mm:ss a">
                                            {ad.startDate}
                                        </Moment>
                                        &nbsp; - &nbsp;
                                        <Moment format="MMMM Do YYYY, h:mm:ss a">
                                            {ad.expiryDate}
                                        </Moment>
                                    </p>
                                    <small
                                        className={`paymentStatus ${getAdStatusClass(
                                            ad?.status
                                        )} ${
                                            ad?.paymentStatus == 'success'
                                                ? 'paymentStatus__success'
                                                : 'paymentStatus__failed'
                                        }`}
                                    >
                                        (Payment status: {ad.paymentStatus})
                                        {ad?.paymentStatus == 'pending' && (
                                            <Button
                                                className="refetchPayment"
                                                onClick={() => checkPayment(ad)}
                                            >
                                                Refetch
                                            </Button>
                                        )}
                                    </small>
                                </div>
                            </div>
                        )
                    })}
                </>
            ) : (
                <NoDataFound />
            )}
        </>
    )
}

export default Ads
