import customAxios from '../axios'
import { END_POINTS } from '../domain'

export const getMyFavouriteList = (email: any) => {
    return customAxios.post(`${END_POINTS.getMyFavouriteListing}`, email)
}

export const addFavourite = (payload: any) => {
    return customAxios.post(`${END_POINTS.addFavourite}`, payload)
}

export const removeFavourite = (payload: any) => {
    return customAxios.post(`${END_POINTS.removeFavourite}`, payload)
}
