import React, { useEffect, useMemo, useState } from 'react'
import userService from '../../api/services/user.service'
import './notification.scss'
import Chat from '../Chat/Chat'
import moment from 'moment'
import returnTimePassed from '../../utils/timePassedFrom'
import { CiChat1 } from 'react-icons/ci'
import { FaRegHandshake } from 'react-icons/fa6'
import { MdOutlineLocalOffer } from 'react-icons/md'
import { BsBraces } from 'react-icons/bs'
import { GiNorthStarShuriken } from "react-icons/gi";
import {
    setChatLoading,
    setLimboData,
    setLimboLoading,
    setChatData,
    setAdsLoading,
    setAdsData,
} from '../../redux/notificationSlice'
import { useDispatch, useSelector } from 'react-redux'

function Notification() {
    const chatLoading = useSelector(
        (state: any) => state.notification.chatLoading
    )
    const chatData = useSelector((state: any) => state.notification.chatData)

    const dispatch = useDispatch()
    //chat notifcation
    const [showChat, setShowChat] = useState({
        shopperId: '',
        vendorId: '',
        limboId: '',
        show: false,
    })

    const toggleShowChat = (
        shopperId: string,
        vendorId: string,
        limboId: string
    ) => {
        setShowChat((prev) => ({
            shopperId,
            vendorId,
            limboId,
            show: !prev.show,
        }))
    }
    const userRole = localStorage.getItem('userRole')

    const userEmail = localStorage.getItem('email')?.split('"')?.[1]
    const userStr = localStorage.getItem('user')
    let userData: any = null
    if (userStr) userData = JSON.parse(userStr)

    const getAllUnreadNotifications = async () => {
        if (userEmail && userData) {
            dispatch(setChatLoading(true))
            const res: any = await userService.getUnReadNotification(userEmail)
            if (res?.success && res?.success == true) {
                dispatch(setChatData(res?.data))
                dispatch(setChatLoading(false))
            } else {
                dispatch(setChatData([]))
                dispatch(setChatLoading(false))
            }
        } else {
            dispatch(setChatData([]))
            dispatch(setChatLoading(false))
        }
    }

    useEffect(() => {
        getAllUnreadNotifications()
    }, [])

    const openChatRoom = (chatData: any) => {
        const listingId = chatData?.currChatRoomData?.listing
        const shopperEmail = chatData?.currChatRoomData?.shopper
        const vendorEmail = chatData?.currChatRoomData?.vendor
        toggleShowChat(shopperEmail, vendorEmail, listingId)
    }

    //limbo notification

    const limboData = useSelector((state: any) => state.notification.limboData)
    const limboLoading = useSelector(
        (state: any) => state.notification.limboLoading
    )
    useEffect(() => {
        getLimboNotification()
    }, [])

    const getLimboNotification = async () => {
        try {
            if (userEmail && userData) {
                dispatch(setLimboLoading(true))
                const res: any = await userService.getLimboNotification(
                    userEmail
                )

                if (res?.success && res?.success == true) {
                    dispatch(setLimboLoading(false))
                    dispatch(setLimboData(res?.data))
                } else {
                    dispatch(setLimboLoading(false))
                    dispatch(setLimboData([]))
                }
            } else {
                dispatch(setLimboLoading(false))
                dispatch(setLimboData([]))
            }
        } catch (err) {
            console.log('err ', err)
        }
    }

    const handleOpenLimbo = (limboDetails: any) => {
        const limboId = limboDetails?.limboId
        window.open(`/limbo-detail/${limboId}`, '_blank')
    }

    const handleOpenAd = (adDetails: any) => {
        if (
            userRole === `"vendor-service-provider"` ||
            userRole === `"vendor-sub-user"`
        ) {
            window.open(`dashboard/ads`, '_blank')
        } else {
            window.open(`dashboard/ad-list`, '_blank')
        }
    }

    //ads notification

    const adsData = useSelector((state: any) => state.notification.adsData)
    const adsLoading = useSelector(
        (state: any) => state.notification.adsLoading
    )
    const getAdsNotification = async () => {
        try {
            if (userEmail && userData) {
                let res
                const userRole = localStorage.getItem('userRole')
                const email: string = localStorage.getItem('email') || ''
                if (userRole === '"admin"') {
                    res = await userService.getPendingAds()
                } else if (
                    userRole === `"vendor-service-provider"` ||
                    userRole === `"vendor-sub-user"`
                ) {
                    res = await userService.getRejectedAds(
                        undefined,
                        email?.split('"')?.[1]
                    )
                }
                dispatch(setAdsLoading(true))
                if (res?.data?.success && res?.data?.success == true) {
                    dispatch(setAdsLoading(false))
                    dispatch(setAdsData(res?.data?.result))
                } else {
                    dispatch(setAdsLoading(false))
                    dispatch(setAdsData([]))
                }
            } else {
                dispatch(setAdsLoading(false))
                dispatch(setAdsData([]))
            }
        } catch (err) {
            console.log('err ', err)
        }
    }

    useEffect(() => {
        getAdsNotification()
    }, [])

    const sortedAllNotification = useMemo(() => {
        const allNotification = [
            ...(limboData || []),
            ...(chatData || []),
            ...(adsData || []),
        ]

        const allNotificationWithDate = allNotification.map((data: any) => {
            const isLimboNotification = data?.limboId ? true : false
            const isAdsNotification = data?.adId ? true : false
            let timeStamp: any = ''
            const chatData = data
            const limboDetails = data
            if (isLimboNotification) {
                timeStamp = limboDetails?.createdAt
                    ? moment(limboDetails?.createdAt)
                    : moment(new Date())
            } else {
                timeStamp = chatData?.unReadChat?.time_stamp
                    ? moment(chatData?.unReadChat?.time_stamp)
                    : moment(new Date())
            }

            if (isAdsNotification) {
                timeStamp = data?.createdAt
                    ? moment(data?.createdAt)
                    : moment(new Date())
            }
            return {
                ...data,
                timeStamp,
            }
        })

        const sortedAllNotificationTemp = allNotificationWithDate.sort(
            (x, y) => {
                if (x.timeStamp.diff(y.timeStamp) >= 0) {
                    return -1
                } else {
                    return 1
                }
            }
        )

        return sortedAllNotificationTemp
    }, [chatData, limboData, adsData])

    const markAsReadLocally = (
        notificationId: any,
        isLimboNotification: any,
        isAdsNotification: any
    ) => {
        if (isLimboNotification) {
            const limboDataTemp = JSON.parse(JSON.stringify(limboData))
            const index = limboDataTemp.findIndex(
                (data: any) => data._id === notificationId
            )

            if (index > -1) {
                limboDataTemp[index].read = true
                dispatch(setLimboData(limboDataTemp))
            }
        } else if (isAdsNotification) {
            const adsDataTemp = JSON.parse(JSON.stringify(adsData))
            const index = adsDataTemp.findIndex(
                (data: any) => data.adId === notificationId
            )

            if (index > -1) {
                adsDataTemp[index].read = true
                dispatch(setAdsData(adsDataTemp))
            }
        } else {
            const chatDataTemp = JSON.parse(JSON.stringify(chatData))

            const index = chatDataTemp.findIndex(
                (data: any) => data.unReadChat._id === notificationId
            )

            if (index > -1) {
                chatDataTemp[index].unReadChat.readByReceiver = true
                dispatch(setChatData(chatDataTemp))
            }
        }
    }

    const loggedInUserType = localStorage.getItem('userRole')

    return (
        <div className="notification-page">
            <div className="chatroom-container">
                <div className="chat-page-title">
                    NOTIFICATION
                    <div className="notification-number">
                        You have {sortedAllNotification?.length || 0}{' '}
                        notification
                    </div>
                </div>
                <div>
                    {sortedAllNotification.map((data: any, key) => {
                        const isLimboNotification = data?.limboId ? true : false
                        const isAdsNotification = data?.adId ? true : false

                        let timeStamp: any = ''
                        let timeText: any = ''
                        const chatData = data
                        const limboDetails = data
                        const adsDetails = data
                        if (isLimboNotification) {
                            timeStamp = limboDetails?.createdAt
                                ? moment(limboDetails?.createdAt)
                                : moment(new Date())
                            timeText = returnTimePassed(timeStamp)
                        } else {
                            timeStamp = data?.unReadChat?.time_stamp
                                ? moment(data?.unReadChat?.time_stamp)
                                : moment(new Date())
                            timeText = returnTimePassed(timeStamp)
                        }

                        if (isAdsNotification) {
                            timeStamp = data?.createdAt
                            timeText = returnTimePassed(timeStamp)
                        }
                        const isRead = isLimboNotification
                            ? limboDetails?.read
                            : isAdsNotification
                            ? adsDetails?.read
                            : chatData?.unReadChat?.readByReceiver
                        const notificationId = isLimboNotification
                            ? limboDetails?._id
                            : isAdsNotification
                            ? adsDetails?.adId
                            : chatData?.unReadChat?._id

                        const chatContentClass = isRead
                            ? 'chat-content read'
                            : 'chat-content unread'

                        return isLimboNotification ? (
                            <>
                                <div
                                    className={chatContentClass}
                                    key={limboDetails?._id}
                                    onClick={async () => {
                                        markAsReadLocally(
                                            notificationId,
                                            isLimboNotification,
                                            undefined
                                        )
                                        await userService.updateLimboReadStatus(
                                            notificationId
                                        )
                                        handleOpenLimbo(limboDetails)
                                    }}
                                >
                                    {/* <div className="chat-img-title"> */}
                                    {/* <span
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                borderBottom: '1px solid black',
                                            }}
                                        > */}
                                    <span>
                                        <span className="chat-img">
                                            {limboDetails?.stage ===
                                            'NEWOFFER' ? (
                                                <MdOutlineLocalOffer
                                                    style={{
                                                        fontSize: '1.4rem',
                                                        margin: '0.5rem',
                                                        color: '#FF0000',
                                                    }}
                                                />
                                            ) : limboDetails?.stage ===
                                              'LIMBOSTART' ? (
                                                <GiNorthStarShuriken 
                                                    style={{
                                                        fontSize: '1.4rem',
                                                        margin: '0.5rem',
                                                        color: '#FF0000',
                                                    }}
                                                />
                                            ) : (
                                                <FaRegHandshake
                                                    style={{
                                                        fontSize: '1.4rem',
                                                        margin: '0.5rem',
                                                        color: '#FF0000',
                                                    }}
                                                />
                                            )}
                                        </span>
                                        <span className="chat-email">
                                            {limboDetails?.stage === 'NEWOFFER'
                                                ? `New Offer`
                                                : limboDetails?.stage ===
                                                  'LIMBOSTART'
                                                ? 'New Limbo'
                                                : 'Hand Shake'}
                                        </span>
                                    </span>
                                    <span className="chat-message">
                                        {limboDetails?.stage === 'NEWOFFER'
                                            ? `A more competitive offer was placed for this limbo :`
                                            : limboDetails?.stage ===
                                              'LIMBOSTART'
                                            ? 'Time for you to compete in '
                                            : loggedInUserType != `"shopper"`
                                            ? 'A shopper has accepted your offer! for                                            '
                                            : 'You finalized the offer for this limbo: '}
                                        &nbsp;
                                        <span style={{ fontWeight: '500' }}>
                                            {limboDetails?.limboName}
                                        </span>
                                    </span>
                                    <span className="chat-timestamp">
                                        {timeText}
                                    </span>
                                    {/* </span> */}
                                    {/* </div> */}
                                </div>
                            </>
                        ) : isAdsNotification ? (
                            <>
                                <div
                                    className={chatContentClass}
                                    key={adsDetails?.adId}
                                    onClick={async () => {
                                        markAsReadLocally(
                                            notificationId,
                                            undefined,
                                            isAdsNotification
                                        )
                                        if (!isRead) {
                                            await userService.updateAdsReadStatus(
                                                notificationId
                                            )
                                        }
                                        handleOpenAd(adsDetails)
                                    }}
                                >
                                    {/* <div className="chat-img-title"> */}
                                    {/* <span
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                borderBottom: '1px solid black',
                                            }}
                                        > */}
                                    <span>
                                        <span className="chat-img">
                                            <MdOutlineLocalOffer
                                                style={{
                                                    fontSize: '1.4rem',
                                                    margin: '0.5rem',
                                                    color: '#FF0000',
                                                }}
                                            />
                                        </span>
                                        <span className="chat-email">
                                            {adsDetails?.adName}
                                        </span>
                                    </span>
                                    <span className="chat-message">
                                        {adsDetails?.adDesc}
                                    </span>
                                    <span className="chat-timestamp">
                                        {timeText}
                                    </span>
                                    {/* </span> */}
                                    {/* </div> */}
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                    className={chatContentClass}
                                    key={chatData?.currChatRoomData?._id}
                                    onClick={() => {
                                        openChatRoom(chatData)
                                        markAsReadLocally(
                                            notificationId,
                                            isLimboNotification,
                                            undefined
                                        )
                                    }}
                                >
                                    {/* <div className="chat-img-title"> */}
                                    {/* <span
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                borderBottom: '1px solid black',
                                            }}
                                        > */}
                                    <span>
                                        <span className="chat-img">
                                            <CiChat1
                                                style={{
                                                    fontSize: '1.4rem',
                                                    margin: '0.5rem',
                                                    color: '#FF0000',
                                                }}
                                            />
                                        </span>
                                        <span className="chat-email">
                                            {chatData?.unReadChat?.sender}
                                        </span>
                                    </span>
                                    <span className="chat-message">
                                        {chatData?.unReadChat?.content
                                            ? chatData?.unReadChat?.content.substring(
                                                  0,
                                                  50
                                              )
                                            : ''}
                                    </span>
                                    <span className="chat-timestamp">
                                        {timeText}
                                    </span>
                                    {/* </span> */}
                                    {/* </div> */}
                                </div>
                            </>
                        )
                    })}
                </div>
                {showChat?.show ? (
                    <Chat
                        shopperId={showChat?.shopperId}
                        vendorId={showChat?.vendorId}
                        listingId={showChat?.limboId ? showChat.limboId : ''}
                        closeCallback={() => {
                            setShowChat((prev) => ({
                                ...prev,
                                show: !prev.show,
                            }))
                        }}
                    />
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

export default Notification
