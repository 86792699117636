import React from 'react'
import { useState, useEffect } from 'react'
import { Navigate, Link, useNavigate } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import AuthService from '../../api/services/auth.service'
import {
    setAdsData,
    setAdsLoading,
    setChatData,
    setChatLoading,
    setLimboData,
    setLimboLoading,
} from '../../redux/notificationSlice'
import userService from '../../api/services/user.service'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import Loader from '../../components/Loader/Loader'

type Props = {}

type State = {
    redirect: string | null
    email: string
    password: string
    loading: boolean
    message: string
}
const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
})
const LoginForm = () => {
    const [redirect, setRedirect] = useState(null)
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)

    const dispatch = useDispatch()

    const getAllUnreadNotifications = async (userEmail: any, userData: any) => {
        if (userEmail && userData) {
            dispatch(setChatLoading(true))
            const res: any = await userService.getUnReadNotification(userEmail)
            if (res?.success && res?.success == true) {
                dispatch(setChatData(res?.data))
                dispatch(setChatLoading(false))
            } else {
                dispatch(setChatData([]))
                dispatch(setChatLoading(false))
            }
        } else {
            dispatch(setChatData([]))
            dispatch(setChatLoading(false))
        }
    }

    const getLimboNotification = async (userEmail: any, userData: any) => {
        try {
            if (userEmail && userData) {
                dispatch(setLimboLoading(true))
                const res: any = await userService.getLimboNotification(
                    userEmail
                )

                if (res?.success && res?.success == true) {
                    dispatch(setLimboLoading(false))
                    dispatch(setLimboData(res?.data))
                } else {
                    dispatch(setLimboLoading(false))
                    dispatch(setLimboData([]))
                }
            } else {
                dispatch(setLimboLoading(false))
                dispatch(setLimboData([]))
            }
        } catch (err) {
            console.log('err ', err)
        }
    }

    const getAdsNotification = async (userEmail: any, userData: any) => {
        try {
            if (userEmail && userData) {
                let res
                const userRole = localStorage.getItem('userRole')
                const email: string = localStorage.getItem('email') || ''
                if (userRole === '"admin"') {
                    res = await userService.getPendingAds()
                } else if (
                    userRole === `"vendor-service-provider"` ||
                    userRole === `"vendor-sub-user"`
                ) {
                    res = await userService.getRejectedAds(
                        undefined,
                        email?.split('"')?.[1]
                    )
                }
                dispatch(setAdsLoading(true))
                if (res?.data?.success && res?.data?.success == true) {
                    dispatch(setAdsLoading(false))
                    dispatch(setAdsData(res?.data?.result))
                } else {
                    dispatch(setAdsLoading(false))
                    dispatch(setAdsData([]))
                }
            } else {
                dispatch(setAdsLoading(false))
                dispatch(setAdsData([]))
            }
        } catch (err) {
            console.log('err ', err)
        }
    }

    const handleTogglePassword = () => {
        setShowPassword(!showPassword)
    }
    const navigate = useNavigate()

    const handleSubmit = (values: any, { setSubmitting }: any) => {
        setLoading(true)
        setMessage('')

        AuthService.login(values.email, values.password).then(
            async (res) => {
                //needed for calling the notification api
                const userEmail = localStorage.getItem('email')?.split('"')?.[1]
                const userStr = localStorage.getItem('user')
                let userData: any = null
                if (userStr) userData = JSON.parse(userStr)

                await getAllUnreadNotifications(userEmail, userData)
                await getLimboNotification(userEmail, userData)
                await getAdsNotification(userEmail, userData)

                if (res?.NEW_PASSWORD_REQUIRED === true) {
                    localStorage.setItem('session', res?.session)
                    setLoading(false)
                    navigate('/change-password')
                } else {
                    setLoading(false)
                    navigate('/')
                }
            },
            (error: any) => {
                toast(error.message)
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.error) ||
                    error.message ||
                    error.toString()
                setLoading(false)
                setMessage(resMessage)
            }
        )
    }

    if (redirect) {
        return <Navigate to={redirect} />
    }

    return (
        <div className="loginForm">
            <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <Field type="email" name="email" />
                            <ErrorMessage
                                name="email"
                                component="div"
                                className="alert alert-danger"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="password">Password:</label>
                            <Field
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                            />
                            <ErrorMessage
                                name="password"
                                component="div"
                                className="alert alert-danger"
                            />
                        </div>

                        <div className="showpassword-row">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={showPassword}
                                    onChange={handleTogglePassword}
                                />{' '}
                                Show Password:
                            </label>
                            <Link to="/forgot-password" className="forgotlink">
                                Forgot Your Password?
                            </Link>
                        </div>
                        <div className="form-group">
                            <button
                                type="submit"
                                // disabled={isSubmitting}
                                className="btn btn-primary btn-block login__btn"
                            >
                                {loading ? <Loader /> : 'Login'}
                            </button>
                        </div>
                        {message && (
                            <div className="form-group">
                                <div
                                    className="alert alert-danger"
                                    role="alert"
                                >
                                    {message}
                                </div>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default LoginForm
