import React, { useState } from 'react'
import { GiCancel } from 'react-icons/gi'
import { Button, Spinner } from 'react-bootstrap'
import './imagepicker.scss'
import { imageUpload } from '../../api/services/listing.service'
import { toast } from 'react-toastify'

interface ImagePickerProps {
    multiple?: boolean
    onImageUpload: (files: FileList | File) => void
    awsFolderName: any
    field?: any
    value?: any
}

const ImagePicker: React.FC<ImagePickerProps> = ({
    multiple = true, // Set default to false
    onImageUpload,
    awsFolderName,
    // field,
    // value,
}) => {
    const [selectedFiles, setSelectedFiles] = useState<any>([])
    const [loader, setLoader] = useState(false)

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files

        // files is an object and selectedFiles is an array.So converting object to array
        const chosenFiles = Array.prototype.slice.call(files)

        if (files) {
            setSelectedFiles(chosenFiles)
        }
    }

    const handleUpload = () => {
        if (!selectedFiles || selectedFiles.length === 0) {
            toast.warning('No file selected')
            return
        }

        const formData = new FormData()
        selectedFiles.forEach((item: any, index: any) => {
            formData.append('files', item)
        })
        formData.append('awsFolderName', awsFolderName)

        setLoader(true)
        imageUpload(formData)
            .then((response) => {
                const data = response?.data?.message?.map(
                    (file: any) => file.Location
                )

                onImageUpload(data)
                setLoader(false)
                toast.success('Image uploaded successfully')
            })
            .catch((error) => {
                setLoader(false)
                toast.error(`${error}`)
            })
    }

    const handleFileDelete = (file: File) => {
        const filteredFiles = selectedFiles.filter(
            (selectedFile: any) => selectedFile !== file
        )
        setSelectedFiles(filteredFiles)
    }

    return (
        <div className="image-picker">
            <div className="selected-images-container">
                {selectedFiles &&
                    selectedFiles.map((file: any, index: any) => (
                        <div className="preview-image-container" key={index}>
                            <img
                                src={URL.createObjectURL(file)}
                                alt={`Selected Image ${index + 1}`}
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '50px',
                                    marginTop: '10px',
                                }}
                            />
                            <Button
                                className="cross-button"
                                onClick={() => handleFileDelete(file)}
                            >
                                <GiCancel />
                            </Button>
                        </div>
                    ))}
            </div>
            <div className="form-fields">
                {/* <label htmlFor="image">Add Photos</label> */}
                <div className="upload-button">
                    <input
                        type="file"
                        name="image"
                        onChange={handleFileChange}
                        accept="image/*"
                        multiple={multiple}
                    />

                    <Button onClick={handleUpload}>
                        {loader ? <Spinner size="sm" /> : 'Upload'}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ImagePicker
